/* src/app/components/library/questions-list/questions-list.component.scss */
mat-form-field {
  margin: 0 7px;
}
.mat-mdc-table .mat-mdc-header-cell {
  background: var(--light-green);
}
::ng-deep .mat-mdc-paginator-page-size-value {
  display: none;
}
::ng-deep .mat-mdc-paginator-page-size-label {
  display: none;
}
td {
  max-width: 200px;
}
/*# sourceMappingURL=questions-list.component.css.map */
