import {Component, Input, OnInit} from '@angular/core';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatDivider} from '@angular/material/divider';
import {MatInput} from '@angular/material/input';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {finalize, Observable, of, tap} from 'rxjs';
import {AnswerFormatTypeEnum} from '../../../../../enums/questions.enum';

@Component({
  selector: 'app-free-text-answer',
  standalone: true,
  imports: [
    MatFormField,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatDivider,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './free-text-answer.component.html',
  styleUrl: './free-text-answer.component.scss'
})
export class FreeTextAnswerComponent implements OnInit {

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  _loading: boolean = false;

  ngOnInit() {
    this._switchLoading().pipe(
      tap(() => this.initForm()),
      finalize(() => this._switchLoading())
    ).subscribe()
  }

  initForm() {
    const questionAnswer: FormArray = this.firstStepFormGroup.get('questionAnswers') as FormArray;
    if (!this._editMode) questionAnswer.push(this.createFormGroupAnswer());
  }

  get answerArray() {
    return this.firstStepFormGroup.get('questionAnswers') as FormArray
  }


  private createFormGroupAnswer(): FormGroup {
    return new FormGroup({
      value: new FormControl(''),
      formatType: new FormControl(AnswerFormatTypeEnum.TEXT),
      min: new FormControl(0),
      max: new FormControl(255),
    })
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  protected readonly AnswerFormatTypeEnum = AnswerFormatTypeEnum;
}
