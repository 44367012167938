import {Component, OnInit, ViewChild} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatIcon} from "@angular/material/icon";
import {ActivatedRoute, Router} from "@angular/router";
import {FormAnswerStatus, FormDetailModel} from "../../../models/form.model";
import {NgClass, TitleCasePipe} from "@angular/common";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "../../shared/button/button.component";
import {LoaderComponent} from "../../shared/loader/loader.component";
import {UserFormService} from "../../../services/back/user-form.service";
import {UserService} from "../../../services/front/user.service";
import {User} from "../../../models/user.model";

@Component({
  selector: 'app-dashboard-form-detail',
  standalone: true,
  imports: [
    MatTable,
    MatHeaderCell,
    MatColumnDef,
    MatCell,
    MatSort,
    MatHeaderRow,
    MatRow,
    MatPaginator,
    MatIcon,
    MatSortHeader,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    NgClass,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    TitleCasePipe,
    ButtonComponent,
    LoaderComponent
  ],
  templateUrl: './dashboard-form-detail.component.html',
  styleUrl: './dashboard-form-detail.component.scss'
})
export class DashboardFormDetailComponent implements OnInit{

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator, {static: false}) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  UUID_LENGTH = 36
  _codeRegion!: string; // TODO @BMA Change when we got the client authentication

  filterForm: FormGroup = new FormGroup({});
  displayedColumns: string[] = ['clientCode', 'clientName', 'raisonSoc', 'status'];
  dataSource = new MatTableDataSource<FormDetailModel>([]);
  formList : FormDetailModel[] = [];
  statusKeys : string[] = ["En cours", "Non commencé", "Complété"];
  isLoading: boolean = false;


  constructor(private router: Router,
              private fb: FormBuilder,
              private activeRouter : ActivatedRoute,
              private userFormService: UserFormService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.isLoading = true;
    const formId = this.activeRouter.snapshot.paramMap.get('uuid') || '';
    const formDate = this.activeRouter.snapshot.paramMap.get('formDate') || '';
    if(formId.length < this.UUID_LENGTH) {
      this.goToDashboard()
    }
    this.setFiltersGroup();
    this.userService.getUser().subscribe((user: User) => {
      this._codeRegion = user.codeRegion;
    })

    this.userFormService.getFormDetails(this._codeRegion, formId, formDate).subscribe((res) => {
      this.formList = res;
      this.dataSource.data = res;
      this.isLoading = false;
    })

  }

  setFiltersGroup(): void {
    this.filterForm = this.fb.group({
      multiple: new FormControl<string>(''),
      status: new FormControl<string>(''),
    });
  }

  applyFilter() {
    const multipleFilter = this.filterForm.get('multiple')?.value.trim().toLowerCase() || '';
    const statusFilter = this.getStatusForFilter() || '';

    this.dataSource.data = this.formList.filter(form => {
      const matchClientCode = !multipleFilter || multipleFilter.length === 0 || form.clientCode.trim().toLowerCase().includes(multipleFilter);
      const matchClientName = !multipleFilter || multipleFilter.length === 0 || form.clientName.trim().toLowerCase().includes(multipleFilter);
      const matchCompanyName = !multipleFilter || multipleFilter.length === 0 || form.raisonSoc.trim().toLowerCase().includes(multipleFilter);
      const matchStatus = !statusFilter || statusFilter.length === 0 || form.status === statusFilter;

      return ( matchClientCode || matchClientName || matchCompanyName ) && matchStatus;
    });

  }

  displayStatus(status: FormAnswerStatus): string {
    if (status === FormAnswerStatus.IN_PROGRESS) {
      return "En cours";
    } else if (status === FormAnswerStatus.SENT) {
      return "Non commencé";
    } else {
      return "Complété";
    }
  }

  getStatusForFilter(){
    const status = this.filterForm.get('status')?.value;
    let realStatus = null;
    if (status === "En cours") {
      realStatus = FormAnswerStatus.IN_PROGRESS;
    } else if (status === "Non commencé") {
      realStatus = FormAnswerStatus.SENT;
    } else if (status === "Complété") {
      realStatus = FormAnswerStatus.COMPLETED;
    }
    return realStatus;
  }

  isInProgress(status : FormAnswerStatus): boolean {
    return status === FormAnswerStatus.IN_PROGRESS;
  }

  goToDashboard() {
    this.router.navigate(["/private/dashboards"])
  }
}
