import {Injectable} from '@angular/core';
import {FormComponentsType, Section} from "../../models/form.model";
import {Question, QuestionSectionOrganized} from "../../models/questions.model";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SectionOrganizerService {

  private questionListForm: BehaviorSubject<(Section | Question)[]> = new BehaviorSubject<(Section | Question)[]>([]);
  public questionListForm$ = this.questionListForm.asObservable();

  constructor() { }

  organizeSectionsAndQuestions(inputList: (Section | Question)[]): QuestionSectionOrganized[] {
    const outputList: QuestionSectionOrganized[] = [];
    let currentSection: QuestionSectionOrganized | null = null;

    for (const item of inputList) {
      if (item.formsComponentsType === FormComponentsType.SECTION) {
        if (currentSection) {
          outputList.push(currentSection);
        }
        currentSection = { section: item as Section, questions: [] };
      } else if (item.formsComponentsType === FormComponentsType.QUESTION) {
        if (currentSection) {
          currentSection.questions.push(item as Question);
        }
      }
    }
    if (currentSection) {
      outputList.push(currentSection);
    }

    return outputList;
  }

  flattenSectionsAndQuestions(organizedList: QuestionSectionOrganized[]): (Section | Question)[] {
    const outputList: (Section | Question)[] = [];
    for (const section of organizedList) {
      outputList.push(section.section);
      for (const question of section.questions) {
        outputList.push(question);
      }
    }
    return outputList;
  }

  setQuestionListForm(questionList: (Section | Question)[]) {
    this.questionListForm.next(questionList);
  }

}
