/* src/app/components/library/create-question/create-question.component.scss */
@media (min-width: 768px) {
  ::ng-deep .mat-horizontal-stepper-header-container {
    margin-left: 15%;
    margin-right: 15%;
  }
}
@media (min-width: 1024px) {
  ::ng-deep .mat-horizontal-stepper-header-container {
    margin-left: 30%;
    margin-right: 30%;
  }
}
/*# sourceMappingURL=create-question.component.css.map */
