import {Component, Input} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {QuillEditorComponent} from "ngx-quill";
import {quillModuleToolbar} from '../../../../../utils/wysiwyg.utils';

@Component({
  selector: 'app-cover-page',
  standalone: true,
  imports: [
    FormsModule,
    QuillEditorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './cover-page.component.html',
  styleUrl: './cover-page.component.scss'
})
export class CoverPageComponent {

  @Input() secondStepFormGroup!: FormGroup;
  editorConfig = {
    modules: {
      toolbar: quillModuleToolbar
    },
    placeholder: 'Commencez votre page de garde ici!'
  };

}
