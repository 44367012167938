import {Injectable} from '@angular/core';

@Injectable()
export class SharedService {

  constructor() { }

  getPropreName(value: string): string {
    if (!value) return value;

    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
