import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {QuestionService} from '../../../services/back/question.service';
import {NotificationService} from '../../../services/front/notifications.service';
import {QuestionForm} from '../../../models/questions.model';
import {OperationResponseModel} from '../../../models/operation-response.model';
import {NotificationsStatus} from '../../../enums/notifications-status';
import {HttpClientModule, HttpErrorResponse} from '@angular/common/http';
import {MatIcon} from '@angular/material/icon';
import {MatFormField} from '@angular/material/form-field';
import {NgClass, UpperCasePipe} from '@angular/common';
import {MatInput, MatLabel} from '@angular/material/input';
import {FormService} from "../../../services/back/form.service";
import {MatButton, MatIconButton} from "@angular/material/button";

@Component({
  selector: 'app-duplicate-dialog',
  standalone: true,
  imports: [
    MatIcon,
    ReactiveFormsModule,
    MatFormField,
    NgClass,
    MatInput,
    MatLabel,
    HttpClientModule,
    MatDialogClose,
    MatButton,
    MatIconButton,
    UpperCasePipe,
  ],
  providers: [
    QuestionService,
    FormService
  ],
  templateUrl: './duplicate-dialog.component.html',
  styleUrl: './duplicate-dialog.component.scss'
})
export class DuplicateDialogComponent {
  form: FormGroup = new FormGroup({
    editorContent : new FormControl('', [Validators.required]),
  })

  errorMessage: string | undefined ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {templateId: number, originalTitle: string, objectType: string},
              public questionService: QuestionService,
              public formService: FormService,
              public notificationService : NotificationService,
              private dialogRef: MatDialogRef<DuplicateDialogComponent>) {
  }

  onSubmit() {
    console.log('log1', this.data.objectType)

    if (this.data.objectType === 'question') {
      const params: QuestionForm = { question: this.form.value.editorContent }
      this.questionService.duplicateQuestion(this.data.templateId, params).subscribe({
        next: (response: OperationResponseModel) => {
          this.handleSuccess(response);
        },
        error: error => {
          this.errorMessage = this.handleError(error);
        }
      });
    } else {
      const params: Map<string, string> = new Map <string, string>();
      params.set("title", this.form.value.editorContent);
      this.formService.duplicateForm(this.data.templateId, params).subscribe({
        next: (response: OperationResponseModel) => {
          this.handleSuccess(response);
        },
        error: error => {
          this.errorMessage = this.handleError(error);
        }
      });
    }

  }

  handleSuccess(response: OperationResponseModel): void {
    this.dialogRef.close('refresh');
    const notificationToDisplay: NotificationsStatus = response.message === NotificationsStatus.SUCCESSFUL_DUPLICATION
      ? NotificationsStatus.SUCCESSFUL_DUPLICATION
      : NotificationsStatus.PARTIAL_DUPLICATION;
    this.notificationService.showNotification(notificationToDisplay);
    this.notificationService.showNotification(response.message === NotificationsStatus.SUCCESSFUL_DUPLICATION
      ? NotificationsStatus.SUCCESSFUL_DUPLICATION
      : NotificationsStatus.PARTIAL_DUPLICATION);
  }

  handleError(error : HttpErrorResponse): string {
    if (error.error.includes('duplicate key value violates unique constraint')) {
      return 'Le titre doit être unique.';
    } else if (error.error.includes('On ne peut pas dupliquer une question avec ce statut.')) {
      return error.error;
    } else {
      return `Erreur ${error.status} `;
    }
  }
}
