import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../front/notifications.service";
import {environment} from "../../../environments/environment";

const API_URL = environment.apiURL;

@Injectable()
export class ClientService {

  private _baseUrl: string = API_URL + '/clients';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }

}
