import {Component, OnInit, ViewChild} from '@angular/core';
import {ButtonComponent} from "../shared/button/button.component";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatChipRemove, MatChipRow} from "@angular/material/chips";
import {MatFormField, MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {DatePipe, NgClass, NgForOf, TitleCasePipe} from "@angular/common";
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
  MatTableModule
} from "@angular/material/table";
import {FormCategoryEnum} from "../../models/form.model";
import {FormIconComponent} from "../shared/form-icon/form-icon/form-icon.component";
import {MatProgressBar} from "@angular/material/progress-bar";
import {DashboardStatisticsModel, QuestionnaireStatus, UserFormModel} from "../../models/questionnaireModel";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {Agence, Collaborateur} from "../../models/inexref.model";
import {debounceTime, distinctUntilChanged, finalize, forkJoin, Observable, of, switchMap, tap} from "rxjs";
import {InexrefService} from "../../services/back/inexref.service";
import {TagService} from "../../services/back/tag.service";
import {Tag} from "../../models/tag.model";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";
import {UserFormService} from "../../services/back/user-form.service";
import {MatPaginator} from "@angular/material/paginator";
import {LoaderComponent} from "../shared/loader/loader.component";
import {Router} from "@angular/router";
import {UserService} from "../../services/front/user.service";
import {UserRoleEnum} from "../../enums/userRole.enum";

@Component({
  selector: 'app-dashboard-home-page',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    MatChipRemove,
    MatChipRow,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    ReactiveFormsModule,
    TitleCasePipe,
    MatCard,
    MatButton,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatCardHeader,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    FormIconComponent,
    MatProgressBar,
    DatePipe,
    MatSortModule,
    MatTableModule,
    MatSort,
    NgClass,
    MatAutocomplete,
    NgForOf,
    MatAutocompleteTrigger,
    MatDatepickerModule,
    MatHint,
    MatFormFieldModule,
    MatInputModule,
    MatPaginator,
    MatSort,
    LoaderComponent,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    provideNativeDateAdapter(),
  ],
  templateUrl: './dashboard-home-page.component.html',
  styleUrl: './dashboard-home-page.component.scss'
})
export class DashboardHomePageComponent implements OnInit {

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator, {static: false}) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  _codeRegion!: string; // TODO @BMA Change when we got the client authentication
  _isVisualisation: boolean = false; // TODO @BMA Change when we got the roles
  _connectedUserMatricule!: string; // TODO @BMA Change when we got the client authentication

  today: Date = new Date();
  isLoading: boolean = false;
  filterQuestionnaire: FormGroup = new FormGroup({});
  displayedColumns: string[] = ['category', 'title', 'sendDate', "progress", 'status'];
  dataSource = new MatTableDataSource<UserFormModel>([]);

  collaborateurList: Collaborateur[] = [];
  agenceList: Agence[] = [];
  tagsList: Tag[] = [];
  userFormList: UserFormModel[] = [];
  statistic: DashboardStatisticsModel = {totalUserForms: 0, totalSentUserForms: 0, totalInProgressUserForms: 0, totalCompletedUserForms: 0};
  categoryKeys = Object.keys(FormCategoryEnum);
  statusKeys = ["En cours", "Non commencé", "Complété"];

  constructor(private fb: FormBuilder,
              private inexrefService: InexrefService,
              private userFormService: UserFormService,
              private userService: UserService,
              private tagService: TagService,
              private router: Router) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this._codeRegion = user.codeRegion;
      this._connectedUserMatricule = user.matricule;
    });
    this.userService.getUserRoles().subscribe(roles => {
      this._isVisualisation = roles.includes(UserRoleEnum.ANALYSTE) && roles.length < 4;
    });

    this.setFiltersGroup();
    this.clientFormCheckOnValueChange();
    this.switchLoading().pipe(
      switchMap(() => forkJoin({
        agenceList: this.inexrefService.getClientAgencesByRegion(this._codeRegion),
        tags: this.tagService.getTagsList(),
        userForms: this.userFormService.getUserForms(this._codeRegion),
        statistics: this.userFormService.getDashboardStatistic(this._codeRegion, this._connectedUserMatricule)
      })),
      finalize(() => this.switchLoading())
    ).subscribe(({agenceList, tags, userForms, statistics}) => {
      this.agenceList = agenceList;
      this.tagsList = tags;
      this.userFormList = userForms;
      this.dataSource.data = userForms
      this.statistic = statistics;
      this.applyFilter();
    })
  }

  setFiltersGroup(): void {
    this.filterQuestionnaire = this.fb.group({
      agency: new FormControl<string>(''),
      collaborator: new FormControl<Collaborateur | null>(null),
      category: new FormControl<string[]>([]),
      status: new FormControl<string>(""),
      tags: new FormControl<string[]>([]),
      date: new FormControl<Date | null>(null),
    });
  }

  applyFilter(): void {
    const filter: Filter = {
      agency: this.filterQuestionnaire.get('agency')?.value || null,
      collaborator: (this.filterQuestionnaire.get('collaborator')?.value as Collaborateur)?.matricule || null,
      category: this.filterQuestionnaire.get('category')?.value || null,
      status: this.getStatusForFilter(),
      tags: this.filterQuestionnaire.get('tags')?.value || null,
      date: this.filterQuestionnaire.get('date')?.value || null,
    };

    if(this._isVisualisation) {
      filter.collaborator = this._connectedUserMatricule;
    }

    this.dataSource.data = this.userFormList.filter(userForm => {
      const matchAgency = !filter.agency || filter.agency.length === 0 || userForm.codeAgence === filter.agency;
      const matchCollaborator = !filter.collaborator || filter.collaborator.length === 0 || userForm.collabMatricule === filter.collaborator;
      const matchCategory = !filter.category || filter.category.length === 0 || userForm.form.category === filter.category;
      const matchStatus = !filter.status || filter.status.length === 0 || userForm.aggregatedStatus === filter.status;
      const matchTags = !filter.tags || filter.tags.length === 0 || filter.tags.some(tag =>
        userForm.form.tagsList.some(t => t.title === tag)
      );
      const matchDate = !filter.date || new Date(userForm.sendDate) >= new Date(filter.date);
      return matchAgency && matchCollaborator && matchCategory && matchStatus && matchTags && matchDate;
    });
  }

  isInProgress(status : QuestionnaireStatus): boolean {
    return status === QuestionnaireStatus.IN_PROGRESS;
  }

  getProgressValue(userForm: UserFormModel): number {
    const [first, second] = userForm.statusSummary.split('/').map(num => parseInt(num, 10));
    return first * 100 / second;
  }

  clientFormCheckOnValueChange() {
    this.filterQuestionnaire.get("collaborator")?.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value: string) => this.inexrefService.getClientCollaborateurs(this._codeRegion, value)),
      tap((res: Collaborateur[]) => this.collaborateurList = res),
    ).subscribe();
  }

  displayCollaborator(collab: Collaborateur | null): string {
    return collab && collab.prenom && collab.nom ? `${collab.prenom} ${collab.nom}` : '';
  }

  displayStatus(status: QuestionnaireStatus): string {
    if (status === QuestionnaireStatus.IN_PROGRESS) {
      return "En cours";
    } else if (status === QuestionnaireStatus.SENT) {
      return "Non commencé";
    } else {
      return "Complété";
    }
  }

  getStatusForFilter(){
    const status = this.filterQuestionnaire.get('status')?.value;
    let realStatus = null;
    if (status === "EN COURS") {
      realStatus = QuestionnaireStatus.IN_PROGRESS;
    } else if (status === "NON COMMENCE") {
      realStatus = QuestionnaireStatus.SENT;
    } else if (status === "COMPLETE") {
      realStatus = QuestionnaireStatus.COMPLETED;
    }
    return realStatus;
  }

  goToDetail(userForm : UserFormModel) {
    this.router.navigate(['/private/dashboards/detail', userForm.form.uuid, userForm.sendDate]);
  }

  private switchLoading(): Observable<{}> {
    this.isLoading = !this.isLoading;
    return of({});
  }
}

interface Filter {
  agency?: string | null;
  collaborator?: string | null;
  category?: string | null;
  status?: string | null;
  tags?: string[] | null;
  date?: Date | null;
}
