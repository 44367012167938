/* src/app/components/dashboard-home-page/dashboard-home-page.component.scss */
.category-column {
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.status-column {
  width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*# sourceMappingURL=dashboard-home-page.component.css.map */
