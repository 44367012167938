/* src/app/components/forms-dashboard/create-form/create-form.component.scss */
::ng-deep .mat-horizontal-stepper-wrapper {
  height: 100%;
}
::ng-deep .mat-horizontal-content-container {
  height: 100%;
}
::ng-deep .mat-horizontal-stepper-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=create-form.component.css.map */
