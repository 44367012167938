@if (isReady) {
  <div class="m-5 container">
    <h3 class="text-xl">{{ questionSection.section.name }}</h3>

    <div class="mt-5">
      @if (questionSection.section.synthesisType === SynthesisType.CONDITIONNEL && questionSectionSynthesis) {
        <app-conditional-synthesis [questionSectionSynthesis]="questionSectionSynthesis"></app-conditional-synthesis>
      } @else if (questionSection.section.synthesisType === SynthesisType.GRAPHIQUE && questionSectionSynthesis) {
        @if (questionAnswers && questionAnswers.length > 0) {
          <div class="flex justify-center">
            @for (questionAnswer of questionAnswers; track $index) {
              <app-graphical-synthesis
                [question]="toQuestionGraphicalSynthesis(questionAnswer)"></app-graphical-synthesis>
            }
          </div>
        } @else {
          Vous n'avez répondu à aucune question, le graphique ne peut pas être affiché.
        }
      } @else if (questionSection.section.synthesisType === SynthesisType.RESTITUTION && questionSectionSynthesis) {
        @if (questionAnswers && questionAnswers.length > 0) {
          <app-restitution-synthesis
            [questionSections]="toQuestionRestitutionSynthesis(questionAnswers)"></app-restitution-synthesis>
        } @else {
          Vous n'avez répondu à aucune question, la restitution ne peut pas être affichée.
        }
      }
    </div>
  </div>
} @else {
  <div class="flex justify-center">
    <app-loader></app-loader>
  </div>
}
