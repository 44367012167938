/* src/app/components/shared/form-card/form-card.component.scss */
.big-icon {
  font-size: 72px;
  height: auto;
  width: auto;
}
.truncate-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-menu-button:hover {
  background-color: #bababa !important;
}
/*# sourceMappingURL=form-card.component.css.map */
