<div class="h-1/3 flex mb-2">
  <div class="grow px-8 py-3 w-11/12">
    <div class="font-bold flex gap-2 items-center w-full">
      @if (showIcon) {
        <mat-icon class="opacity-60 min-w-6" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(question.type)">{{QuestionTypeUtil.getQuestionTypeIcon(question.type)}}</mat-icon>
      }
      <div class="truncate">{{ question.question }}</div>
    </div>
    <div class="text-sm italic truncate">{{ this.extractTextFromHtml(question.description) }}</div>
  </div>
  @if (!visualisationMode) {
    <div class="cursor-pointer" (click)="deleteSelectedQuestion(question.id)">
      <mat-icon class="m-3">delete</mat-icon>
    </div>
  } @else {
    <mat-checkbox [checked]="isNotApplicable" (click)="setResponseNonApplicable()">
      Non Applicable
    </mat-checkbox>
  }
</div>

<div class="px-8 h-2/3 flex-col italic text-gray-200">
  <div class="flex justify-center items-center" [formGroup]="questionAnswerForm">
    @if (questionPossibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Text libre</mat-label>
        <input matInput #message [maxLength]="questionPossibleAnswer.max" formControlName="answerText" placeholder="Text libre" type="text">
        <mat-hint align="end">{{message.value.length}} / {{ questionPossibleAnswer.max }}</mat-hint>
      </mat-form-field>
    } @else if (questionPossibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Mail</mat-label>
        <input matInput [maxLength]="questionPossibleAnswer.max" formControlName="answerMail" placeholder="example@example.com" type="email">
        <mat-hint>Please enter a valid email address</mat-hint>
      </mat-form-field>
    } @else if (questionPossibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="answerDate" (dateChange)="onFormChanges()">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    } @else if (questionPossibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
      <mat-form-field appearance="fill" (change)="onFormChanges()">
        <mat-label>Number</mat-label>
        <input matInput [minLength]=questionPossibleAnswer.min [maxLength]=questionPossibleAnswer.max formControlName="answerNumber" placeholder="Number" type="number">
        <mat-hint align="start">Min : {{ questionPossibleAnswer.min }}</mat-hint>
        <mat-hint align="end">Max : {{ questionPossibleAnswer.max }}</mat-hint>
      </mat-form-field>
    }
  </div>
</div>
