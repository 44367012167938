<section class="h-full relative">
<div class="flex h-full">
  @if (showSummary) {
    <div class="h-full w-1/5 overflow-y-auto overflow-x-hidden">
      <app-form-summary [thirdStepFormGroup] = thirdStepForm [isSummaryBlockedForVisualisation]="visualisationModeForSummary"></app-form-summary>
    </div>
  }

  <div [class]="showSummary ? 'h-full w-4/5' : 'h-full w-full'">
    <div class="w-full">
      <h1 class="flex justify-center bg-black text-white py-2 text-lg fixed left-0 right-0 z-40">{{_editMode ? 'Édition d\'un formulaire' : 'Création d\'un formulaire'}}</h1>
    </div>

    <mat-stepper #stepper [linear]="true" [labelPosition]="'bottom'" class="scroll-auto pt-12 h-full" (selectionChange)="onStepChange($event)">

      <mat-step [stepControl]="firstStepForm" class="flex flex-col">
        <form [formGroup]="firstStepForm" class="h-full flex flex-col">
          <ng-template matStepLabel>Initialisation</ng-template>
          @if(!_loading && this.formService.isErrorServer) {
        <div class="p-32 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
          Erreur serveur
        </div>
      } @else if (!_loading && !this.formService.isErrorServer) {
        <div class="flex justify-center grow mt-7">
          <mat-icon class="text-red-500 mr-1">@if(this.errorMessage) {error}</mat-icon>
          <div class="text-red-500 text-sm mt-0.5 truncate">{{errorMessage}}</div>
        </div>

        <app-form-initialization class="h-full" [firstStepFormGroup] = firstStepForm></app-form-initialization>
      } @else {
          <app-loader></app-loader>
      }

          <section class="flex justify-between left-2 right-2 mt-5">
          <button (click)="this.returnToLibraryRefreshed()"
                  class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500">
            <mat-icon class="text-[24px]">arrow_back_ios</mat-icon>
            <span class="h-[24px]">Retour</span>
          </button>

          <div class="flex gap-5">
            @if (!_editMode && this.formId === undefined){
              <button
                (click) = saveForm(FormStatus.DRAFT)
                [disabled]="!firstStepForm.valid"
                [ngClass]="firstStepForm.valid? 'border-blue-500 bg-blue-500' : 'border-gray-500 bg-gray-500' "
                class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white">
                <span class="h-[24px]">Enregistrer et Suivant</span>
                <mat-icon class="text-[24px]">arrow_forward_ios</mat-icon>
              </button>
            } @else {
              <button
                [disabled]="!firstStepForm.valid"
                (click)="saveForm(FormStatus.DRAFT)"
                class="flex border-2 px-4 py-2 rounded "
                [ngClass]="firstStepForm.valid? 'border-blue-500 text-blue-500' : 'border-gray-500 text-gray-500' ">
                <mat-icon class="text-[24px]">save</mat-icon>
                <span class="h-[24px]">Quitter et enregistrer en brouillon</span>
              </button>

              <button matStepperNext
                      [disabled]="!firstStepForm.valid"
                      [ngClass]="firstStepForm.valid? 'border-blue-500 bg-blue-500' : 'border-gray-500 bg-gray-500' "
                      class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white">
                <span class="h-[24px]">Suivant</span>
                <mat-icon class="text-[24px]">arrow_forward_ios</mat-icon>
              </button>
            }
          </div>
        </section>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondStepForm">
        <ng-template matStepLabel>Page de garde</ng-template>
        @if(!_loading && this.formService.isErrorServer) {
      <div class="p-32 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
        Erreur serveur
      </div>
    } @else if (!_loading && !this.formService.isErrorServer) {
      <app-cover-page class="grow h-4/5" [secondStepFormGroup] = secondStepForm></app-cover-page>
    } @else {
      <app-loader></app-loader>
    }
        <section class="flex justify-between left-2 right-2 mt-5">
          <button matStepperPrevious class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500">
            <mat-icon class="text-[24px]">arrow_back_ios</mat-icon>
            <span class="h-[24px]">Retour</span>
          </button>

          <div class="flex gap-5">
          <button (click)="saveForm(FormStatus.DRAFT)"
                  class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500">
            <mat-icon class="text-[24px]">save</mat-icon>
            <span class="h-[24px]">Quitter et enregistrer en brouillon</span>
          </button>

          <button matStepperNext
                  [disabled]="!secondStepForm.valid"
                  [ngClass]="secondStepForm.valid? 'border-blue-500 bg-blue-500' : 'border-gray-500 bg-gray-500' "
                  class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white">
            <span class="h-[24px]">Suivant</span>
            <mat-icon class="text-[24px]">arrow_forward_ios</mat-icon>
          </button>
          </div>
        </section>
      </mat-step>

      <mat-step [stepControl]="thirdStepForm">
        <ng-template matStepLabel>Ajout de questions</ng-template>
        @if(!_loading && this.formService.isErrorServer) {
          <div class="p-32 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
            Erreur serveur
          </div>
        } @else if (!_loading && !this.formService.isErrorServer) {
          <app-form-questions-list-editor
            class="h-[88%] overflow-hidden"
            [thirdStepFormGroup] = thirdStepForm (blockSummary)="blockSummary($event)"></app-form-questions-list-editor>
        } @else {
          <app-loader></app-loader>
        }
        <section class="flex justify-between left-2 right-2 mt-5">
          <button matStepperPrevious class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500">
            <mat-icon class="text-[24px]">arrow_back_ios</mat-icon>
            <span class="h-[24px]">Retour</span>
          </button>

          <div class="flex gap-5">
            <button class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500 gap-1"
                    (click)="saveForm(FormStatus.DRAFT)">
              <span class="h-[24px]" >Sauvegarder & Quitter</span>
              <mat-icon class="text-[24px]">save</mat-icon>
            </button>

            <button matStepperNext class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white gap-1"
                    (click)="saveForm(FormStatus.INACTIVE)">
              <span class="h-[24px]">Publier</span>
              <mat-icon class="text-[24px]">published_with_changes</mat-icon>
            </button>
          </div>
        </section>
      </mat-step>

    </mat-stepper>
  </div>
</div>
</section>
