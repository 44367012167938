<div class="h-1/3 flex mb-2">
  <div class="grow px-8 py-3 w-11/12">
    <div class="font-bold flex gap-2 items-center w-full">
      @if (showIcon) {
        <mat-icon class="opacity-60 min-w-6" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(question.type)">{{QuestionTypeUtil.getQuestionTypeIcon(question.type)}}</mat-icon>
      }
      <div class="truncate">{{ question.question }}</div>
    </div>
    <div class="text-sm italic truncate">{{ this.extractTextFromHtml(question.description) }}</div>
  </div>
  @if (!visualisationMode) {
    <div class="cursor-pointer" (click)="deleteSelectedQuestion(question.id)">
      <mat-icon class="m-3">delete</mat-icon>
    </div>
  } @else {
    <mat-checkbox [checked]="isNotApplicable" (click)="setResponseNonApplicable()">
      Non Applicable
    </mat-checkbox>
  }
</div>
<div class="px-8 h-2/3 flex-col italic text-gray-200" [formGroup]="questionAnswerForm">
  @for (possibleAnswer of question.questionPossibleAnswers; track possibleAnswer; let i = $index){
    <div class="flex justify-center items-center">
      @if (possibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
        <div class="flex flex-col w-full gap-3">
          <div class="w-full flex justify-start text-blue-950">
            {{ possibleAnswer.value }}
          </div>
          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Text libre</mat-label>
            <input matInput #message [formControlName]="'answerText' + i" [maxLength]="possibleAnswer.max" placeholder="Text libre" type="text">
            <mat-hint align="end">{{message.value.length}} / {{ possibleAnswer.max }}</mat-hint>
          </mat-form-field>
        </div>
      } @else if (possibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
        <div class="flex flex-col w-full gap-3">
          <div class="w-full flex justify-start text-blue-950">
            {{ possibleAnswer.value }}
          </div>
          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Mail</mat-label>
            <input matInput [maxLength]="possibleAnswer.max" [formControlName]="'answerMail' + i" placeholder="example@example.com" type="email">
            <mat-hint>Please enter a valid email address</mat-hint>
          </mat-form-field>
        </div>
      } @else if (possibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
        <div class="flex flex-col w-full gap-3">
          <div class="w-full flex justify-start text-blue-950">
            {{ possibleAnswer.value }}
          </div>
          <mat-form-field class="w-3/4">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="'answerDate' + i" (dateChange)="onFormChanges()">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      } @else if (possibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
        <div class="flex flex-col w-full gap-3">
          <div class="w-full flex justify-start text-blue-950">
            {{ possibleAnswer.value }}
          </div>
          <mat-form-field appearance="fill" class="w-3/4">
            <mat-label>Number</mat-label>
            <input matInput (change)="onFormChanges()" [minLength]=possibleAnswer.min [maxLength]=possibleAnswer.max [formControlName]="'answerNumber' + i" placeholder="Number" type="number">
            <mat-hint align="start">Min : {{ possibleAnswer.min }}</mat-hint>
            <mat-hint align="end">Max : {{ possibleAnswer.max }}</mat-hint>
          </mat-form-field>
        </div>
      }
    </div>
  }
</div>
