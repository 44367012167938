@if (isLoading){
  <app-loader></app-loader>
} @else {
  <div class="flex h-full">
    <div class="grow p-2 overflow-scroll pr-4">
      @if (isLibraryDisplayed) {
        <app-questions-list
          [mode]="'form'"
          (questionSelected)="collectQuestion($event)"
        ></app-questions-list>
      } @else {
        @for (item of getQuestionSectionOrganizedList(); track $index ) {
          <div class="w-full flex flex-col">
            <div class="flex mb-10">
              <div class="text-2xl text-blue-800 w-10/12">{{$index+1}} - {{item.section.name}} :</div>
              <mat-form-field appearance="fill" class="max-h-[80px]">
                <mat-label>Type de synthèse</mat-label>
                <mat-select [(value)]="item.section.synthesisType" (selectionChange)="editSectionSynthesis(item.section, $event.value)">
                  @for (synthesis of getAvailableSynthesisTypes(item); track synthesis) {
                    <mat-option [value]="synthesis.key">{{synthesis.value}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

          @if(item.questions.length === 0) {
            <div class="h-28 mt-8 mb-1 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
              Ajoutez une question!
            </div>
          } @else {
            @for (elt of item.questions; track $index) {
              <div class="flex flex-col items-center">
                <div class="w-3/4">
                  @if(isQuestionTypeAuthorized(elt.type)) {
                    <div class="flex">
                      <div class="w-20 h-10 text-xs flex justify-center items-center mr-0.5 cursor-pointer"
                           [ngClass]="this.isNavigationMenuDisplayed(elt.id) ? 'border-x border-t border-red-400 text-red-400 ' : 'bg-red-400 text-white' "
                           (click)="this.navigationToogle(elt.id)">Question</div>

                        <div class="w-20 h-10 text-xs flex justify-center items-center cursor-pointer"
                             [ngClass]="!this.isNavigationMenuDisplayed(elt.id)  ? 'border-x border-t border-blue-400 text-blue-400 ' : 'bg-blue-400 text-white' "
                             (click)="this.navigationToogle(elt.id)">Navigation</div>
                      </div>
                    }

                    <div [class]="this.isNavigationMenuDisplayed(elt.id) ? 'h-48 border border-solid border-gray-200 flex flex-col mb-4 px-8 py-6': 'border border-solid border-gray-200 flex flex-col mb-4 px-8 py-6'">
                      @if(!this.isNavigationMenuDisplayed(elt.id)) {
                        @if (elt.type === QuestionTypeEnum.UNIQUE_CHOICE || elt.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
                          <app-unique-multiple-question-display [question]="elt" (deleteQuestion)="removeQuestionFromList($event)"></app-unique-multiple-question-display>
                        } @else if (elt.type === QuestionTypeEnum.RANK) {
                          <app-rank-question-display [question]="elt" (deleteQuestion)="removeQuestionFromList($event)"></app-rank-question-display>
                        } @else if (elt.type === QuestionTypeEnum.EVAL) {
                          <app-evaluation-question-display [question]="elt" (deleteQuestion)="removeQuestionFromList($event)"></app-evaluation-question-display>
                        } @else if (elt.type === QuestionTypeEnum.FREE_TEXT) {
                          <app-free-text-question-display [question]="elt" (deleteQuestion)="removeQuestionFromList($event)"></app-free-text-question-display>
                        } @else if (elt.type === QuestionTypeEnum.MULTI) {
                          <app-multi-question-display [question]="elt" (deleteQuestion)="removeQuestionFromList($event)"></app-multi-question-display>
                        }
                      } @else {
                        <div class="grow overflow-scroll">

                          @if(this.navNodeList(elt.id).length) {
                            <div class="flex w-full mt-1">
                              <div class="mr-2 mt-5"> Si choix de réponse :</div>
                              <form class="grow">
                                <div> @for (navNode of this.navNodeList(elt.id); track $index) {
                                  <div class="flex grow">
                                    <div>
                                      <mat-form-field appearance="outline" class="w-12">
                                        <mat-label>N°</mat-label>
                                        <input matInput
                                               [(ngModel)]="navNode.nextQuestionId"
                                               [name]="'nextQuestionId_' + $index"
                                        >
                                      </mat-form-field>
                                    </div>
                                    <div class="flex w-3/5">
                                      <div class="mx-2 mt-5 whitespace-nowrap"> aller à :</div>
                                      <mat-form-field appearance="outline">
                                        <mat-label>Question</mat-label>
                                        <mat-select
                                          [(ngModel)]="navNode.valueId"
                                          [name]="'valueId_' + $index">
                                          @for (elt of displayQuestion(elt.id); track $index) {
                                            <mat-option [value]="elt.id.toString()">{{elt.question}}</mat-option>
                                          }
                                        </mat-select>
                                      </mat-form-field>
                                      <mat-icon class="mt-5 mx-5 cursor-pointer" (click)="deleteNavigation(elt.id, $index)">delete</mat-icon>
                                    </div>
                                  </div>
                                }</div>
                              </form>
                            </div>
                          }

                          <button mat-stroked-button (click)="addNavigation(elt.id)">
                            <mat-icon class="w-5 h-5">add</mat-icon> Ajouter une navigation
                          </button>

                        </div>

                      }
                    </div>
                  </div>
                </div>
              }
            }

            <div class="w-11/12 border-t-blue-800 border-t my-8 mr-auto ml-auto"></div>
          </div>
        }
      }

  </div>

  @if(getQuestionSectionOrganizedList().length > 0) {
    <div class="content-center">
      <div class="flex flex-col border border-gray-300 border-solid py-2">
        <mat-icon class="cursor-pointer"
                  [ngStyle]="{'color':isLibraryDisplayed ? 'var(--violine)' : 'black' }"
                  matTooltip="Ajout de question"
                  (click)="displayLibrary()">add</mat-icon>
      </div>
    </div>
  }
</div>
}
