import {Tag} from "./tag.model";

export interface QuestionnaireModel {
  id : number | null,
  title : string,
  status : QuestionnaireStatus,
  category : string,
  nbFinalized: number,
  totalSent: number,
  sentAt : Date
}


export enum QuestionnaireStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED'
}


export interface UserFormModel {
  uuid: string,
  userUuid: string,
  form: DashboardFormModel,
  collabMatricule: string,
  codeAgence: string,
  collabRegionId: number,
  status: string,
  sendDate: Date,
  aggregatedStatus: string,
  statusSummary: string
}

export interface DashboardFormModel {
  uuid: string,
  title: string,
  category: string,
  tagsList: Tag[],
}

export interface DashboardStatisticsModel {
  totalUserForms: number,
  totalSentUserForms: number,
  totalInProgressUserForms: number,
  totalCompletedUserForms: number
}
