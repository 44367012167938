/* src/app/components/forms-dashboard/forms-dashboard.component.scss */
.big-icon {
  font-size: 72px;
  height: auto;
  width: auto;
}
.gradient-radial {
  background:
    radial-gradient(
      circle,
      #93c5fd,
      #3b82f6);
}
::ng-deep .mat-mdc-paginator-page-size-value {
  display: none;
}
::ng-deep .mat-mdc-paginator-page-size-label {
  display: none;
}
/*# sourceMappingURL=forms-dashboard.component.css.map */
