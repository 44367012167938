import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {finalize, Observable, of, tap} from 'rxjs';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatDivider} from '@angular/material/divider';
import {MatFormField} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatInput, MatLabel} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList} from '@angular/cdk/drag-drop';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-rank-answer',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatSlideToggle,
    MatDivider,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatLabel,
    MatIcon,
    CdkDrag,
    CdkDropList,
    CdkDragHandle,
    NgForOf,
    NgIf
  ],
  templateUrl: './rank-answer.component.html',
  styleUrl: './rank-answer.component.scss'
})
export class RankAnswerComponent implements OnInit {

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  _loading: boolean = false;

  questionAnswers: FormArray = new FormArray<any>([]);

  ngOnInit() {
    this._switchLoading().pipe(
      tap(() => this.initForm()),
      finalize(() => this._switchLoading())
    ).subscribe()
  }

  initForm() {
    this.questionAnswers = this.firstStepFormGroup.get('questionAnswers') as FormArray;
    if (!this._editMode) this.questionAnswers.push(this.createFormGroupAnswer());
    else {
      this.questionAnswers.controls.sort((a, b) => a.get('displayOrder')?.value - b.get('displayOrder')?.value)
    }
  }

  addElement() {
    this.questionAnswers.push(this.createFormGroupAnswer());
  }

  drop(event: CdkDragDrop<FormGroup[]>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    const formArray = this.answerArray;
    const from = event.previousIndex;
    const to = event.currentIndex;

    const item = formArray.at(from);
    formArray.removeAt(from);
    formArray.insert(to, item);
  }

  get answerArray() {
    return this.firstStepFormGroup.get('questionAnswers') as FormArray
  }

  private createFormGroupAnswer(): FormGroup {
    return new FormGroup({
      displayOrder: new FormControl(null),
      value: new FormControl('', Validators.required),
      formatType: new FormControl(''),
      min: new FormControl(null),
      max: new FormControl(null),
    })
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

}
