import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Level, NotificationService} from "../front/notifications.service";
import {Section} from "../../models/form.model";
import {catchError, first, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";

const API_URL = environment.apiURL;

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  private _baseUrl: string = API_URL + '/sections';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }


  // [POST] /api/sections
  addSection(section: Section): Observable<Section> {
    return this.httpClient.post<Section>(this._baseUrl, section)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  // [PUT] /api/sections
  updateSection(section: Section): Observable<Section> {
    return this.httpClient.put<Section>(this._baseUrl, section)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  // [DELETE] /api/sections/:id
  deleteSection(sectionId: number): Observable<Section> {
    return this.httpClient.delete<Section>(this._baseUrl + '/' + sectionId)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

}
