import {Component, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {PATHS} from "../../../app.routes";
import {environment} from "../../../../environments/environment";
import {UserRoleEnum} from "../../../enums/userRole.enum";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-top-navigation',
  standalone: true,
  imports: [
    MatIcon,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    NgIf
  ],
  templateUrl: './top-navigation.component.html',
  styleUrl: './top-navigation.component.scss'
})
export class TopNavigationComponent implements OnInit {
  protected readonly PATHS = PATHS;
  isIconVisible: boolean = true;
  env: string = environment.env;

  _isLoading = false;

  userRoles: UserRoleEnum[] = [UserRoleEnum.VALIDEUR, UserRoleEnum.ANALYSTE, UserRoleEnum.CONCEPTEUR, UserRoleEnum.CONTRIBUTEUR];

  constructor() {
  }

  ngOnInit() {
    console.log(this.userRoles);
  }

  private switchLoading(): Observable<{}> {
    this._isLoading = !this._isLoading;
    return of({})
  }

  protected readonly UserRoleEnum = UserRoleEnum;

  logout() {
    console.log("logout");
  }
}
