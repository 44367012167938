export enum FormDisplayMode {
  CONCEPTION="CONCEPTION",
  VISUALISATION="VISUALISATION",
  SYNTHESE="SYNTHESE"
}

export enum FormStatusAction {
  DUPLICATE="DUPLICATE",
  ARCHIVE="ARCHIVE",
  DELETE="DELETE"
}
