import {Component, OnInit} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {MatIcon} from '@angular/material/icon';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatFormField, MatOption, MatSelect} from '@angular/material/select';
import {MatInput, MatLabel} from '@angular/material/input';
import {QuillEditorComponent} from 'ngx-quill';
import {MatDivider} from '@angular/material/divider';
import {MatButton} from '@angular/material/button';
import {NgClass, NgIf} from '@angular/common';
import {finalize, Observable, of, switchMap, tap} from 'rxjs';
import {Level, NotificationService} from '../../../services/front/notifications.service';
import {
  Question,
  QuestionForm,
  QuestionPossibleAnswers,
  QuestionReport,
  QuestionReportForm
} from '../../../models/questions.model';
import {QuestionStatus, QuestionTypeEnum} from '../../../enums/questions.enum';
import {QuestionService} from '../../../services/back/question.service';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionSettingsStepComponent} from './question-settings-step/question-settings-step.component';
import {TextReportStepComponent} from './text-report-step/text-report-step.component';
import {PATHS} from "../../../app.routes";

@Component({
  selector: 'app-create-question',
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    MatStepperNext,
    MatStepperPrevious,
    MatIcon,
    MatSelect,
    MatLabel,
    MatFormField,
    MatOption,
    QuillEditorComponent,
    MatDivider,
    MatInput,
    MatButton,
    NgIf,
    QuestionSettingsStepComponent,
    NgClass,
    TextReportStepComponent,
  ],
  templateUrl: './create-question.component.html',
  styleUrl: './create-question.component.scss'
})
export class CreateQuestionComponent implements OnInit {

  _loading: boolean = false;
  _editMode: boolean = false;
  questionId: number | undefined = undefined;

  firstStepForm: FormGroup = new FormGroup({});
  secondStepForm: FormGroup = new FormGroup({});

  question: Question | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private questionService: QuestionService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.questionId = params['id'];
      if (this.questionId !== undefined) this._editMode = !this._editMode;
    });

    if (this._editMode) this.enterEditMode();
    else this.enterCreationMode();
  }

  enterEditMode(): void {
    this._switchLoading().pipe(
      switchMap(() => this.getDetailedQuestionWithId()),
      tap(questionForm => this.buildEditionForm(questionForm)),
      finalize(() => this._switchLoading())
    ).subscribe();
  }

  enterCreationMode(): void {
    this._switchLoading().pipe(
      tap(() => this.createFormFirstStep()),
      tap(() => this.createFormSecondStep()),
      finalize(() => this._switchLoading())
    ).subscribe();
  }

  getDetailedQuestionWithId(): Observable<Question | null> {
    if (this.questionId !== undefined) {
      return this.questionService.getQuestion(this.questionId);
    } else return of(null)
  }

  buildEditionForm(question: Question | null): void {
    this.question = question;
    if (question !== null) {
      this.firstStepForm = this._formBuilder.group({
        type: new FormControl((question.type === QuestionTypeEnum.MULTIPLE_CHOICE || question.type === QuestionTypeEnum.UNIQUE_CHOICE) ? QuestionTypeEnum.CHOICES : question.type),
        subType: new FormControl(this.getFormSubType(question.type)),
        question: new FormControl(question.question, Validators.required),
        category: new FormControl(question.category, Validators.required),
        description: new FormControl(question.description),
        questionAnswers: this.buildEditionFormAnswerArray(question.questionPossibleAnswers)
      });

      let reportElements = this.buildEditionSecondStepReportElementArray(question.questionReportElements);
      this.secondStepForm = this._formBuilder.group({
        reportElements: reportElements
      })
    } else {
      this.notificationService.sendNotification(
        Level.ERROR,
        "Impossible de récupérer cette question vous aller être redirigé vers la liste des question",
        10000);
    }
  }

  buildEditionFormAnswerArray(questionAnswers: QuestionPossibleAnswers[]): FormArray {
    const answerArray: FormArray = this._formBuilder.array([]) as FormArray;

    questionAnswers.forEach((a: QuestionPossibleAnswers) => {
      const group: FormGroup = new FormGroup({
        displayOrder: new FormControl(a.displayOrder),
        value: new FormControl(a.value),
        formatType: new FormControl(a.formatType),
        min: new FormControl(a.min),
        max: new FormControl(a.max),
      });
      answerArray.push(group);
    });

    return answerArray
  }

  buildEditionSecondStepReportElementArray(questionReports: QuestionReport[]): FormArray {
    const reportElementArray: FormArray = this._formBuilder.array([]) as FormArray;

    questionReports.forEach((a: QuestionReport) => {
      const group: FormGroup = new FormGroup({
        flag: new FormControl(a.flag),
        text: new FormControl(a.text),
        answer: new FormControl(a.answer)
      });
      reportElementArray.push(group);
    });
    return reportElementArray;
  }

  getFormSubType(type: string) {
    if (type === QuestionTypeEnum.MULTIPLE_CHOICE || type === QuestionTypeEnum.UNIQUE_CHOICE) {
      return type;
    } else return null;
  }

  createFormFirstStep(): void {
    this.firstStepForm = this._formBuilder.group({
      type: new FormControl(null, Validators.required),
      subType: new FormControl(QuestionTypeEnum.UNIQUE_CHOICE),
      question: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      description: new FormControl(''),
      questionAnswers: this._formBuilder.array([])
    });
  }

  createFormSecondStep() {
    this.secondStepForm = this._formBuilder.group({
      reportElements: this._formBuilder.array([])
    });
  }

  saveAndQuit(quit: boolean = false) {
    if (this.firstStepForm.invalid) {
      this.notificationService.sendNotification(Level.ERROR, "Merci de remplir tous les champs obligatoire!", 3000);
      return;
    }
    if (this._editMode && this.question?.status !== undefined) {
      this.updateQuestion(this.question.status);
    } else {
      if (!quit) this._editMode = !this._editMode;
      this.saveQuestion(QuestionStatus.DRAFT);
    }

    if (quit) this.goBackToLibrary();
  }

  publish() {
    if (this.firstStepForm.invalid) {
      this.notificationService.sendNotification(Level.ERROR, "Merci de remplir tous les champs obligatoire!", 3000);
      return;
    }

    if (this._editMode && this.question?.status !== undefined) {
      this.updateQuestion(this.question.status);
    } else {
      this.saveQuestion(QuestionStatus.INACTIVE);
    }

    this.goBackToLibrary();
  }

  saveAndQuitReport() {
    if (this.secondStepForm.invalid) {
      this.notificationService.sendNotification(Level.ERROR, "Merci de remplir tous les champs obligatoire!", 3000);
      return;
    }

    if (this._editMode && this.question?.status !== undefined) {
      this.updateQuestionReport(this.question.status);
    } else {
      this.saveQuestionReport(QuestionStatus.DRAFT);
    }

    this.goBackToLibrary();
  }

  publishReport() {
    if (this.secondStepForm.invalid) {
      this.notificationService.sendNotification(Level.ERROR, "Merci de remplir tous les champs obligatoire!", 3000);
      return;
    }

    if (this._editMode && this.question?.status !== undefined) {
      this.updateQuestionReport(this.question.status === QuestionStatus.DRAFT ? QuestionStatus.INACTIVE : this.question.status);
    } else {
      this.saveQuestionReport(QuestionStatus.INACTIVE);
    }

    this.goBackToLibrary();
  }

  goBackToLibrary() {
    this.router.navigate(['private/' + PATHS.LIBRARY.BASE], { state: { refresh: true } });
  }

  private updateQuestion(status: QuestionStatus) {
    const questionForm: QuestionForm = this.buildQuestionForm(status);

    if (this.questionId) this.questionService.updateQuestion(this.questionId, questionForm)
      .subscribe(res =>
        this.notificationService.sendNotification(Level.INFO, 'Votre question vient d\'être mise à jour !'));
  }

  private saveQuestion(status: QuestionStatus) {
    const questionForm: QuestionForm = this.buildQuestionForm(status);

    this.questionService.saveQuestion(questionForm)
      .pipe(tap(res => {
        this.questionId = res.id;
        this.question = res
      }))
      .subscribe(_ =>
        this.notificationService.sendNotification(Level.INFO, 'Création de votre nouvelle question au statut ' + this.question?.status.toLowerCase()));
  }

  private updateQuestionReport(status: QuestionStatus) {
    const questionReportForm: QuestionReportForm | null = this.buildQuestionReportForm(status);
    if (questionReportForm && this.questionId) {
      this.questionService.updateQuestionReport(this.questionId, questionReportForm).subscribe(res => console.log(res));
    } else {
      this.notificationService.sendNotification(Level.ERROR, "Impossible de mettre à jour les textes conditionnels !");
    }
  }

  private saveQuestionReport(status: QuestionStatus) {
    const questionReportForm: QuestionReportForm | null = this.buildQuestionReportForm(status);
    if (questionReportForm) {
      this.questionService.saveQuestionReport(questionReportForm).subscribe(res => console.log(res));
    } else {
      this.notificationService.sendNotification(Level.ERROR, "Impossible de sauvegarder les textes conditionnels !");
    }
  }

  private buildQuestionForm(status: QuestionStatus): QuestionForm {
    if (this.firstStepForm.get('type')?.value === QuestionTypeEnum.RANK || this.firstStepForm.get('type')?.value === QuestionTypeEnum.MULTI) {
      const array = this.firstStepForm.get('questionAnswers') as FormArray;

      for (let index = 0; index < array.length; index++) {
        const item = <FormGroup>array.at(index);
        item.get('displayOrder')?.setValue(index);
      }
    }
    return {
      userTag: "BAPT0000", //todo add the potential id of the user or his fullname
      question: this.firstStepForm.get('question')?.value,
      description: this.firstStepForm.get('description')?.value,
      status: status,
      type: this.firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES ? this.firstStepForm.get('subType')?.value : this.firstStepForm.get('type')?.value,
      categoryId: this.firstStepForm.get('category')?.getRawValue().id,
      questionPossibleAnswers: this.firstStepForm.get('questionAnswers')?.getRawValue(),
      isInLibrary: true
    }
  }

  private buildQuestionReportForm(status: QuestionStatus): QuestionReportForm | null {
    if (this.questionId === undefined) {
      this.notificationService.sendNotification(Level.ERROR, "Impossible de sauvegarder car la questions n'existe pas encore !");
      return null;
    }
    else {
      return {
        status: status,
        reportElements: this.secondStepForm.get('reportElements')?.getRawValue()
      }
    }
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  protected readonly QuestionTypeEnum = QuestionTypeEnum;
  protected readonly QuestionStatus = QuestionStatus;
}
