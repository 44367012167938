<section class="flex flex-col w-full h-full px-8 py-5">
  <div class="flex justify-between items-center">
    <div>
      <h3 class="font-bold text-2xl">Liste des formulaires</h3>
    </div>
    <div>
      <button [routerLink]="PATHS.FORMS.CREATE" class="flex items-center bg-black text-white py-3 px-6 w-52 rounded">
        <mat-icon>add</mat-icon>
        <span class="text-md h-5 flex">Créer un formulaire</span>
      </button>
    </div>
  </div>

  <form class="flex items-baseline mt-8 gap-4 w-3/5" [formGroup]="filterForm!" >
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Rechercher</mat-label>
      <mat-icon matSuffix>search</mat-icon>
      <input matInput placeholder="Rechercher" formControlName="title">
    </mat-form-field>

    <!-- Category -->
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Categorie</mat-label>
      <mat-select formControlName="category">
        <mat-option [value]="''">Tous</mat-option>
        @for (category of categoryKeys; track category) {
          <mat-option [value]="category">{{category | titlecase}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <!-- Tags -->
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-select formControlName="tags" multiple>
        <mat-select-trigger>
          {{this.filterForm!.value.tags[0] || ''}}
          @if ((this.filterForm!.value.tags.length || 0) > 1) {
            <span class="example-additional-selection">
          (+{{(this.filterForm!.value.tags.length || 0) - 1}} {{this.filterForm!.value.tags.length === 2 ? 'other' : 'others'}})
        </span>
          }
        </mat-select-trigger>
        @for (tag of tagsList; track tag) {
          <mat-option [value]="tag.title">{{tag.title}}</mat-option>
        }
      </mat-select>
    </mat-form-field>


    <!-- Status -->
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>État</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="this.defaultStatus">Tous</mat-option>
        @for (status of defaultStatus; track status) {
          <mat-option [value]="status">{{status | titlecase}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <app-button
      label="Filtrer"
      [disabled]="!this.subscription.closed"
      (buttonClick)="applyFilter()"
      color="sky-blue"
    ></app-button>

  </form>
  <!-- Display Selected Filters as chips-->
  <div class="flex flex-wrap gap-3">
    @for (chip of chipsList; track chip) {
      <mat-chip-row removable (removed)="removeFilterFromChipList(chip)">
        {{ chip.key }} - {{ chip.value | titlecase }}
        <button matChipRemove>
          <mat-icon >cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
  </div>

  @if(!this.subscription.closed){
    <app-loader></app-loader>
  } @else if (this.subscription.closed && this.formList.length > 0) {
    <div class="flex flex-col mt-8">
      <div class="flex flex-wrap mb-8 justify-center">
        @for (form of displayedForms; track $index) {
          <app-form-card [form]="form" (archiveOrDeleteFormEvent)="archiveOrDeleteFormEvent($event, form)"></app-form-card>
        }
      </div>
    <mat-paginator class="m-auto" [pageSizeOptions]="[this.pageSize]"
                   [length]="this.totalNumberOfItem"
                   [pageSize]="this.filterForm!.get('itemNumberPerPage')!.value"
                   [pageIndex]="filterForm!.get('pageNumber')!.value"
                   [disabled]="!previousPageActive && !nextPageActive"
                   (page)="onPageChange($event)"
                   showFirstLastButtons>
    </mat-paginator>
    </div>
  } @else {
    <div class="p-32 w-full flex justify-center text-center text-[var(--light-grey-1)]">
      <p class="text-center">Aucun formulaire trouvé</p>
    </div>
  }
</section>
