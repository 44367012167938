<section class="flex flex-col items-center">
  <section class="w-3/5">
    <h3 class="text-2xl font-bold pb-3">Définissez les paramètres de la question</h3>
    <form [formGroup]="firstStepFormGroup">
      <mat-form-field class="w-full">
        <mat-label>Type de question</mat-label>
        <mat-select [formControlName]="'type'" [value]="selectedOption?.type">

          <mat-select-trigger class="flex gap-2">
            <mat-icon *ngIf="selectedOption">{{selectedOption.icon}}</mat-icon>
            <span>{{selectedOption ? selectedOption.name : ''}}</span>
          </mat-select-trigger>

          @for (item of questionTypeList; track $index) {
            @for (question of item; track $index) {
              <mat-option [value]="question.type" [disabled]="question.disabled">
                <mat-icon>{{question.icon}}</mat-icon>
                {{question.name}}
              </mat-option>
            }
            <mat-divider *ngIf="questionTypeList.length !== ($index + 1)"></mat-divider>
          }

        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Catégorie</mat-label>
        <mat-select [formControlName]="'category'" [value]="selectedCategory">
          <mat-select-trigger>
            <span>{{selectedCategory?.name}}</span>
          </mat-select-trigger>
          @for (category of categoriesList; track $index) {
            <mat-option [value]="category">
              {{category.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Votre Question</mat-label>
        <input matInput type="text" [formControlName]="'question'" placeholder="votre question">
      </mat-form-field>

      <h4 class="font-bold">Rédigez une description</h4>
      <quill-editor class="w-full mb-5"
                    [modules]="editorConfig.modules"
                    [placeholder]="editorConfig.placeholder"
                    [formControlName]="'description'">
      </quill-editor>

      <mat-divider *ngIf="false"></mat-divider>

      <div class="flex justify-between w-full items-center my-5" *ngIf="false">
        <h4>Insérer une image ou un lien vidéo</h4>
        <div class="flex gap-2">
          <div>
            <button mat-stroked-button><mat-icon>backup</mat-icon>Importer une image</button>
          </div>
          <div>
            <button mat-stroked-button (click)="displayUrl.set(!displayUrl())">
              <mat-icon>{{displayUrl() ? "link_off" : "link" }}</mat-icon>
              {{ displayUrl() ? 'Retirer le lien video' : 'Ajouter un lien video' }}
            </button>
          </div>
        </div>
      </div>

      <mat-form-field *ngIf="displayUrl()" class="w-full mt-5" appearance="outline">
        <mat-label>Ajouter un lien video</mat-label>
        <input matInput type="text">
      </mat-form-field>

      <mat-divider></mat-divider>

      <div *DynamicComponentLoading></div>
    </form>


  </section>

</section>
