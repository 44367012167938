import {Component, Input} from '@angular/core';
import {NotificationsStatus} from "../../../enums/notifications-status";
import {NgClass, NgIf} from "@angular/common";
import {NotificationService} from "../../../services/front/notifications.service";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatIcon
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  @Input() status!: NotificationsStatus;
  protected readonly NotificationsStatus = NotificationsStatus;

  constructor(public notificationService : NotificationService) {
  }

  closeNotification() {
    this.notificationService.notificationSignal.set(false);
  }
}
