import {AnswerFormatTypeEnum, QuestionReportFlag, QuestionStatus, QuestionTypeEnum} from '../enums/questions.enum';
import {FormComponentsType, Section} from "./form.model";

export interface Question {
  id: number;
  parentId: number;
  userTag: string;
  question: string;
  description: string;
  imageUrl: string;
  type: QuestionTypeEnum;
  status: QuestionStatus;
  category: QuestionCategory;
  createdAt: Date;
  updatedAt: Date;
  formsComponentsType: FormComponentsType;
  questionPossibleAnswers: QuestionPossibleAnswers[];
  questionReportElements: QuestionReport[];
}

export interface QuestionSectionOrganized {
  section: Section;
  questions: Question[];
}

export interface QuestionSectionSynthesis {
  section: Section;
  questionReportSynthesis: QuestionReportSynthesis[];
}

export interface QuestionGraphicalSynthesis {
  question: Question;
  questionAnswer: QuestionAnswer;
}

export interface QuestionReportSynthesis {
  question: Question,
  questionReports: QuestionReport[],
}

export interface QuestionRestitutionSynthesis {
  organizedResponses: Map<Question, QuestionAnswer[]>;
}


export interface QuestionCategory {
  id: number;
  name: string;
  color: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface QuestionType {
  type: QuestionTypeEnum;
  icon: string;
  color?: string;
  name: string;
  disabled: boolean;
}

export interface QuestionForm {
  userTag?: string;
  question?: string;
  description?: string;
  type?: QuestionTypeEnum;
  status?: QuestionStatus;
  categoryId?: number;
  questionPossibleAnswers?: QuestionPossibleAnswers[];
  isInLibrary?: boolean
}

export interface QuestionPossibleAnswers {
  id: number;
  displayOrder: number;
  formatType: AnswerFormatTypeEnum;
  value: string;
  min: number;
  max: number;
}

export interface QuestionResponseModel {
  displayOrder: number;
  value: string;
}

export interface QuestionAnswer {
  questionId: number;
  response: QuestionResponseModel[];
}

export interface QuestionAnswerForm {
  formId: string;
  questionAnswers: QuestionAnswer[];
}

export interface QuestionReport {
  id: number;
  questionId: number;
  flag: QuestionReportFlag;
  text: string;
  answer: string;
}

export interface QuestionReportForm {
  status: QuestionStatus;
  reportElements: ReportElement[];
}


export interface ReportElement {
  flag: QuestionReportFlag;
  text: string;
  answer: number;
}

export const questionTypeList: QuestionType[][] = [
  [
    {
      type: QuestionTypeEnum.CHOICES,
      icon: 'check_box',
      name: 'Question à choix unique ou multiple',
      disabled: false
    }
  ],
  [
    {
      type: QuestionTypeEnum.RANK,
      icon: 'low_priority',
      name: 'Classement',
      disabled: false
    },
    {
      type: QuestionTypeEnum.EVAL,
      icon: 'trending_up',
      name: 'Évaluation',
      disabled: false
    }
  ],
  [
    {
      type: QuestionTypeEnum.FREE_TEXT,
      icon: 'notes',
      name: 'Texte libre',
      disabled: false
    }
  ],
  [
    {
      type: QuestionTypeEnum.MULTI,
      icon: 'list',
      name: 'Champs multiple',
      disabled: false
    }
  ]
]
