import {Component} from '@angular/core';
import {NotificationsComponent} from "../../components/shared/notifications/notifications.component";
import {RouterOutlet} from "@angular/router";
import {TopNavigationComponent} from "../../components/shared/top-navigation/top-navigation.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-private-layout',
  standalone: true,
  imports: [
    NotificationsComponent,
    RouterOutlet,
    TopNavigationComponent,
    NgIf
  ],
  templateUrl: './private-layout.component.html',
  styleUrl: './private-layout.component.scss'
})
export class PrivateLayoutComponent {

  _LOADING: boolean = false;


  constructor() {
  }
}
