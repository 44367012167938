import {Injectable} from '@angular/core';
import {InexrefService} from "../back/inexref.service";
import {BehaviorSubject, filter, first, Observable} from "rxjs";
import {User} from "../../models/user.model";
import {UserRoleEnum} from "../../enums/userRole.enum";
import {AccountInfo} from "@azure/msal-browser";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject = new BehaviorSubject<User | undefined>({
    matricule: "MARTBA00",
    nom: "MARTIN",
    prenom: "Baptiste",
    mail: "baptiste.martin@inextenso.fr",
    codeAgence: "578",
    codeRegion: "180",
    codeBureau: "",
    codeEj: "",
    codePole: "",
    collabProfils: [],
    dateModification: "",
    present: false,
    sexe: ""
  });
  private userRoleSubject: BehaviorSubject<UserRoleEnum[]> = new BehaviorSubject<UserRoleEnum[]>([UserRoleEnum.CONTRIBUTEUR, UserRoleEnum.VALIDEUR, UserRoleEnum.ANALYSTE, UserRoleEnum.CONCEPTEUR]);


  constructor(
    private inexrefService: InexrefService
  ) {
  }

  getUser(): Observable<User> {
    return this.userSubject.pipe(filter((user): user is User => user !== undefined), first());
  }

  getUserRoles(): Observable<UserRoleEnum[]> {
    return this.userRoleSubject.asObservable();
  }

  setUserInformation(user: User): void {
    this.userSubject.next(user);
    user.collabProfils.forEach(el => {
      const currentRoles = this.userRoleSubject.value;
      const newRoles = [...currentRoles, el.codeProfil];
      this.userRoleSubject.next(newRoles);
    });
  }

  getUserInformation(account: AccountInfo): Observable<User> {
    return this.inexrefService.getMe("baptiste.martin@inextenso.fr");
  }

}
