import {Component, Input, OnInit} from '@angular/core';
import {
  EvaluationQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/evaluation-question-display/evaluation-question-display.component";
import {FormGroup, FormsModule} from "@angular/forms";
import {
  FreeTextQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/free-text-question-display/free-text-question-display.component";
import {LoaderComponent} from "../loader/loader.component";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatTooltip} from "@angular/material/tooltip";
import {
  MultiQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/multi-question-display/multi-question-display.component";
import {QuestionsListComponent} from "../../library/questions-list/questions-list.component";
import {
  RankQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/rank-question-display/rank-question-display.component";
import {
  UniqueMultipleQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/unique-multiple-question-display/unique-multiple-question-display.component";
import {QuestionAnswer, QuestionAnswerForm, QuestionSectionOrganized} from "../../../models/questions.model";
import {SectionOrganizerService} from "../../../services/front/section-organizer.service";
import {Form} from "../../../models/form.model";
import {SynthesisCardComponent} from "./synthesis-card/synthesis-card.component";

@Component({
  selector: 'app-synthesis',
  standalone: true,
  imports: [
    EvaluationQuestionDisplayComponent,
    FormsModule,
    FreeTextQuestionDisplayComponent,
    LoaderComponent,
    MatButton,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatTooltip,
    MultiQuestionDisplayComponent,
    QuestionsListComponent,
    RankQuestionDisplayComponent,
    UniqueMultipleQuestionDisplayComponent,
    SynthesisCardComponent
  ],
  templateUrl: './synthesis.component.html',
  styleUrl: './synthesis.component.scss'
})
export class SynthesisComponent implements OnInit {

  @Input() thirdStepFormGroup!: FormGroup;
  @Input() responses!: QuestionAnswerForm;
  @Input() form : Form | null = null;

  isLoading: boolean = false;

  constructor(private sectionOrganizerService : SectionOrganizerService) {}

  ngOnInit() {
  }

  getAnswersForSection(questionSection: QuestionSectionOrganized): QuestionAnswer[] {
    if (this.responses === undefined || this.responses.questionAnswers === undefined)
      return [];

    // Récupère tous les questionIds présents dans la section
    const questionIdsInSection = questionSection.questions.map(q => q.id);

    // Filtre les questionAnswers pour ne conserver que ceux dont le questionId est présent dans questionIdsInSection
    return this.responses.questionAnswers.filter(qa => questionIdsInSection.includes(qa.questionId));
  }

  getQuestionSectionOrganizedList() : QuestionSectionOrganized[] {
    if (this.thirdStepFormGroup) {
      return this.sectionOrganizerService.organizeSectionsAndQuestions(this.thirdStepFormGroup?.get('formsComponentsList')?.value);
    }

    return this.sectionOrganizerService.organizeSectionsAndQuestions(this.form?.formsComponentsList || []);
  }
}
