import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {LoaderComponent} from '../../shared/loader/loader.component';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {DatePipe, NgClass, TitleCasePipe} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Params, Router, RouterLink} from '@angular/router';
import {QuestionsStatusTrPipe} from '../../../utils/questions-status-tr.pipe';
import {CustomPaginatorIntl} from '../../shared/custom-paginator-intl';
import {Question} from '../../../models/questions.model';
import {Subject, takeUntil} from 'rxjs';
import {QuestionStatus} from '../../../enums/questions.enum';
import {QuestionService} from '../../../services/back/question.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../services/front/notifications.service';
import {DuplicateDialogComponent} from '../../shared/duplicate-dialog/duplicate-dialog.component';
import {PATHS} from '../../../app.routes';
import {QuestionTypeUtil} from "../../../utils/questions-type-utils";


@Component({
  selector: 'app-questions-list',
  standalone: true,
  imports: [
    LoaderComponent,
    MatPaginator,
    MatTable,
    MatIcon,
    MatTooltip,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    DatePipe,
    RouterLink,
    MatHeaderCellDef,
    MatCellDef,
    QuestionsStatusTrPipe,
    TitleCasePipe,
    NgClass
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  templateUrl: './questions-list.component.html',
  styleUrl: './questions-list.component.scss'
})
export class QuestionsListComponent  implements OnInit, AfterViewInit, OnDestroy{
  @Input() mode!: string;
  @Output() questionSelected: EventEmitter<Question> = new EventEmitter();
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns!: string[];
  dataSource: MatTableDataSource<Question> = new MatTableDataSource<Question>();
  pageSize: number = 10;
  currentPage: number = 0;
  pagedData: MatTableDataSource<Question> = new MatTableDataSource<Question>();
  _unsubscribe = new Subject<void>();
  subscriptionClosed: boolean = false;

  protected readonly QuestionStatus = QuestionStatus;

  constructor(public questionService: QuestionService,
              public dialog: MatDialog,
              public route: ActivatedRoute,
              public notificationService: NotificationService,
              public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navigationState = this.router.getCurrentNavigation()?.extras.state;

        if (navigationState?.['refresh']) {
          console.log("REFRESH")
          this.init();
        }
      }
    });
  }

  ngOnInit() {
    this.init();
    this.route.queryParams
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((params: Params) => {
        if (params['refresh']){
          this.init()
        }
      })
  }

  ngAfterViewInit() {
    this.pagedData.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  init() {
    this.subscriptionClosed = false;
    this.questionService.getQuestions()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        (rsp: Question[]) => {
          this.dataSource.data = rsp;
          this.updatePagedData();
          this.setDisplayedColumns();
          this.subscriptionClosed = true;
        });
  }

  onPageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagedData();
  }

  updatePagedData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedData.data = this.dataSource.data.slice(startIndex, endIndex);
  }

  openDialog(q: Question) {
    const dialogRef:MatDialogRef<DuplicateDialogComponent> = this.dialog.open(DuplicateDialogComponent, {
      width: '672px',
      height: '470px',
      data: {templateId: q.id, newTitle: q.question, objectType: 'question'}
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.init();
      }
    });
  }

  editQuestion(questionId: number) {
    this.router.navigate(["private/" + PATHS.LIBRARY.BASE, PATHS.LIBRARY.EDIT, questionId]);
  }

  isDuplicateAuthorized(element: Question): boolean {
    return element.status.toLowerCase() != QuestionStatus.DRAFT.toLowerCase();
  }

  handleQuestion(question: Question) {
    if (this.mode !== 'library') {
        this.notificationService.setIsLibraryDisplayed(false);
        this.questionSelected.emit(question);
    } else {
      console.log('[WIP] Ce clic bientôt amenera à l\'édition de la question')
      console.log(question);
    }
  }

  setDisplayedColumns() {

    this.displayedColumns = this.mode !== 'library'
      ? ['questionType', 'categoryName', 'name', 'status']
      : ['questionType', 'categoryName', 'name', 'status', 'action'];
  }

  getTitle() {
    return this.mode !== 'library'
      ? 'Selectionnez votre question !'
      : 'Liste de questions';
  }

  protected readonly QuestionTypeUtil = QuestionTypeUtil;
}
