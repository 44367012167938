import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[DynamicComponentLoading]',
  standalone: true
})
export class DynamicComponentLoadingDirective {

  constructor(
    public viewContainerRef: ViewContainerRef
  ) { }
}
