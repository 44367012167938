import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, first, Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {OperationResponseModel} from '../../models/operation-response.model';
import {Level, NotificationService} from "../front/notifications.service";
import {NotificationsStatus} from "../../enums/notifications-status";
import {
  Question,
  QuestionCategory,
  QuestionForm,
  QuestionReport,
  QuestionReportForm
} from '../../models/questions.model';
import {environment} from "../../../environments/environment";

const API_URL = environment.apiURL;

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private _baseUrl: string = API_URL + '/questions';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  // [GET] /api/questions
  getQuestions() : Observable<Question[]> {
    return this.httpClient.get<Question[]>(this._baseUrl)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
          return throwError(() => error);
        })
      );
  }

  // [GET] /api/questions/:id
  getQuestion(questionId: number): Observable<Question> {
    return this.httpClient.get<Question>(this._baseUrl + '/' + questionId)
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, 'Impossible de trouver cette question !', 5000))));
  }

  // [POST] /api/questions
  saveQuestion(question: QuestionForm): Observable<Question> {
    return this.httpClient.post<Question>(this._baseUrl, question)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  // [POST] /api/questions/duplicate/:id {questionForm w/ "question" only}
  duplicateQuestion(questionId: number, params: QuestionForm): Observable<OperationResponseModel> {
    return this.httpClient.post<OperationResponseModel>(this._baseUrl + '/duplicate/' + questionId, params)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }
  // [PUT] /api/questions/:id {questionForm}
  updateQuestion(questionId: number, question: QuestionForm): Observable<Question> {
    return this.httpClient.put<Question>(this._baseUrl + '/' + questionId, question)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  // =====================================================================================
  //                                    QUESTION REPORT
  // =====================================================================================
  saveQuestionReport(questionReportForm: QuestionReportForm): Observable<QuestionReport[]> {
    return this.httpClient.post<QuestionReport[]>(this._baseUrl + '/reports/', questionReportForm)
          .pipe(first(),
            catchError(error => throwError(() =>
              this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  updateQuestionReport(questionId: number, questionReportForm: QuestionReportForm): Observable<QuestionReport[]> {
    return this.httpClient.put<QuestionReport[]>(this._baseUrl + '/reports/' + questionId, questionReportForm)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  getAllQuestionReportForQuestion(questionId: number): Observable<QuestionReport[]> {
    return this.httpClient.get<QuestionReport[]>(this._baseUrl + '/reports/question/' + questionId)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  // =====================================================================================
  //                                       CATEGORIES
  // =====================================================================================

  getAllCategories(): Observable<QuestionCategory[]> {
    return this.httpClient.get<QuestionCategory[]>(
      this._baseUrl +  '/category/all'
    ).pipe(first(), catchError(error => throwError(() => new Error('Something went wrong: ' + error.message))));
  }
}
