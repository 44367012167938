import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {DatePipe, NgForOf, NgIf, NgStyle, TitleCasePipe} from "@angular/common";
import {Form, FormCategoryEnum, FormParams, FormStatus} from "../../models/form.model";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorIntl, MatPaginatorModule, PageEvent} from "@angular/material/paginator";
import {FormCardComponent} from "../shared/form-card/form-card.component";
import {RouterLink} from "@angular/router";
import {PATHS} from "../../app.routes";
import {FormService} from "../../services/back/form.service";
import {forkJoin, Subscription} from "rxjs";
import {LoaderComponent} from "../shared/loader/loader.component";
import {CustomPaginatorIntl} from "../shared/custom-paginator-intl";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatOption, MatSelect, MatSelectTrigger} from "@angular/material/select";
import {TagService} from "../../services/back/tag.service";
import {Tag} from "../../models/tag.model";
import {MatButton} from "@angular/material/button";
import {MatChipListbox, MatChipRow, MatChipsModule} from "@angular/material/chips";
import {ButtonComponent} from "../shared/button/button.component";
import {NotificationService} from "../../services/front/notifications.service";
import {NotificationsStatus} from "../../enums/notifications-status";
import {ConfirmDialogComponent, ConfirmDialogData} from "../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormStatusAction} from "../../enums/form.enum";
import {DuplicateDialogComponent} from "../shared/duplicate-dialog/duplicate-dialog.component";

@Component({
  selector: 'app-forms-dashboard',
  standalone: true,
  imports: [
    MatIcon,
    NgForOf,
    NgStyle,
    DatePipe,
    NgIf,
    MatFormField,
    MatInput,
    MatLabel,
    MatTableModule,
    MatPaginatorModule,
    FormCardComponent,
    RouterLink,
    LoaderComponent,
    MatSelect,
    MatSelectTrigger,
    ReactiveFormsModule,
    MatOption,
    MatButton,
    MatChipsModule,
    MatChipListbox,
    MatChipRow,
    ButtonComponent,
    TitleCasePipe
  ],
  providers : [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  templateUrl: './forms-dashboard.component.html',
  styleUrl: './forms-dashboard.component.scss'
})
export class FormsDashboardComponent implements OnInit, OnDestroy{

  protected readonly PATHS = PATHS;
  displayedForms: Form[] = [];
  pageSize: number = 5;
  formList: Form[] = [];
  subscription!: Subscription;
  filterForm: FormGroup | undefined;
  categoryKeys = Object.keys(FormCategoryEnum);
  // TODO : To be modified depending on user's role
  defaultStatus = Object.values(FormStatus);
  chipsList : { key: string, value: string }[] = [];
  tagsList: Tag[] = [];
  totalNumberOfItem : number = 0;
  previousPageActive: boolean = false;
  nextPageActive: boolean = true;



  constructor(private formService: FormService,
              private tagService: TagService,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private dialog: MatDialog,
              ) {
  }

  ngOnInit() {
    this.createForm();
    this.initData();
  }

  createForm() {
    this.filterForm = this.fb.group({
      title: new FormControl<string>(''),
      category: new FormControl<string>(''),
      tags: new FormControl<string[]>([]),
      status: new FormControl<string[]>(this.defaultStatus),
      itemNumberPerPage : new FormControl<number>(this.pageSize),
      pageNumber: new FormControl<number>(0),
    });
  }

  initData() {
    const initialFormParams = new FormParams('', '', this.defaultStatus, [], 0, this.pageSize);
    this.subscription = (
      forkJoin({
        forms: this.formService.getAllFormWithParams(initialFormParams),
        tags: this.tagService.getTagsList()
      }).subscribe({
        next: ({ forms, tags }) => {
          this.formList = forms.data;
          this.displayedForms = this.formList;
          this.totalNumberOfItem = forms.totalNumbers;
          this.tagsList = tags;
        }
      })
    );
  }

  setFormList() {
    const formValues = this.filterForm!.value;
    const title = formValues.title as string;
    const category = formValues.category as string;
    const tags = formValues.tags as string[];
    const status = formValues.status as string[];
    const size = formValues.itemNumberPerPage as number;
    const page = formValues.pageNumber as number;

    const filters = new FormParams(title, category, status, tags, page, size);
    this.subscription = this.formService.getAllFormWithParams(filters).subscribe(response => {
      this.formList = response.data;
      this.displayedForms = response.data;
      this.totalNumberOfItem = response.totalNumbers;
      this.updatePaginatorState(page, response.totalPages);
      this.updateChipsListForFilters();
    })
  }

  applyFilter() {
    this.setFormList();
  }

  onPageChange($event: PageEvent) {
    this.filterForm!.get('itemNumberPerPage')!.setValue($event.pageSize);
    this.filterForm!.get('pageNumber')!.setValue($event.pageIndex);
    this.applyFilter()
  }

  updatePaginatorState(pageIndex: number, totalPages: number) {
    this.previousPageActive = pageIndex > 0;
    this.nextPageActive = pageIndex + 1 < totalPages;
  }

  removeFilterFromChipList(filter: { key: string, value: string }) {
    if (filter.key === 'Category') {
      this.filterForm!.get('category')!.setValue('');
    } else if (filter.key === 'Tag') {
      const tags = this.filterForm!.get('tags')!.value.filter((tag: string) => tag !== filter.value);
      this.filterForm!.get('tags')!.setValue(tags);
    } else if (filter.key === 'État') {
      this.filterForm!.get('status')!.setValue(this.defaultStatus);
    }
    this.updateChipsListForFilters();
    this.applyFilter();
  }

  updateChipsListForFilters() {
    const formValues = this.filterForm!.value;
    this.chipsList = [];

    if (formValues.category && formValues.category !== '') {
      this.chipsList.push({ key: 'Category', value: formValues.category });
    }
    if (formValues.tags && formValues.tags.length) {
      formValues.tags.forEach((tag: string) => {
        this.chipsList.push({ key: 'Tag', value: tag });
      });
    }
    if (formValues.status && formValues.status !== '' && formValues.status !== this.defaultStatus) {
      this.chipsList.push({ key: 'État', value: formValues.status });
    }

  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  archiveOrDeleteFormEvent($event: { formId: string; type: FormStatusAction }, form : Form) {
    if ($event.type === FormStatusAction.ARCHIVE) {
     this.subscription = this.formService.updateFormStatusToArchived($event.formId).subscribe(() => {
        this.notificationService.showNotification(NotificationsStatus.SUCCESSFUL_UPDATED_FORM);
        this.applyFilter()
      })
    }

    if ($event.type === FormStatusAction.DUPLICATE) {
      const dialogRef:MatDialogRef<DuplicateDialogComponent> = this.dialog.open(DuplicateDialogComponent, {
        width: '400px',
        height: '300px',
        data: {templateId: $event.formId, originalTitle: form.title, objectType: 'form'}
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'refresh') {
          this.initData();
        }
      });
    }

    if ($event.type === FormStatusAction.DELETE) {
      const data: ConfirmDialogData = {
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce formulaire ?'
      };
      this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        height: '250px',
        data
      }).afterClosed().subscribe((itShouldBeDeleted) => {
        if(itShouldBeDeleted) {
          this.subscription = this.formService.deleteForm($event.formId).subscribe(() => {
            this.notificationService.showNotification(NotificationsStatus.SUCCESSFUL_DELETED_FORM);
            this.applyFilter();
          })
        }
      })
    }
  }
}
