import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious} from "@angular/material/stepper";
import {
  QuestionSettingsStepComponent
} from "../../library/create-question/question-settings-step/question-settings-step.component";
import {PATHS} from "../../../app.routes";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {finalize, mergeMap, Observable, of, Subject, switchMap, takeUntil, tap} from "rxjs";
import {Form, FormCategoryModel, FormStatus} from "../../../models/form.model";
import {FormInitializationComponent} from "./form-initialization/form-initialization.component";
import {MatDivider} from "@angular/material/divider";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {NgClass, NgForOf, NgIf, NgStyle, TitleCasePipe} from "@angular/common";
import {FormService} from "../../../services/back/form.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationService} from "../../../services/front/notifications.service";
import {NotificationsStatus} from "../../../enums/notifications-status";
import {CoverPageComponent} from "./form-coverpage/cover-page/cover-page.component";
import {FormQuestionsListEditorComponent} from "./form-questions-list-editor/form-questions-list-editor.component";
import {FormSummaryComponent} from "./form-summary/form-summary.component";
import {QuestionThreadModel} from "../../../models/question-thread.model";
import {LoaderComponent} from "../../shared/loader/loader.component";

@Component({
  selector: 'app-create-form',
  standalone: true,
  imports: [
    MatIcon,
    MatStep,
    MatStepLabel,
    MatStepper,
    MatStepperPrevious,
    QuestionSettingsStepComponent,
    MatStepperNext,
    RouterLink,
    FormInitializationComponent,
    MatDivider,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    NgForOf,
    TitleCasePipe,
    NgStyle,
    NgClass,
    CoverPageComponent,
    FormQuestionsListEditorComponent,
    FormSummaryComponent,
    LoaderComponent,
  ],
  templateUrl: './create-form.component.html',
  styleUrl: './create-form.component.scss'
})
export class CreateFormComponent implements OnInit, OnDestroy{

  @ViewChild('stepper') stepper! : MatStepper;

  protected readonly PATHS = PATHS;
  _loading: boolean = false;

  firstStepForm: FormGroup = new FormGroup({});
  secondStepForm: FormGroup = new FormGroup({});
  thirdStepForm: FormGroup = new FormGroup([]);

  errorMessage: string = '';
  tagsListLimit= 5;
  _unsubscribe = new Subject<void>();
  protected readonly FormStatus = FormStatus;
  showSummary: boolean = false;
  _editMode: boolean = false;
  formId: string | undefined;
  visualisationModeForSummary: boolean = false;

  constructor(private _formBuilder: FormBuilder,
              public formService: FormService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params['uuid'] !== undefined) {
        this.formId = params['uuid'];
        this._editMode = !this._editMode;
      }
    });

    this._switchLoading().pipe(
      mergeMap(() => this.createFormStep()),
      tap(() => this.resetErrorMessage()),
      tap(() => this.checkIfTagsListIsUnderMax()),
      finalize(() => this._switchLoading())
    ).subscribe();
  }

  getTemplateForm(): Observable<Form> {
    let form: Form = this.initializeEmptyForm();

    if(this.formId) {
      return this.formService.getForm(this.formId);
    } else {
      return of(form)
    }

  }

  initializeEmptyForm(): Form {
    return {
      title: '',
      category: {} as FormCategoryModel,
      tagsList: [],
      status: FormStatus.DRAFT,
      coverPage: '',
      creator: 'DevTeam', //TODO add the potiential ID of user
      withReport: true,
      formsComponentsList: [],
      questionThreadList: []
    };
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  checkIfTagsListIsUnderMax(){

    this.firstStepForm.controls['tagsList'].valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((tags) => {
      if (tags.length > this.tagsListLimit) {
        tags.pop();
        this.firstStepForm.controls['tagsList'].setValue(tags, { emitEvent: false });
        this.errorMessage = `Il y a une limite de ${this.tagsListLimit} par formulaire`;
      }
    });

  }

  resetErrorMessage() {
    this.firstStepForm.controls['name'].valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe( {
      next: () => {
        if (this.firstStepForm.controls['name'].dirty) {
          this.errorMessage = '';
        }
      }
    });
  }

  createFormStep(): Observable<Form> {

    return this.getTemplateForm().pipe(
      switchMap((form: Form)=>{
        this.firstStepForm = this._formBuilder.group({
          name: new FormControl(form.title, Validators.required),
          category: new FormControl(form.category, Validators.required),
          tagsList: new FormControl(form.tagsList, Validators.maxLength(this.tagsListLimit))
        });

        this.secondStepForm = this._formBuilder.group({
          coverPage: new FormControl(form.coverPage, Validators.required)
        });

        this.thirdStepForm = this._formBuilder.group({
          withReport: new FormControl(form.withReport),
          formsComponentsList: new FormControl(form.formsComponentsList),
          questionThreadList: new FormControl(form.questionThreadList)
        });

         return of(form)
      })
    )
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  saveForm(status: FormStatus): void {
    const form: Form = this.buildForm(status);
    const isFormValid: boolean = this.firstStepForm.valid
      && this.secondStepForm.valid
      && this.thirdStepForm.valid;

    if (this._editMode && this.formId && isFormValid) {
      this.updateForm(status, form);
    } else if ((status === FormStatus.DRAFT || status === FormStatus.INACTIVE) && this.firstStepForm.valid) {
      this.createForm(status, form);
    }
  }

  updateForm(status: FormStatus, form: Form): void {
    if(this.formId) {
      this.formService.editForm(this.formId, form)
        .subscribe({
          next: () => this.handleSuccess(status),
          error: error => this.handleError(error)
        });
    }
  }

  createForm(status: FormStatus, form: Form): void {
    this.formService.createForm(form)
      .subscribe({
        next: (formCreated: Form) => {
          if(this.formId === undefined && !this._editMode) {
            this.formId = formCreated.uuid;
            this._editMode = true;
            this.stepper.next();
          } else {
            this.handleSuccess(status);
          }
        },
        error: error => this.handleError(error)
      });
  }

  returnToLibraryRefreshed() {
    this.router.navigate(['/private/', PATHS.FORMS.BASE], {queryParams: {refresh: 'refresh'}});
  }

  handleSuccess(status: FormStatus): void {
    const notificationForCreation = status === FormStatus.DRAFT
      ? NotificationsStatus.SUCCESSFUL_CREATED_DRAFT
      : NotificationsStatus.SUCCESSFUL_CREATED_FORM;
    const notificationMessage = this._editMode
      ? NotificationsStatus.SUCCESSFUL_UPDATED_FORM
      : notificationForCreation;

    this.notificationService.showNotification(notificationMessage);
    this.returnToLibraryRefreshed();
  }

  handleError(error : HttpErrorResponse) {
    this.firstStepForm.controls['name'].markAsPristine();
    if (error.error.includes('Violation of UNIQUE KEY constraint')) {
      this.errorMessage = 'Le titre doit être unique';
    }
  }

  setDefaultNextQuestionId(list : QuestionThreadModel[]): QuestionThreadModel[] {
    if (list.length) {
      let lastIndex = list.length - 1;
      list.forEach((thread: QuestionThreadModel, index: number) => {
        const isLastIndex = index === lastIndex;
        const isFirstIndex = index === 0;

        thread.defaultNextQuestionId = isLastIndex
          ? null
          : list[index + 1].question.id;
        thread.previousQuestionId = isFirstIndex
          ? null
          : list[index - 1].question.id;

      });
    }
    return list;
  }

  buildForm(status: FormStatus): Form {
    return {
      uuid: this.formId? this.formId : undefined,
      creator: "Baptiste Martin", //todo add the potential id of the user or his fullname
      title: this.firstStepForm.get('name')?.value,
      category: this.firstStepForm.get('category')?.value,
      tagsList: this.firstStepForm.get('tagsList')?.value,
      coverPage: this.secondStepForm.get('coverPage')?.value,
      withReport: this.thirdStepForm.get('withReport')?.value,
      formsComponentsList: this.thirdStepForm.get('formsComponentsList')?.value,
      questionThreadList : this.setDefaultNextQuestionId(this.thirdStepForm.get('questionThreadList')?.value),
      status: status,
    }
  }

  onStepChange(event: any) {
    this.showSummary = event.selectedIndex === 2;
  }

  blockSummary($event: boolean) {
    this.visualisationModeForSummary = $event;
  }
}
