<form [formGroup]="thirdStepFormGroup" class=" h-full flex flex-col">
  <div class="flex items-center justify-between">

    <div>
      <mat-slide-toggle
        color="#2f4ec2"
        labelPosition="before"
        formControlName="withReport"> Restitution synthèse</mat-slide-toggle>
    </div>

    <div>
      <mat-button-toggle-group appearance="legacy" [value]="formDisplayMode" (change)="changeDisplayMode($event)">
      <mat-button-toggle [value]="FormDisplayMode.CONCEPTION">Conception</mat-button-toggle>
      <mat-button-toggle [value]="FormDisplayMode.VISUALISATION">Visualisation</mat-button-toggle>
      <mat-button-toggle [value]="FormDisplayMode.SYNTHESE">Synthèse</mat-button-toggle>
    </mat-button-toggle-group>
    </div>
  </div>

  @if (formDisplayMode === FormDisplayMode.CONCEPTION) {
    <div class="h-full">
      <app-form-question-display-conception [thirdStepFormGroup]="thirdStepFormGroup" ></app-form-question-display-conception>
    </div>
  } @else if (formDisplayMode === FormDisplayMode.VISUALISATION) {
  <div class="h-full">
    <app-form-visualisation [thirdStepFormGroup]="thirdStepFormGroup" (questionAnswered)="addAnswers($event)"></app-form-visualisation>
  </div>
  } @else if (formDisplayMode === FormDisplayMode.SYNTHESE) {
    <div class="h-full">
      <app-synthesis [thirdStepFormGroup]="thirdStepFormGroup" [responses]="responses"></app-synthesis>
    </div>
  }
</form>
