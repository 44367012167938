import {Component, Input} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomPaginatorIntl} from '../shared/custom-paginator-intl';
import {RouterLink} from '@angular/router';
import {PATHS} from '../../app.routes';
import {MatIcon} from '@angular/material/icon';
import {QuestionsListComponent} from './questions-list/questions-list.component';


@Component({
  selector: 'app-question-library',
  standalone: true,
  imports: [
    RouterLink,
    MatIcon,
    QuestionsListComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
  ],
  templateUrl: './library.component.html',
  styleUrl: './library.component.scss'
})
export class LibraryComponent {

  @Input() mode?: string;

  constructor(
  ) {}

  protected readonly PATHS = PATHS;
}


