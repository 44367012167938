import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Level, NotificationService} from "../front/notifications.service";
import {Client} from "../../models/inexref.model";


const API_URL = environment.apiURL;

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private _baseUrl: string = API_URL + '/campaign';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) { }

  sendCampaign(campaignForm: CampaignForm): Observable<string> {
    return this.httpClient.post<string>(this._baseUrl, campaignForm).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'L\'envoie du formulaire impossible', 5000))));
  }

}

export interface CampaignForm {
  name: string;
  subject: string;
  clients: Client[];
  formUUID: string;
  mailContent: string;
}
