@if (routerLink) {
  <a [routerLink]="routerLink" [ngClass]="classes" [ngStyle]="styles">
    @if (icon) {
      <mat-icon class="text-[24px]">{{ icon }}</mat-icon>
    }
    @if (!isIconOnly) {
      <div class="h-[24px]">{{ label }}</div>
    }
  </a>
} @else {
  <button
    [ngClass]="classes"
    [ngStyle]="styles"
    (click)="handleClick()"
  >
    @if (icon) {
      <mat-icon class="text-[24px]">{{ icon }}</mat-icon>
    }
    @if (!isIconOnly) {
      <div class="h-[24px]">{{ label }}</div>
    }
  </button>
}
