<div class="flex flex-col h-full justify-around gap-15 px-4 py-2">
  <div class="flex justify-between items-center gap-20 ">
    <div class="font-bold" >{{data.title}}</div>
    <button mat-icon-button color="primary"
            (click)="dialogRef.close(undefined)">
      <mat-icon>close</mat-icon></button>
  </div>
  <div class="h-2/4 p-5" >
    <span class="mt-10">{{data.text}}</span>
    @if (data.text2) {
      <span>{{data.text2}}</span>
    }
  </div>
  <div class="flex justify-end gap-5">
    <button mat-button
            (click)="dialogRef.close(false)">{{(data.no || label.NO)|uppercase}}</button>
    <button mat-flat-button color="primary"
            (click)="dialogRef.close(true)">{{(data.yes || label.YES)|uppercase}}</button>
  </div>
</div>
