export enum NotificationsStatus {
  SUCCESSFUL_DUPLICATION= 'SUCCESSFUL_DUPLICATION',
  PARTIAL_DUPLICATION= 'PARTIAL_DUPLICATION',
  SERVER_ERROR= 'server error',
  SUCCESSFUL_CREATED_DRAFT= 'successful created draft',
  SUCCESSFUL_CREATED_FORM= 'successful created form',
  SUCCESSFUL_UPDATED_FORM= 'successful updated form',
  SUCCESSFUL_DELETED_FORM= 'successful deleted form',
  ERROR_DELETING_SECTION= 'error deleting section',
  ERROR_SECTION_NOT_FOUND = 'error founding section',
  ERROR_SECTION_NAME_LENGTH = 'error section name length',
  ERROR_SECTION_ALREADY_EXIST = 'error section already exist',
  ERROR_QUESTION_ALREADY_EXISTS = 'error question already exists',
}
