<section class="h-full relative">
  <h1 class="text-center bg-black text-white py-2 text-lg fixed left-0 right-0 z-50">Création d'une question</h1>
  <h1 class="text-center bg-black text-white py-2 text-lg fixed left-0 right-0 z-50">{{_editMode ? 'Édition d\'une question' : 'Création d\'une question'}}</h1>

  <mat-stepper [linear]="true" [labelPosition]="'bottom'" #stepper class="scroll-auto pt-12">

    <mat-step [stepControl]="firstStepForm">
      <ng-template matStepLabel>Paramètres</ng-template>

      @if (!_loading) {
        <app-question-settings-step [firstStepFormGroup]="firstStepForm" [_editMode]="_editMode"></app-question-settings-step>
      }

      <section class="flex justify-between left-2 right-2 mt-5">
        <button class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500" (click)="goBackToLibrary()">
          <mat-icon class="text-[24px]">arrow_back_ios</mat-icon>
          <span class="h-[24px]">Retourner à la bibliothèque</span>
        </button>

        <div class="flex gap-5">
          <button class="flex border-2 px-4 py-2 rounded gap-1"
                  [ngClass]="firstStepForm.dirty ? 'border-blue-500 text-blue-500' : 'border-gray-500 text-gray-500'"
                  [disabled]="!firstStepForm.dirty"
                  (click)="saveAndQuit(true)">
            <span class="h-[24px]" >{{(question?.status == null || question?.status == QuestionStatus.DRAFT) ? "Sauvegarder le Brouillon & Quitter" : "Sauvegarder & Quitter"}}</span>
            <mat-icon class="text-[24px]">save</mat-icon>
          </button>

          <!-- une fois le texte rapport fait il faudra remettre: matStepperNext -->
          @if (firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES ||firstStepForm.get('type')?.value === QuestionTypeEnum.EVAL) {
            <button matStepperNext class="flex border-2 px-4 py-2 rounded"
                    [ngClass]="firstStepForm.valid ? 'bg-blue-500 border-blue-500 text-white' : 'bg-gray-500 border-gray-500 text-white'"
                    [disabled]="!firstStepForm.valid"
                    (click)="saveAndQuit()">
              <span class="h-[24px]">Suivant</span>
              <mat-icon class="text-[24px]">arrow_forward_ios</mat-icon>
            </button>
          } @else if (firstStepForm.get('type')?.value !== null) {
            <button matStepperNext class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white"
                    *ngIf="question?.status == null || question?.status == QuestionStatus.DRAFT"
                    [ngClass]="firstStepForm.valid? 'border-blue-500 bg-blue-500' : 'border-gray-500 bg-gray-500'"
                    [disabled]="!firstStepForm.valid"
                    (click)="publish()"
                   >
              <span class="h-[24px]">Publier la question</span>
              <mat-icon class="text-[24px]">published_with_changes</mat-icon>
            </button>
          }
        </div>
      </section>
    </mat-step>

    @if (firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES ||firstStepForm.get('type')?.value === QuestionTypeEnum.EVAL) {
      <mat-step>
        <ng-template matStepLabel>Texte rapport</ng-template>

        @if (!_loading) {
          <app-text-report-step [firstStepForm]="firstStepForm" [secondStepForm]="secondStepForm" [_editMode]="_editMode"></app-text-report-step>
        }

        <section class="flex justify-between left-2 right-2 mt-5">
          <button matStepperPrevious class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500">
            <mat-icon class="text-[24px]">arrow_back_ios</mat-icon>
            <span class="h-[24px]">Précédent</span>
          </button>

          <div class="flex gap-5">
            <button class="flex border-blue-500 border-2 px-4 py-2 rounded text-blue-500 gap-1"
                    [ngClass]="secondStepForm.valid? 'border-blue-500 text-blue-500' : 'border-gray-500 text-gray-500'"
                    [disabled]="!secondStepForm.valid"
                    (click)="saveAndQuitReport()">
              <span class="h-[24px]" >{{(question?.status == null || question?.status == QuestionStatus.DRAFT) ? "Sauvegarder le Brouillon & Quitter" : "Sauvegarder & Quitter"}}</span>
              <mat-icon class="text-[24px]">save</mat-icon>
            </button>

            <!-- une fois le texte rapport fait il faudra remettre: matStepperNext -->
            <button matStepperNext class="flex border-blue-500 bg-blue-500 border-2 px-4 py-2 rounded text-white gap-1"
                    *ngIf="question?.status == null || question?.status == QuestionStatus.DRAFT"
                    [ngClass]="secondStepForm.valid? 'border-blue-500 bg-blue-500' : 'border-gray-500 bg-gray-500'"
                    [disabled]="!secondStepForm.valid"
                    (click)="publishReport()">
              <span class="h-[24px]">Publier la question</span>
              <mat-icon class="text-[24px]">published_with_changes</mat-icon>
            </button>
          </div>
        </section>
      </mat-step>
    }

  </mat-stepper>
</section>


