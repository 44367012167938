import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Tag} from "../../models/tag.model";
import {environment} from "../../../environments/environment";

const API_URL = environment.apiURL;

@Injectable()
export class TagService {
  private _baseUrl: string = API_URL + '/tags';

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getTagsList(): Observable<Tag[]> {
    return this.httpClient.get<Tag[]>(this._baseUrl)
  }

  createTag(param: {title: string}) {
    return this.httpClient.post<Tag>(this._baseUrl, param)
  }

  deleteTag(id: number) {
    return this.httpClient.delete<Tag>(this._baseUrl + `/${id}`)
  }

}
