/* src/app/components/shared/notifications/notifications.component.scss */
.black-bg {
  position: relative;
  background-image:
    linear-gradient(
      125deg,
      #333037 0%,
      #19171b 20%,
      #19171b 80%,
      #333037 80%,
      #19171b 100%);
}
.text-arial {
  font-size: 1.2rem;
  font-weight: 900;
  color: var(--violine);
  font-family: Arial, sans-serif;
}
.alert {
  position: fixed;
  top: 7rem;
  right: -100%;
  transition: right 0.5s ease-in-out;
}
.alert.show {
  right: 0;
}
/*# sourceMappingURL=notifications.component.css.map */
