import {Component, Input, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {QuestionReport, QuestionSectionSynthesis} from "../../../../../models/questions.model";
import {NgForOf} from "@angular/common";
import {QuestionReportFlag} from "../../../../../enums/questions.enum";

@Component({
  selector: 'app-conditional-synthesis',
  standalone: true,
  imports: [
    MatIcon,
    NgForOf
  ],
  templateUrl: './conditional-synthesis.component.html',
  styleUrl: './conditional-synthesis.component.scss'
})
export class ConditionalSynthesisComponent implements OnInit {

  @Input() questionSectionSynthesis!: QuestionSectionSynthesis;

  supportPoints: QuestionReport[] = [];
  warningPoints: QuestionReport[] = [];
  criticalPoints: QuestionReport[] = [];

  ngOnInit(): void {
    this.questionSectionSynthesis.questionReportSynthesis.map(question => {
      question.questionReports.map(questionReport => {
        this.addQuestionReportToGoodCategory(questionReport);
      })
    })
  }

  // J'ai choisi de ne pas utiliser de filter sur la map pour éviter un triple parcours
  addQuestionReportToGoodCategory(questionReport: QuestionReport) {
    if (questionReport.flag === QuestionReportFlag.GREEN)
      this.supportPoints.push(questionReport);
    else if (questionReport.flag === QuestionReportFlag.ORANGE)
      this.warningPoints.push(questionReport);
    else if (questionReport.flag === QuestionReportFlag.RED)
      this.criticalPoints.push(questionReport);
  }
}
