import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {Level, NotificationService} from "../front/notifications.service";
import {environment} from "../../../environments/environment";
import {DashboardStatisticsModel, UserFormModel} from "../../models/questionnaireModel";
import {catchError, Observable, of, throwError} from "rxjs";
import {FormDetailModel} from "../../models/form.model";
import {NotificationsStatus} from "../../enums/notifications-status";
import {FormAnswerModel} from "../../models/formAnswer.model";

const API_URL = environment.apiURL;
@Injectable({
  providedIn: 'root'
})
export class UserFormService {
  private _baseUrl: string = API_URL + '/user-forms';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  // [GET] /api/user-forms
  getUserForms(collabRegionId : string): Observable<UserFormModel[]> {
    return this.httpClient.get<UserFormModel[]>(this._baseUrl, {
      params: new HttpParams()
      .set("collabRegionId", collabRegionId)
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les formulaires', 5000))));
  }

  // [GET] /api/user-forms/statistic
  getDashboardStatistic(collabRegionId : string, collabMatricule : string): Observable<DashboardStatisticsModel> {
    return this.httpClient.get<DashboardStatisticsModel>(this._baseUrl + '/statistic', {
      params: new HttpParams()
      .set("collabRegionId", collabRegionId)
      .set("collabMatricule", collabMatricule)
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les statistiques', 5000))));
  }

  // [GET] /api/user-forms/details
  getFormDetails(regionId : string, formUuid : string, formSendDate: string): Observable<FormDetailModel[]> {
    return this.httpClient.get<FormDetailModel[]>(this._baseUrl + '/details', {
      params: new HttpParams()
      .set("regionId", regionId)
      .set("formUuid", formUuid)
      .set("formSendDate", formSendDate)
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les detailles', 5000))));
  }


  getQuestionThreadId(formUuid: string, questionId: string): Observable<number> {
    return this.httpClient.get<number>(this._baseUrl + '/question-thread/' + formUuid + '/' + questionId).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of();
      })
    )
  }

  setFormAnswer(formAnswer: FormAnswerModel): Observable<FormAnswerModel> {
    return this.httpClient.post<FormAnswerModel>(this._baseUrl+'/form-answer', formAnswer).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of();
      })
    );
  }

}
