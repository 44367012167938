<div [formGroup]="firstStepFormGroup" *ngIf="!_loading">
  <div [formArrayName]="'questionAnswers'">
    @for (answer of answerArray.controls; track $index) {
      <section [formGroupName]="$index">
        <section class="flex justify-between w-full items-center my-5">
          <h4>Type de saisie</h4>
          <div>
            <mat-button-toggle-group
              [formControlName]="'formatType'"
              class="!shadow-md !rounded-3xl"
              [value]="AnswerFormatTypeEnum.TEXT"
            >
              <mat-button-toggle [value]="AnswerFormatTypeEnum.TEXT">Libre</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.DATE">Date</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.EMAIL">E-mail</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.NUMBER">Numérique</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </section>

        @if (answer.get('formatType')?.value !== AnswerFormatTypeEnum.DATE) {
          <mat-divider></mat-divider>
          <section class="my-5">
            <h4>Nombre de caractères</h4>
            <section class="flex justify-between gap-5 w-full">
              <mat-form-field class="w-1/2">
                <mat-label>Min (Par défaut: 0)</mat-label>
                <input matInput type="number" [formControlName]="'min'" placeholder="Min (Par défaut: 0)">
              </mat-form-field>
              <mat-form-field class="w-1/2">
                <mat-label>Max (Par défaut: 255)</mat-label>
                <input matInput type="number" [formControlName]="'max'" placeholder="Max (Par défaut: 255)">
              </mat-form-field>
            </section>
          </section>
        }
      </section>
    }
  </div>
</div>



