<div class="h-1/3 flex mb-2">
  <div class="grow px-8 py-3 w-11/12">
    <div class="font-bold flex gap-2 items-center w-full">
      @if (showIcon) {
        <mat-icon class="opacity-60 min-w-6" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(question.type)">{{QuestionTypeUtil.getQuestionTypeIcon(question.type)}}</mat-icon>
      }
      <div>{{ question.question }}</div>
    </div>
    <div class="text-sm italic truncate">{{ this.extractTextFromHtml(question.description) }}</div>
  </div>
  @if (!visualisationMode) {
    <div class="cursor-pointer" (click)="deleteSelectedQuestion(question.id)">
      <mat-icon class="m-3">delete</mat-icon>
    </div>
  } @else {
    <mat-checkbox [checked]="isNotApplicable" (click)="setResponseNonApplicable()">
      Non Applicable
    </mat-checkbox>
  }
</div>
<div class="px-8 h-2/3 flex-col italic text-gray-200" [formGroup]="questionForm">
  <div class="flex-col gap-4" cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="questionAnswers">
    @for (control of questionAnswersFunction.controls; track control; let i = $index){
      <div [formGroupName]="i"  class="flex items-center gap-5 mb-4" cdkDrag>
        <div class="flex items-center gap-3">
          <button cdkDragHandle class="flex">
            <mat-icon>drag_indicator</mat-icon>
          </button>
          <h5>{{ i + 1 }}</h5>
        </div>
        <div class="w-full">
          <mat-form-field class="w-full -mb-5">
            <mat-label>Libellé</mat-label>
            <input matInput type="text" [placeholder]="'Libellé'" formControlName="answer">
          </mat-form-field>
        </div>
      </div>
    }
  </div>

</div>
