import {Component, Input, OnInit} from '@angular/core';
import {
  Question,
  QuestionAnswer,
  QuestionGraphicalSynthesis,
  QuestionReport,
  QuestionReportSynthesis,
  QuestionRestitutionSynthesis,
  QuestionSectionOrganized,
  QuestionSectionSynthesis
} from "../../../../models/questions.model";
import {SynthesisType} from "../../../../models/form.model";
import {ConditionalSynthesisComponent} from "./conditional-synthesis/conditional-synthesis.component";
import {GraphicalSynthesisComponent} from "./graphical-synthesis/graphical-synthesis.component";
import {RestitutionSynthesisComponent} from "./restitution-synthesis/restitution-synthesis.component";
import {QuestionService} from "../../../../services/back/question.service";
import {map} from "rxjs";
import {LoaderComponent} from "../../loader/loader.component";

@Component({
  selector: 'app-synthesis-card',
  standalone: true,
  imports: [
    ConditionalSynthesisComponent,
    GraphicalSynthesisComponent,
    RestitutionSynthesisComponent,
    LoaderComponent,
    RestitutionSynthesisComponent
  ],
  templateUrl: './synthesis-card.component.html',
  styleUrl: './synthesis-card.component.scss'
})
export class SynthesisCardComponent implements OnInit {

  @Input() questionSection!: QuestionSectionOrganized;
  @Input() questionAnswers?: QuestionAnswer[];

  protected readonly SynthesisType = SynthesisType;

  questionSectionSynthesis!: QuestionSectionSynthesis;

  isReady: boolean = false;

  constructor(private questionReportService: QuestionService) {
  }

  ngOnInit(): void {
    this.buildQuestionSectionSynthesis()
  }

  private buildQuestionSectionSynthesis(): void {
    const questionReportSynthesisArray: QuestionReportSynthesis[] = [];
    this.isReady = false;

    this.questionSection.questions.map(question => {
      this.questionReportService.getAllQuestionReportForQuestion(question.id)
        .pipe(map(res => {

          const questionReportSynthesis = this.toQuestionReportSynthesis(question, res);

          questionReportSynthesisArray.push(questionReportSynthesis);

          // Vérifie si toutes les questions ont été traitées
          if (questionReportSynthesisArray.length === this.questionSection.questions.length) {
            this.questionSectionSynthesis = {
              section: this.questionSection.section,
              questionReportSynthesis: questionReportSynthesisArray,
            };
          }

        }))
        .subscribe(() => {
          this.isReady = true;
        });
    });
  }

  toQuestionReportSynthesis(question: Question, questionReports: QuestionReport[]): QuestionReportSynthesis {
    return {
      question: question,
      questionReports: questionReports
    } as QuestionReportSynthesis;
  }

  toQuestionGraphicalSynthesis(questionAnswer: QuestionAnswer) {
    return {
      question: this.findQuestion(questionAnswer),
      questionAnswer: questionAnswer
    } as QuestionGraphicalSynthesis
  }

  toQuestionRestitutionSynthesis(questionAnswers: QuestionAnswer[]) {
    const organizedResponses = new Map<Question, QuestionAnswer[]>();

    questionAnswers.forEach(questionAnswer => {
      const question = this.findQuestion(questionAnswer);

      if (organizedResponses.has(question)) {
        // Si la question est déjà dans la map, ajoute les nouvelles réponses
        organizedResponses.get(question)?.push(questionAnswer);
      } else {
        // Sinon, crée une nouvelle entrée avec cette question
        organizedResponses.set(question, [questionAnswer]);
      }
    });

    return {
      organizedResponses: organizedResponses
    } as QuestionRestitutionSynthesis;
  }

  findQuestion(questionAnswerForm: QuestionAnswer): Question {
    const question = this.questionSection.questions.find(q => q.id === questionAnswerForm.questionId);

    return question !== undefined ? question : {} as Question;
  }
}

