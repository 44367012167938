@charset "UTF-8";

/* src/app/components/shared/synthesis/synthesis.component.scss */
:host {
  display: block;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
/*# sourceMappingURL=synthesis.component.css.map */
