import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatDivider} from "@angular/material/divider";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect, MatSelectTrigger} from "@angular/material/select";
import {AsyncPipe, NgForOf, NgIf, TitleCasePipe} from "@angular/common";
import {FormCategoryEnum} from "../../../../models/form.model";
import {MatInput} from "@angular/material/input";
import {Tag} from "../../../../models/tag.model";
import {Subject, takeUntil} from "rxjs";
import {TagService} from "../../../../services/back/tag.service";

@Component({
  selector: 'app-form-initialization',
  standalone: true,
  imports: [
    MatDivider,
    MatFormField,
    MatIcon,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    NgIf,
    ReactiveFormsModule,
    TitleCasePipe,
    NgForOf,
    MatInput,
    AsyncPipe
  ],
  templateUrl: './form-initialization.component.html',
  styleUrl: './form-initialization.component.scss'
})
export class FormInitializationComponent implements OnInit, OnDestroy{

  @Input() firstStepFormGroup!: FormGroup

  formCategoryEnum = FormCategoryEnum;
  selectedCategory: FormCategoryEnum | undefined;
  _unsubscribe = new Subject<void>();
  tagsList: Tag[] | undefined;

  constructor(private tagService: TagService) {}

  ngOnInit() {
    this.init();
  }

  get tagsListFormGroup() {
    return this.firstStepFormGroup.get('tagsList') as FormControl<Tag[]>;
  }

  compareTags(tag1: any, tag2: any): boolean {
    return tag1 && tag2 ? tag1.id === tag2.id : tag1 === tag2;
  }

  init() {
    this.tagService.getTagsList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
      (rsp: Tag[]) => {
        this.tagsList = rsp;
      });
  }


  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  getEnumKeys(): Array<string> {
    return Object.keys(this.formCategoryEnum);
  }
}
