<section class="flex justify-between items-center shadow-md pr-8 bg-black">
  <div class="flex items-center">
    <div class="mr-5">
      <img class="w-48" src="https://www.inextenso.fr/wp-content/themes/inextenso/images/logo-inextenso.svg" alt="new logo In Extenso !"/>
    </div>

    @if (!_isLoading) {
      <ul class="flex items-center gap-5">
        @if(userRoles.includes(UserRoleEnum.ANALYSTE) || userRoles.includes(UserRoleEnum.CONTRIBUTEUR)) {
          <li class="cursor-pointer">
            <a [routerLink]="PATHS.DASHBOARDS.BASE" [routerLinkActive]="'text-[var(--violine)]'" class="flex flex-col items-center text-white hover:text-[var(--violine)] hover:transition hover:duration-200 hover:ease-in-out ease-in-out duration-200">
              <div *ngIf="isIconVisible">
                <mat-icon class="text-xl">dashboard</mat-icon>
              </div>
              <span class="text-sm">Dashboard</span>
            </a>
          </li>
        }
        @if(userRoles.includes(UserRoleEnum.CONCEPTEUR)) {
          <li class="cursor-pointer">
            <a [routerLink]="PATHS.FORMS.BASE" [routerLinkActive]="'text-[var(--violine)]'" class="flex flex-col items-center text-white hover:text-[var(--violine)] hover:transition hover:duration-200 hover:ease-in-out ease-in-out duration-200">
              <div *ngIf="isIconVisible">
                <mat-icon class="text-xl">description</mat-icon>
              </div>
              <span class="text-sm">Formulaires</span>
            </a>
          </li>
        }

        @if(userRoles.includes(UserRoleEnum.CONCEPTEUR)) {
          <li class="cursor-pointer">
            <a [routerLink]="PATHS.LIBRARY.BASE" [routerLinkActive]="'text-[var(--violine)]'" class="flex flex-col items-center text-white hover:text-[var(--violine)] hover:transition hover:duration-200 hover:ease-in-out ease-in-out duration-200">
              <div *ngIf="isIconVisible">
                <mat-icon class="text-xl">list</mat-icon>
              </div>
              <span class="text-sm">Bibliothèque</span>
            </a>
          </li>
        }

        @if(userRoles.includes(UserRoleEnum.CONCEPTEUR)) {
          <li class="cursor-pointer">
            <a [routerLink]="PATHS.TAGS.BASE" [routerLinkActive]="'text-[var(--violine)]'" class="flex flex-col items-center text-white hover:text-[var(--violine)] hover:transition hover:duration-200 hover:ease-in-out ease-in-out duration-200">
              <div *ngIf="isIconVisible">
                <mat-icon class="text-xl">local_offer</mat-icon>
              </div>
              <span class="text-sm">Tags</span>
            </a>
          </li>
        }

        @if(!userRoles.includes(UserRoleEnum.CONTRIBUTEUR)) {
          <li class="cursor-pointer">
            <a [routerLink]="PATHS.CLIENTS.BASE" [routerLinkActive]="'text-[var(--violine)]'" class="flex flex-col items-center text-white hover:text-[var(--violine)] hover:transition hover:duration-200 hover:ease-in-out ease-in-out duration-200">
              <div *ngIf="isIconVisible">
                <mat-icon class="text-xl">group</mat-icon>
              </div>
              <span class="text-sm">Clients</span>
            </a>
          </li>
        }

      </ul>
    }
  </div>

  <!-- Temp -->
  <div>
    <button class="flex items-center text-white bg-red-500 px-4 rounded-2xl">ENV: {{env}}</button>
  </div>

  <div class="ml-8">
    <button (click)="logout()" class="flex items-center bg-black border-2 gap-2 border-white text-white pt-2 pb-1 px-4">
      <span><mat-icon>power_settings_new</mat-icon></span>
    </button>

  </div>
</section>
