import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Question, QuestionAnswer, QuestionAnswerForm, QuestionSectionOrganized} from "../../../models/questions.model";
import {Form} from "../../../models/form.model";
import {SectionOrganizerService} from "../../../services/front/section-organizer.service";
import {QuestionTypeEnum} from "../../../enums/questions.enum";
import {
  EvaluationQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/evaluation-question-display/evaluation-question-display.component";
import {
  FreeTextQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/free-text-question-display/free-text-question-display.component";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {
  MultiQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/multi-question-display/multi-question-display.component";
import {
  RankQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/rank-question-display/rank-question-display.component";
import {
  UniqueMultipleQuestionDisplayComponent
} from "../../forms-dashboard/create-form/form-questions-list-editor/unique-multiple-question-display/unique-multiple-question-display.component";
import {MatExpansionModule,} from "@angular/material/expansion";
import {UserFormService} from "../../../services/back/user-form.service";
import {FormAnswerModel} from "../../../models/formAnswer.model";

@Component({
  selector: 'app-form-visualisation',
  standalone: true,
  imports: [
    EvaluationQuestionDisplayComponent,
    FormsModule,
    FreeTextQuestionDisplayComponent,
    MatButton,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MultiQuestionDisplayComponent,
    RankQuestionDisplayComponent,
    ReactiveFormsModule,
    UniqueMultipleQuestionDisplayComponent,
    MatExpansionModule,
  ],
  templateUrl: './form-visualisation.component.html',
  styleUrl: './form-visualisation.component.scss'
})
export class FormVisualisationComponent implements OnInit {

  @Input() thirdStepFormGroup: FormGroup | null = null;
  @Input() form : Form | null = null;
  @Output() questionAnswered : EventEmitter<QuestionAnswerForm> = new EventEmitter<QuestionAnswerForm>();
  _userUuid: string = "48D328D2-64CF-44EA-8A5B-8A6CAE531BB9"; // TODO @BMA Change when we finish reaching this page

  protected readonly QuestionTypeEnum = QuestionTypeEnum;

  questionAnswerForm: QuestionAnswerForm = {formId: '', questionAnswers: []};

  constructor(private sectionOrganizerService : SectionOrganizerService, private userFormService : UserFormService) {}

  ngOnInit() {
    this.questionAnswerForm = {
      formId: this.form?.uuid || '',
      questionAnswers: []
    };
  }

  getQuestionSectionOrganizedList() : QuestionSectionOrganized[] {
    if(this.thirdStepFormGroup) {
      return this.sectionOrganizerService.organizeSectionsAndQuestions(this.thirdStepFormGroup?.get('formsComponentsList')?.value);
    }
    return this.sectionOrganizerService.organizeSectionsAndQuestions(this.form?.formsComponentsList || []);
  }

  setQuestionAnswer($event: QuestionAnswer, question: Question) {
    const isAlreadyAnswered = this.questionAnswerForm.questionAnswers.findIndex(qa => qa.questionId === $event.questionId);


    this.userFormService.getQuestionThreadId(this.form?.uuid as string, $event.questionId.toString()).subscribe(questionThreadId => {
      const formAnswer : FormAnswerModel = {
        userUuid : this._userUuid,
        formUuid: this.form?.uuid as string,
        questionThreadId,
        answer: this.getQuestionAnswerInStringType($event, question),
        applicable: $event.response[0].displayOrder !== -2
      }
      this.userFormService.setFormAnswer(formAnswer).subscribe()
    })

    if (isAlreadyAnswered !== -1) {
      this.questionAnswerForm.questionAnswers[isAlreadyAnswered] = $event;
    } else {
      this.questionAnswerForm.questionAnswers.push($event);
    }

    this.questionAnswered.emit(this.questionAnswerForm);
  }


  getQuestionAnswerInStringType(questionAnswer : QuestionAnswer, question : Question) {
    if(question.type === QuestionTypeEnum.RANK || question.type === QuestionTypeEnum.MULTI) {
      return questionAnswer.response.sort((a, b) => a.displayOrder - b.displayOrder)
      .map(r => r.value).join('>>>');
    } else {
      return questionAnswer.response
      .sort((a, b) => b.displayOrder - a.displayOrder)
      .map(r => {
        if (r.displayOrder === -1) {
          return `[${r.value}]`;
        }
        return r.value;
      })
      .join('>>>');
    }
  }





}
