@if (isLoading) {
  <app-loader></app-loader>
} @else {
  <div class="flex flex-col items-center w-full p-10">
  <div class="flex w-full items-start">
    <mat-icon>arrow_back</mat-icon>
    <p class="underline cursor-pointer" (click)="goToDashboard()">Retour au dashboard</p>
  </div>
  <div class="w-3/4 mt-10 flex justify-between">
    <form class="w-full flex gap-5 items-baseline" [formGroup]="filterForm" >

      <mat-form-field appearance="outline">
        <mat-label>Recherche</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput formControlName="multiple">
      </mat-form-field>

      <mat-form-field appearance="outline" class="">
        <mat-label>Statut</mat-label>
        <mat-select formControlName="status">
          <mat-option [value]="''">Tous</mat-option>
          @for (status of statusKeys; track status) {
            <mat-option [value]="status">{{status}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <app-button
        label="Filtrer"
        color="sky-blue"
        (buttonClick)="applyFilter()"
      ></app-button>
    </form>
  </div>
  <div class="border border-gray-200 w-3/4">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="clientCode">
        <th mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header>Code client</th>
        <td mat-cell class="category-column" *matCellDef="let element">
          {{element.clientCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom du distinataire</th>
        <td mat-cell class="category-column" *matCellDef="let element">{{element.clientName}}</td>
      </ng-container>

      <ng-container matColumnDef="raisonSoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Raison sociale</th>
        <td mat-cell class="category-column" *matCellDef="let element">{{element.raisonSoc}}</td>
      </ng-container>


      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
        <td mat-cell *matCellDef="let element" class="status-column">
          <div class="flex justify-between">
            <div [ngClass]="{'text-[var(--violine)]' : isInProgress(element.status)}">{{displayStatus(element.status)}}</div>
            <div class="flex gap-5">
              <mat-icon class="cursor-not-allowed">edit</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>
}

