<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="flex flex-col h-full justify-around gap-15 px-4 py-2">

    <div class="flex justify-between items-center gap-20">
      <div class="font-bold">Duplication</div>
      <button mat-icon-button mat-dialog-close color="primary">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="h-2/4 flex flex-col justify-between">
      <span class="">Merci de renseigner un nouveau titre ! </span>

      <div class="flex justify-center items-end my-3">
        <mat-icon class="text-red-500 mr-1">@if (this.errorMessage) {
          error
        }</mat-icon>
        <div class="text-red-500 text-sm truncate">{{ this.errorMessage }}</div>
      </div>

      <mat-form-field appearance="outline">
        <input matInput type="text" [formControlName]="'editorContent'">
      </mat-form-field>
    </div>

    <div class="flex justify-end gap-5 my-2">
      <button mat-flat-button color="primary"
              [ngClass]="form.valid ? 'cartoon-button' : 'disable-cartoon-button'"
              type="submit"
              [disabled]="!form.valid">
        <span>Créer</span>
      </button>
    </div>

  </div>
</form>
