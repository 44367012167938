import {Component, ComponentRef, Input, OnInit, signal, Type, ViewChild, WritableSignal} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {MatFormField, MatOption, MatSelect, MatSelectTrigger} from '@angular/material/select';
import {MatIcon} from '@angular/material/icon';
import {MatInput, MatLabel} from '@angular/material/input';
import {NgIf} from '@angular/common';
import {QuillEditorComponent, QuillViewComponent} from 'ngx-quill';
import {FormArray, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {DynamicComponentLoadingDirective} from '../../../../directives/dynamic-component-loading.directive';
import {QuestionCategory, QuestionType, questionTypeList} from '../../../../models/questions.model';
import {quillModuleToolbar} from '../../../../utils/wysiwyg.utils';
import {QuestionService} from '../../../../services/back/question.service';
import {QuestionTypeEnum} from '../../../../enums/questions.enum';
import {FreeTextAnswerComponent} from './free-text-answer/free-text-answer.component';
import {
  UniqueMultipleChoiceAnswerComponent
} from './unique-multiple-choice-answer/unique-multiple-choice-answer.component';
import {EvaluationAnswerComponent} from './evaluation-answer/evaluation-answer.component';
import {RankAnswerComponent} from './rank-answer/rank-answer.component';
import {MultiFieldAnswerComponent} from './multi-field-answer/multi-field-answer.component';

@Component({
  selector: 'app-question-settings-step',
  standalone: true,
  imports: [
    MatButton,
    MatDivider,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgIf,
    QuillEditorComponent,
    ReactiveFormsModule,
    MatSelectTrigger,
    QuillViewComponent,
    DynamicComponentLoadingDirective
  ],
  templateUrl: './question-settings-step.component.html',
  styleUrl: './question-settings-step.component.scss'
})
export class QuestionSettingsStepComponent implements OnInit {

  @ViewChild(DynamicComponentLoadingDirective, { static: true })
  dynamicContainer!: DynamicComponentLoadingDirective;

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  displayUrl: WritableSignal<boolean> = signal(false);

  selectedOption: QuestionType | undefined;
  selectedCategory: QuestionCategory | undefined;

  categoriesList: QuestionCategory[] = [];

  componentRegistry: { [key: string]: Type<AnswerComponents>; } = {};
  currentComponentRef: ComponentRef<AnswerComponents> | null = null;

  editorConfig = {
    modules: {
      toolbar: quillModuleToolbar
    },
    placeholder: 'Écrivez votre question...'
  };

  questionTypeList: QuestionType[][] = questionTypeList;

  constructor(
    private questionService: QuestionService
  ) {
    this.componentRegistry[QuestionTypeEnum.FREE_TEXT] = FreeTextAnswerComponent;
    this.componentRegistry[QuestionTypeEnum.CHOICES] = UniqueMultipleChoiceAnswerComponent;
    this.componentRegistry[QuestionTypeEnum.EVAL] = EvaluationAnswerComponent;
    this.componentRegistry[QuestionTypeEnum.RANK] = RankAnswerComponent;
    this.componentRegistry[QuestionTypeEnum.MULTI] = MultiFieldAnswerComponent;
  }

  ngOnInit() {
    this.questionService.getAllCategories().subscribe((values: QuestionCategory[]) => {
      this.categoriesList = values;

      if (this._editMode) this.initEditionForm();
      else this.initCreationForm();
    });

  }

  initCreationForm() {
    this.firstStepFormGroup.get('type')?.valueChanges.subscribe((val: QuestionTypeEnum) => {
      if (val !== undefined) this.foundQuestionType(val);
    });
    this.firstStepFormGroup.get('category')?.valueChanges.subscribe(
      (val: QuestionCategory) => this.selectedCategory = val);
  }

  initEditionForm() {
    this.firstStepFormGroup.get('type')?.disable();
    this.foundQuestionType(this.firstStepFormGroup.get('type')?.value);
    this.selectedCategory = this.categoriesList.find((v: QuestionCategory): boolean => v.id === this.firstStepFormGroup.get('category')?.value.id);
    this.firstStepFormGroup.get('category')?.valueChanges.subscribe(
      (val: QuestionCategory) => this.selectedCategory = val);
  }

  private foundQuestionType(val: string) {
    this.questionTypeList.find(subArray => {
      const founded = subArray.find(v => v.type === val);
      if (founded) {
        this.selectedOption = founded;
        this.loadComponent(this.selectedOption.type)
      }
      return !!founded;
    });
  }

  private loadComponent(selectedOption: QuestionTypeEnum) {
    if (this.currentComponentRef) {
      this.currentComponentRef.destroy();
    }

    if (!this._editMode) {
      const answers: FormArray = this.firstStepFormGroup.get('questionAnswers') as FormArray;
      answers.clear();
    }

    this.dynamicContainer.viewContainerRef.clear();
    const componentRef: ComponentRef<AnswerComponents>  = this.getComponent(selectedOption);
    this.currentComponentRef = componentRef;
    this.assignFormToComponent(componentRef);
  }

  private getComponent(selectedOption: QuestionTypeEnum) {
    return this.dynamicContainer.viewContainerRef.createComponent(
      this.componentRegistry[selectedOption]
    );
  }

  private assignFormToComponent(componentRef: ComponentRef<any>) {
    componentRef.instance.firstStepFormGroup = this.firstStepFormGroup;
    componentRef.instance._editMode = this._editMode;
  }
}

type AnswerComponents =
  FreeTextAnswerComponent
  | UniqueMultipleChoiceAnswerComponent
  | EvaluationAnswerComponent
  | RankAnswerComponent
  | MultiFieldAnswerComponent;
