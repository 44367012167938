import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {TopNavigationComponent} from './components/shared/top-navigation/top-navigation.component';
import {NotificationsComponent} from "./components/shared/notifications/notifications.component";
import {HttpClientModule} from "@angular/common/http";
import {SharedService} from "./services/front/shared.service";
import {FormService} from "./services/back/form.service";
import {TagService} from "./services/back/tag.service";
import {QuestionsStatusTrPipe} from "./utils/questions-status-tr.pipe";
import {QuestionService} from './services/back/question.service';
import {SectionService} from "./services/back/section.service";
import {ClientService} from "./services/back/client.service";
import {InexrefService} from "./services/back/inexref.service";
import {UserFormService} from "./services/back/user-form.service";
import {UserService} from "./services/front/user.service";
import {CampaignService} from "./services/back/campaign.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TopNavigationComponent,
    NotificationsComponent,
    HttpClientModule,
    QuestionsStatusTrPipe
  ],
  providers: [
    QuestionService,
    SharedService,
    FormService,
    TagService,
    SectionService,
    ClientService,
    InexrefService,
    UserFormService,
    UserService,
    CampaignService
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor() {
  }
}
