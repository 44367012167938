import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {QuillEditorComponent} from "ngx-quill";
import {MatIcon} from "@angular/material/icon";
import {QuestionsListComponent} from "../../../library/questions-list/questions-list.component";
import {MatTooltip} from "@angular/material/tooltip";
import {NotificationService} from "../../../../services/front/notifications.service";
import {FormCardComponent} from "../../../shared/form-card/form-card.component";
import {LowerCasePipe, NgClass, NgStyle} from "@angular/common";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {FormDisplayMode} from "../../../../enums/form.enum";
import {
  FormQuestionDisplayConceptionComponent
} from "./form-question-display-conception/form-question-display-conception.component";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatButton, MatFabButton} from "@angular/material/button";
import {FormVisualisationComponent} from "../../../shared/form-visualisation/form-visualisation.component";
import {SynthesisComponent} from "../../../shared/synthesis/synthesis.component";
import {QuestionAnswerForm} from "../../../../models/questions.model";

@Component({
  selector: 'app-form-questions-list-editor',
  standalone: true,
  imports: [
    FormsModule,
    QuillEditorComponent,
    ReactiveFormsModule,
    MatIcon,
    QuestionsListComponent,
    MatTooltip,
    FormCardComponent,
    NgClass,
    NgStyle,
    MatSlideToggle,
    MatLabel,
    MatFormField,
    MatInputModule,
    LowerCasePipe,
    MatSelect,
    MatOption,
    MatButton,
    MatFabButton,
    MatButtonToggleGroup,
    MatButtonToggle,
    FormQuestionDisplayConceptionComponent,
    FormVisualisationComponent,
    SynthesisComponent
  ],
  templateUrl: './form-questions-list-editor.component.html',
  styleUrl: './form-questions-list-editor.component.scss'
})
export class FormQuestionsListEditorComponent implements OnInit {

  @Input() thirdStepFormGroup!: FormGroup;

  @Output() blockSummary: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLibraryDisplayed: boolean = false;
  formDisplayMode: FormDisplayMode = FormDisplayMode.CONCEPTION;

  responses!: QuestionAnswerForm;

  protected readonly FormDisplayMode = FormDisplayMode;

  constructor(public notificationService: NotificationService) {
  }

  ngOnInit() {
    this.notificationService._isLibraryDisplayed.subscribe(value => {
      this.isLibraryDisplayed = value;
    });
  }

  changeDisplayMode(event: MatButtonToggleChange) {
    this.formDisplayMode = event.value;
    this.formDisplayMode === FormDisplayMode.VISUALISATION ? this.blockSummary.emit(true) : this.blockSummary.emit(false);
  }

  addAnswers(event: any) {
    this.responses = event;
  }
}
