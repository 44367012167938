import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {Form, FormStatus} from "../../../models/form.model";
import {DatePipe, NgStyle, TitleCasePipe} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {Router} from "@angular/router";
import {PATHS} from "../../../app.routes";
import {FormIconComponent} from "../form-icon/form-icon/form-icon.component";
import {FormStatusAction} from "../../../enums/form.enum";

@Component({
  selector: 'app-form-card',
  standalone: true,
  imports: [
    MatIcon,
    NgStyle,
    DatePipe,
    TitleCasePipe,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    FormIconComponent
  ],
  templateUrl: './form-card.component.html',
  styleUrl: './form-card.component.scss'
})
export class FormCardComponent {
  @Input() form!: Form;
  @Output() archiveOrDeleteFormEvent = new EventEmitter<{ formId : string, type : FormStatusAction}>();

  defaultColor: string = "#454545"
  protected readonly FormStatus = FormStatus;

  styleMap: StyleMap[] = [
    {category: 'DIAGNOSTIC', primaryColor : '#450992', secondaryColor : '#2196F3', icon: 'troubleshoot'},
    {category: 'PROFILAGE', primaryColor : '#295ff5', secondaryColor : '#FB8C00', icon: 'badge'},
    {category: 'CONSEIL', primaryColor : '#f5c244', secondaryColor : '#FF6347', icon: 'chat'},
    {category: 'MARKETING', primaryColor : '#3a7f4f', secondaryColor : '#50C878', icon: 'account_balance'},
];

  constructor(private route: Router) {
  }

  getSecondaryColorMap(cat: string) :string {
    const style: StyleMap | undefined = this.styleMap.find((style: StyleMap) => style.category === cat)
    return style ? style.secondaryColor : this.defaultColor;
  }

  getStatusColor(status: string): string {
    const mapStatusColor = {
      [FormStatus.ARCHIVE.toString()]: '#F5C6C6',
      [FormStatus.ACTIVE.toString()]: '#C6F5C6',
      [FormStatus.INACTIVE.toString()]: '#F5D6A6',
      [FormStatus.DRAFT.toString()]: 'lightgray'
  }
  return mapStatusColor[status] ? mapStatusColor[status] : 'black'
  }

  duplicateForm(uuid: string | undefined) {
    this.archiveOrDeleteFormEvent.emit({formId: uuid as string, type: FormStatusAction.DUPLICATE});
  }

  archiveForm(uuid: string | undefined) {
    this.archiveOrDeleteFormEvent.emit({formId: uuid as string, type: FormStatusAction.ARCHIVE});
  }

  deleteForm(uuid: string | undefined) {
    this.archiveOrDeleteFormEvent.emit({formId: uuid as string, type: FormStatusAction.DELETE});
  }

  updateForm(formId: string | undefined) {
    if(formId) {
      this.route.navigate(["/private/", PATHS.FORMS.BASE, PATHS.FORMS.EDIT, formId]);
    }
  }
}

export interface StyleMap {
  category: string;
  primaryColor: string;
  secondaryColor: string;
  icon: string;
}
