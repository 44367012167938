<div class="border black-bg px-4 py-5 rounded-lg w-1/3 alert flex justify-between"
  [ngClass]="{'show': this.notificationService.notificationSignal()}">
  <div>
    @if (this.notificationService.status === NotificationsStatus.SUCCESSFUL_DUPLICATION) {
      <p class="text-white text-arial">Succès !</p>
      <p class="text-white text-arial">Duplication réussie !</p>
    } @else if (this.notificationService.status === NotificationsStatus.PARTIAL_DUPLICATION) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Certains champs n'ont pas été copiés</p>
    } @else if (this.notificationService.status === NotificationsStatus.SERVER_ERROR) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Une erreur est survenue. Merci de réessayer plus tard</p>
    } @else if (this.notificationService.status === NotificationsStatus.SUCCESSFUL_CREATED_DRAFT) {
      <p class="text-white text-arial">Succès !</p>
      <p class="text-sm text-white">Le brouillon a été sauvegardé.</p>
    } @else if (this.notificationService.status === NotificationsStatus.SUCCESSFUL_CREATED_FORM) {
      <p class="text-white text-arial">Succès !</p>
      <p class="text-sm text-white">Le fomulaire a été publié.</p>
    } @else if (this.notificationService.status === NotificationsStatus.SUCCESSFUL_UPDATED_FORM) {
      <p class="text-white text-arial">Succès !</p>
      <p class="text-sm text-white">Le fomulaire a été mis à jour !</p>
    } @else if (this.notificationService.status === NotificationsStatus.SUCCESSFUL_DELETED_FORM) {
      <p class="text-white text-arial">Succès !</p>
      <p class="text-sm text-white">Le fomulaire a été supprimé !</p>
    } @else if (this.notificationService.status === NotificationsStatus.ERROR_DELETING_SECTION) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Veuillez d'abord supprimer les questions de la thématique.</p>
    } @else if (this.notificationService.status === NotificationsStatus.ERROR_SECTION_NOT_FOUND) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Vous devez ajouter une thématique avant de pouvoir ajouter des questions.</p>
    } @else if (this.notificationService.status === NotificationsStatus.ERROR_SECTION_NAME_LENGTH) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Le nom de la thématique doit contenir au moins 1 caractère.</p>
    } @else if (this.notificationService.status === NotificationsStatus.ERROR_SECTION_ALREADY_EXIST) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Une thématique avec ce nom existe déjà.</p>
    } @else if (this.notificationService.status === NotificationsStatus.ERROR_QUESTION_ALREADY_EXISTS) {
      <p class="text-white text-arial">Attention !</p>
      <p class="italic text-sm text-white">Une question avec ce libellé existe déjà.</p>
    } @else {
      <p class="text-white text-arial">{{ notificationService.level }}</p>
      <p class="italic text-sm text-white">{{ notificationService.message }}</p>
    }
  </div>
  <div class="cursor-pointer"
       (click)="this.closeNotification()">
    <mat-icon class="text-white">close</mat-icon>
  </div>
</div>
