import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDrag, CdkDragHandle, CdkDropList} from "@angular/cdk/drag-drop";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {Question, QuestionAnswer, QuestionResponseModel} from "../../../../../models/questions.model";
import {MatTooltip} from "@angular/material/tooltip";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {evaluationSavedAnswerModel} from "../../../../../models/form.model";
import {QuestionTypeUtil} from "../../../../../utils/questions-type-utils";
import {MatCheckbox} from "@angular/material/checkbox";


@Component({
  selector: 'app-evaluation-question-display',
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    FormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatTooltip,
    MatRadioButton,
    MatCheckbox,
    MatRadioGroup
  ],
  templateUrl: './evaluation-question-display.component.html',
  styleUrl: './evaluation-question-display.component.scss'
})

export class EvaluationQuestionDisplayComponent implements OnInit {
  @Input() question!: Question;
  @Input() showIcon: boolean = true;
  @Input() visualisationMode: boolean = false;
  @Input() savedValue: number | null = null
  @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionAnswer: EventEmitter<QuestionAnswer> = new EventEmitter<QuestionAnswer>();
  questionAnswersForEvaluationType: evaluationSavedAnswerModel[] = [];
  isNotApplicable: boolean = false;
  previousValue: number | null = null;
  questionForm!: FormGroup;
  protected readonly QuestionTypeUtil = QuestionTypeUtil;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.createAnswerList();
    this.createForm();
  }

  createAnswerList() {
    for(let i = this.question.questionPossibleAnswers[0].min ; i <= this.question.questionPossibleAnswers[0].max; i++) {
      if(i == this.question.questionPossibleAnswers[0].min){
        this.questionAnswersForEvaluationType.push({
          value: i,
          label: this.splitString(this.question.questionPossibleAnswers[0].value, 1)
        })
      } else if(i == this.question.questionPossibleAnswers[0].max){
        this.questionAnswersForEvaluationType.push({
          value: i,
          label: this.splitString(this.question.questionPossibleAnswers[0].value, 2)
        })
      } else {
        this.questionAnswersForEvaluationType.push({
          value: i,
          label: ""
        })
      }
    }
  }

  createForm() {
    this.questionForm = this.fb.group({
      answer: new FormControl({ value: this.savedValue || null, disabled: this.isNotApplicable || !this.visualisationMode })
    });

    if (this.savedValue !== null) {
      this.questionForm.get('answer')?.setValue(this.savedValue);
    }
  }


  splitString(input : string, part: number) {
    const parts = input.split(';');

    if (part === 1) {
      return parts[0];
    } else if (part === 2) {
      return parts[1];
    } else {
      return '';
    }
  }

  extractTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  deleteSelectedQuestion(id: number) {
    this.deleteQuestion.emit(id);
  }

  onFormChanges() {
    let responses : QuestionResponseModel[] = [];
    this.savedValue = this.questionForm.get('answer')?.value;
    if(this.isNotApplicable) {
      responses.push({displayOrder: -2, value: this.questionForm.get('answer')?.value as string});
    } else {
     responses.push({displayOrder: 0, value: this.questionForm.get('answer')?.value as string});
    }
    const questionAnswer : QuestionAnswer = {
      questionId: this.question.id,
      response: responses
    }
    this.questionAnswer.emit(questionAnswer);
  }


  setResponseNonApplicable() {
    this.isNotApplicable = !this.isNotApplicable;
    if(this.isNotApplicable) {
      this.previousValue = this.savedValue;
      this.questionForm?.get('answer')?.disable();
      this.questionForm?.get('answer')?.setValue('N/A');
      this.onFormChanges();
    } else {
      this.questionForm.get('answer')?.enable();
      this.questionForm.get('answer')?.setValue(this.previousValue);
      this.onFormChanges();
    }
  }
}
