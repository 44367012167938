@if (!_LOADING) {
  <section class="flex flex-col h-full w-screen">
    <section class="w-full fixed h-[90px] z-50 flex justify-end">
      <app-top-navigation class="flex-1"></app-top-navigation>
    </section>
    <section class="flex h-full w-full">

      <section class="h-full w-full block pt-[90px] relative">
        <router-outlet></router-outlet>
      </section>
    </section>
  </section>
}
