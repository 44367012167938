import {Component} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-public-layout',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss'
})
export class PublicLayoutComponent {

  constructor(private router: Router) {

    this.router.navigate(['private']);
  }
}
