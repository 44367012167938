import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {QuestionsListComponent} from "../../../../library/questions-list/questions-list.component";
import {
  UniqueMultipleQuestionDisplayComponent
} from "../unique-multiple-question-display/unique-multiple-question-display.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatRadioButton} from "@angular/material/radio";
import {Question, QuestionAnswer} from "../../../../../models/questions.model";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {QuestionTypeEnum} from "../../../../../enums/questions.enum";
import {CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {RankSavedAnswerModel} from "../../../../../models/form.model";
import {QuestionTypeUtil} from "../../../../../utils/questions-type-utils";

@Component({
  selector: 'app-rank-question-display',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    QuestionsListComponent,
    UniqueMultipleQuestionDisplayComponent,
    MatCheckbox,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatRadioButton,
    CdkDragHandle,
    FormsModule,
    CdkDropList,
    CdkDrag,
    ReactiveFormsModule
  ],
  templateUrl: './rank-question-display.component.html',
  styleUrl: './rank-question-display.component.scss'
})
export class RankQuestionDisplayComponent implements OnInit{

  @Input() question!: Question;
  @Input()  showIcon: boolean = true;
  @Input() visualisationMode: boolean = false;
  @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionAnswer: EventEmitter<QuestionAnswer> = new EventEmitter<QuestionAnswer>();
  questionForm!: FormGroup;
  isNotApplicable: boolean = false;
  questionAnswers: FormArray<any> = new FormArray<any>([]);
  protected readonly QuestionTypeEnum = QuestionTypeEnum;
  protected readonly QuestionTypeUtil = QuestionTypeUtil;
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.question.questionPossibleAnswers.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });

    this.questionForm = this.formBuilder.group({
      questionAnswers: this.formBuilder.array(this.question.questionPossibleAnswers.map(answer =>
        this.formBuilder.group({
          answer: [answer.value]
        })
      ))
    });

    this.questionForm.get('questionAnswers')?.disable();
  }

  get questionAnswersFunction(): FormArray {
    return this.questionForm.get('questionAnswers') as FormArray;
  }

  extractTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  deleteSelectedQuestion(id: number) {
    this.deleteQuestion.emit(id);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container && this.visualisationMode && !this.isNotApplicable) {
      moveItemInArray(this.questionAnswersFunction.controls, event.previousIndex, event.currentIndex);
      this.onFormChanges();
    }
  }

  getRankResponse() : RankSavedAnswerModel {
    let answers : RankSavedAnswerModel = {
      choices: [],
    }
    if(this.isNotApplicable) {
      answers.choices.push({
        value: 'N/A',
        displayOrder: -2
      });
      return answers
    }
    this.questionAnswersFunction.controls.map((control, index) => {
      answers.choices.push({
        value: control.get('answer')?.value,
        displayOrder: index
      });
    });
    return answers;
  }

  setResponseNonApplicable() {
    this.isNotApplicable = !this.isNotApplicable;
    this.onFormChanges();
  }

  onFormChanges() {
    let questionAnswer : QuestionAnswer = {
      questionId: this.question.id,
      response: this.getRankResponse().choices
    }
    this.questionAnswer.emit(questionAnswer);
  }
}
