<div class="relative border border-solid border-gray-200 w-72 min-h-[400px] mr-4 mt-4">

    <div class="border-solid border-b border-gray-200 w-full h-14 flex items-center justify-end p-3 text-gray-300">
      <div class="flex justify-between items-center gap-1">
        <span>{{form.status | titlecase}}</span>
        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon [ngStyle]="{'color': getSecondaryColorMap(form.category.toString())}">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="mat-menu-button"
                    [disabled]="form.status === FormStatus.DRAFT"
                    (click)="duplicateForm(form.uuid)">
              <mat-icon>content_copy</mat-icon>
              <span>Dupliquer</span>
            </button>

            @if (form.status != FormStatus.ARCHIVE) {
              <button mat-menu-item class="mat-menu-button" (click)="archiveForm(form.uuid)">
                <mat-icon>archive</mat-icon>
                <span>Archiver</span>
              </button>
            }
            <button mat-menu-item class="mat-menu-button" (click)="deleteForm(form.uuid)">
              <mat-icon>delete_sweep</mat-icon>
              <span>Supprimer</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <app-form-icon
      [formCategory]="form.category.toString()"
      [mode]="'formCard'">
    </app-form-icon>
    <div class="w-full mt-9 h-80 p-4 flex flex-col justify-between cursor-pointer" (click)="updateForm(form.uuid)">
      <div>
        <div class="text-gray-400 text-sm"> {{form.category.name}}</div>
        <h4 class="text-lg font-bold text-gray-800 mb-1 truncate-ellipsis">{{form.title}}</h4>
        <div class="flex gap-1 my-2 min-h-14 flex-wrap">
          @for (tag of form.tagsList; track $index) {
            <span class="bg-blue-100 text-blue-700 px-2 py-1 h-6 rounded-full text-xs">{{tag.title}}</span>
          }
        </div>
      </div>
      <div>
        <div class="flex flex-col items-end">
          <p class="text-xs text-gray-500 mb-1">Crée le <span class="text-gray-600">{{form.createdAt | date: 'dd/MM/yyyy'}}</span></p>
        </div>
      </div>
    </div>
  </div>

