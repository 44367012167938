import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatRadioButton} from "@angular/material/radio";
import {MatTooltip} from "@angular/material/tooltip";
import {
  Question,
  QuestionAnswer,
  QuestionPossibleAnswers,
  QuestionResponseModel
} from "../../../../../models/questions.model";
import {AnswerFormatTypeEnum} from "../../../../../enums/questions.enum";
import {MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";
import {QuestionTypeUtil} from "../../../../../utils/questions-type-utils";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-free-text-question-display',
  standalone: true,
  imports: [
    MatIcon,
    MatRadioButton,
    MatTooltip,
    MatHint,
    MatInput,
    MatLabel,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckbox,
    ReactiveFormsModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    provideNativeDateAdapter(),
  ],
  templateUrl: './free-text-question-display.component.html',
  styleUrl: './free-text-question-display.component.scss'
})
export class FreeTextQuestionDisplayComponent implements OnInit{

  @Input() question!: Question;
  @Input()  showIcon: boolean = true;
  @Input() visualisationMode: boolean = false;
  @Input()  savedValue: string | null = null;
  @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionAnswer: EventEmitter<QuestionAnswer> = new EventEmitter<QuestionAnswer>();
  isNotApplicable: boolean = false;
  questionAnswerForm!: FormGroup;
  questionPossibleAnswer : QuestionPossibleAnswers = {} as QuestionPossibleAnswers;
  protected readonly AnswerFormatTypeEnum = AnswerFormatTypeEnum;
  protected readonly QuestionTypeUtil = QuestionTypeUtil;

  constructor() {
  }

  ngOnInit(): void {
    this.questionPossibleAnswer = this.question.questionPossibleAnswers[0];
    this.createForm();
    this.visualisationMode ? this.questionAnswerForm.enable() : this.questionAnswerForm.disable();
  }

  extractTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  deleteSelectedQuestion(id: number) {
    this.deleteQuestion.emit(id);
  }

  setResponseNonApplicable() {
    this.isNotApplicable = !this.isNotApplicable;
    if(this.isNotApplicable) {
      this.questionAnswerForm.disable();
    } else {
      this.questionAnswerForm.reset();
      this.questionAnswerForm.enable();
    }
    this.onFormChanges();
  }

  createForm() {
    const controlsConfig: { [key: string]: FormControl } = {};

    if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
      const textControl = new FormControl('', [Validators.maxLength(256), Validators.minLength(1)]);
      controlsConfig['answerText'] = textControl;
      textControl.valueChanges.pipe(debounceTime(1000))
      .subscribe(value => {
        if (value !== undefined && value !== null) {
          this.onFormChanges();
        }
      });
    }
    if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
      controlsConfig['answerDate'] = new FormControl('', [Validators.required]);
    }
    if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
      controlsConfig['answerNumber'] = new FormControl('', [Validators.min(this.questionPossibleAnswer.min), Validators.max(this.questionPossibleAnswer.max)]);
    }
    if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
      const mailControl = new FormControl('', [Validators.email]);
      controlsConfig['answerMail'] = mailControl
      mailControl.valueChanges.pipe(debounceTime(1000))
      .subscribe(value => {
        if (value !== undefined && value !== null) {
          this.onFormChanges();
        }
      });
    }
    this.questionAnswerForm = new FormGroup(controlsConfig);
  }

  onFormChanges() {
    let responses : QuestionResponseModel[] = [];
    if(this.isNotApplicable) {
      this.setValueForNotApplicable();
      responses.push({displayOrder: -2, value: 'N/A'});
    } else {
      let value = '';
      if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
        value = (this.questionAnswerForm.get('answerDate')?.value as Date) ? (this.questionAnswerForm.get('answerDate')?.value as Date).toISOString() : '';
      }
      if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
        value = this.questionAnswerForm.get('answerText')?.value as string;
      }
      if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
        value = this.questionAnswerForm.get('answerNumber')?.value as string;
      }
      if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
        value = this.questionAnswerForm.get('answerMail')?.value as string;
      }
      if (value === '') return;
      responses.push({displayOrder: 0, value: value});
    }

    const questionAnswer : QuestionAnswer = {
      questionId: this.question.id,
      response: responses
    }

    this.questionAnswer.emit(questionAnswer);
  }

  setValueForNotApplicable() {
    if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
      this.questionAnswerForm.get('answerText')?.setValue('N/A');
    }
    if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
      this.questionAnswerForm.get('answerDate')?.setValue('N/A');
    }
    if(this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
      this.questionAnswerForm.get('answerNumber')?.setValue('N/A');
    }
    if (this.questionPossibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
      this.questionAnswerForm.get('answerMail')?.setValue('N/A');
    }
  }
}
