import {Routes} from '@angular/router';
import {DashboardHomePageComponent} from './components/dashboard-home-page/dashboard-home-page.component';
import {LibraryComponent} from './components/library/library.component';
import {FormsDashboardComponent} from './components/forms-dashboard/forms-dashboard.component';
import {TagsListComponent} from "./components/tags-list/tags-list.component";
import {CreateQuestionComponent} from './components/library/create-question/create-question.component';
import {CreateFormComponent} from "./components/forms-dashboard/create-form/create-form.component";
import {ViewFormComponent} from "./components/forms-dashboard/view-form/view-form.component";
import {PublicLayoutComponent} from "./layouts/public/public-layout.component";
import {PrivateLayoutComponent} from "./layouts/private/private-layout.component";
import {
  DashboardFormDetailComponent
} from "./components/dashboard-home-page/dashboard-form-detail/dashboard-form-detail.component";

export const PATHS = {
  DASHBOARDS: {
    BASE: "dashboards",
    DETAIL: "detail"
  },
  FORMS: {
    BASE: 'forms',
    CREATE: "create",
    EDIT: "edit",
    VIEW: "view"
  },
  LIBRARY: {
    BASE: "library",
    CREATE: "create",
    EDIT: "edit"
  },
  CLIENTS: {
    BASE: "clients",
    IMPORT: "import"
  },
  TAGS: {
    BASE: "tags"}
};


export const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      // { path: '', pathMatch: "full", redirectTo: 'login' },
      // { path: 'login', component: AuthComponent },
      // { path: 'forms/:forms_uuid/:client_uuid', component: ViewFormComponent },
      // { path: 'politique-confidentialite-donnees', component: PoliticDataComponent }
    ]
  },
  {
    path: 'private',
    component: PrivateLayoutComponent,
    // canActivate: [MsalGuard, AuthGuard],
    children: [
      { path: '', pathMatch: "full", redirectTo: PATHS.DASHBOARDS.BASE },
      { path: PATHS.DASHBOARDS.BASE, children: [
          {path: "", component: DashboardHomePageComponent },
          {path: PATHS.DASHBOARDS.DETAIL + "/:uuid/:formDate", component:DashboardFormDetailComponent }]
      },
      { path: PATHS.FORMS.BASE, children : [
          {path: "", component: FormsDashboardComponent },
          {path: PATHS.FORMS.CREATE, component: CreateFormComponent},
          {path: PATHS.FORMS.EDIT + "/:uuid", component: CreateFormComponent},
          {path: PATHS.FORMS.VIEW + "/:uuid", component: ViewFormComponent}]
      },
      { path: PATHS.LIBRARY.BASE, children : [
          { path: "", component: LibraryComponent },
          { path: PATHS.LIBRARY.CREATE, component: CreateQuestionComponent },
          { path: PATHS.LIBRARY.EDIT + "/:id", component: CreateQuestionComponent }]
      },
      // { path: PATHS.CLIENTS.BASE, component: ClientsListComponent},
      { path: PATHS.TAGS.BASE, children: [
        {path: '', component: TagsListComponent}]
      }
    ]
  }
]
