@if(this.subscriptionClosed && this.dataSource.data.length === 0) {
  <div style="color: var(--light-grey)"
       class="p-32 w-full flex justify-center text-center">
    Aucun résultat
  </div>
} @else if (!this.subscriptionClosed) {
  <app-loader></app-loader>
} @else {
  <!-- filters -->
    <!--    <div class="flex justify-center">-->
    <!--      <div class="md:my-6 rounded-md border border-gray-100 w-11/12 shadow-md">-->
    <!--        <div class="w-full h-[50px] md:h-[70px]">-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->

  <div class="hidden md:flex justify-center mt-10">
    <div class="border border-gray-200 w-11/12 pb-1">

      <div class="h-16 flex items-center font-black px-4 justify-between">
        <div>{{getTitle()}}</div>
        <div>
          <mat-paginator #paginator
                         class="custom-paginator"
                         [length]="dataSource.data.length"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[5, 10, 25, 50, 100]"
                         (page)="onPageEvent($event)">
            ></mat-paginator></div>
      </div>

      <table mat-table [dataSource]="pagedData">
        <ng-container matColumnDef="questionType">
          <th mat-header-cell *matHeaderCellDef class="w-10"></th>
          <td mat-cell *matCellDef="let element" class="w-10"><mat-icon class="opacity-60" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(element.type)">{{QuestionTypeUtil.getQuestionTypeIcon(element.type)}}</mat-icon></td>
        </ng-container>
        <ng-container matColumnDef="categoryName">
          <th mat-header-cell *matHeaderCellDef> Catégorie </th>
          <td mat-cell *matCellDef="let element" class="w-1/6"> {{element.category.name}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Titre </th>
          <td mat-cell *matCellDef="let element" class="w-4/6 truncate"> {{element.question}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status | qStatusTr | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-around">
              <mat-icon class="cursor-pointer" (click)="editQuestion(element.id)">edit</mat-icon>
              <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="true">
                  <mat-icon>visibility</mat-icon>Visualiser
                </button>
                <button mat-menu-item [disabled]="!isDuplicateAuthorized(element)" (click)="openDialog(element)">
                  <mat-icon>content_copy</mat-icon>Dupliquer
                </button>
                <button mat-menu-item [disabled]="true">
                  <mat-icon>archive</mat-icon>Archiver
                </button>
                <button mat-menu-item [disabled]="true">
                  <mat-icon>delete</mat-icon>Supprimer
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="mode === 'library'? 'cursor-default' : 'cursor-pointer'" (click)="handleQuestion(row)"></tr>
      </table>
    </div>
  </div>

  <div id="container-questions" class="flex flex-col grow justify-between">
    <div id="questions" class="flex flex-col grow items-center md:hidden h-[380px] overflow-scroll">
      @for (elt of dataSource.data; track $index) {
        <div class="my-1 rounded-md bg-[var(--light-green)] w-11/12 px-4 py-3 flex flex-col">
          <div class="flex justify-between items-center mb-1">
            <p class="text-[10px] text-gray-500">{{elt.createdAt | date: 'dd/mm/yyyy'}}</p>
            <span class="text-xs text-gray-500">{{elt.status}}</span>
          </div>
          <h3 class="font-bold text-xs md:font-semibold mb:text-mb mb-2 truncate">{{elt.question}}</h3>
          <p class="text-gray-700 text-xs truncate-ellipsis">{{elt.category.name}}</p>

        </div>
      }
    </div>

    <div id="footer" class="flex items-end justify-center mt-4">
      <div class="flex md:hidden w-full px-4">
        <div [routerLink]="" class="flex-1 mr-2">
          <button class="cursor-not-allowed w-full flex items-center justify-center text-white py-3 px-6 rounded bg-[var(--violine)]">
            <mat-icon>add</mat-icon>
            <span class="text-md h-5 sm:flex">Catégorie</span>
          </button>
        </div>
        <div [routerLink]="" class="flex-1 ml-2">
          <button class="cursor-not-allowed w-full flex items-center justify-center text-white py-3 px-6 rounded bg-[var(--menthol)]">
            <mat-icon>add</mat-icon>
            <span class="text-md h-5 sm:flex ml-2">Question</span>
          </button>
        </div>
      </div>
    </div>
  </div>
}
