@if (isLoading) {
  <app-loader></app-loader>
} @else {
  <div class="flex flex-col justify-between h-full p-8">
    <div>
      <div>
        <form class="flex justify-between items-baseline mt-8" [formGroup]="filterQuestionnaire" >
          <mat-form-field appearance="outline" class="w-full mr-5">
            <mat-label>Agence</mat-label>
            <mat-select formControlName="agency">
              <mat-option [value]="null"></mat-option>
              @for (agence of agenceList; track agence) {
                <mat-option [value]="agence.codeAgence">{{agence.libelle | titlecase}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          @if (!_isVisualisation){
            <mat-form-field appearance="outline" class="w-full mx-5">
              <mat-label>Collaborateur</mat-label>
              <input matInput type="text" formControlName="collaborator" [matAutocomplete]="autoCollab">
              <mat-autocomplete #autoCollab="matAutocomplete" [displayWith]="displayCollaborator">
                <mat-option *ngFor="let collab of collaborateurList" [value]="collab">
                  {{ collab.prenom }} {{ collab.nom }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          }

          <mat-form-field appearance="outline" class="w-full mx-5">
            <mat-label>Catégorie</mat-label>
            <mat-select formControlName="category">
              <mat-option [value]="null">Tous</mat-option>
              @for (category of categoryKeys; track category) {
                <mat-option [value]="category">{{category | titlecase}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-full mx-5">
            <mat-label>Statut</mat-label>
            <mat-select formControlName="status">
              <mat-option [value]="null">Tous</mat-option>
              @for (status of statusKeys; track status) {
                <mat-option [value]="status">{{status | titlecase}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-full mx-5">
            <mat-label>Tags</mat-label>
            <mat-select formControlName="tags" multiple>
              <mat-select-trigger>
                {{this.filterQuestionnaire!.value.tags[0] || ''}}
                @if ((this.filterQuestionnaire!.value.tags.length || 0) > 1) {
                  <span class="example-additional-selection">
          (+{{(this.filterQuestionnaire!.value.tags.length || 0) - 1}} {{this.filterQuestionnaire!.value.tags.length === 2 ? 'other' : 'others'}})
        </span>
                }
              </mat-select-trigger>
              @for (tag of tagsList; track tag) {
                <mat-option [value]="tag.title">{{tag.title}}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-full mx-5">
            <mat-label>De</mat-label>
            <input matInput [matDatepicker]="picker" [max]="today" formControlName="date">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <app-button
            label="Filtrer"
            (buttonClick)="applyFilter()"
            color="sky-blue"
          ></app-button>

        </form>
      </div>

      <div class="flex justify-between">
        <mat-card appearance="outlined" class="w-[20%]">
          <mat-card-header>
            <mat-card-subtitle>Formulaires envoyés</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="mt-5">
            <p class="text-2xl font-black">{{ this.statistic.totalUserForms }}</p>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" class="w-[20%]">
          <mat-card-header>
            <mat-card-subtitle>Formulaires complétés</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="mt-5">
            <p class="text-2xl font-black">{{ this.statistic.totalCompletedUserForms }}</p>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" class="w-[20%]">
          <mat-card-header>
            <mat-card-subtitle>Non répondu</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="mt-5">
            <p class="text-2xl font-black">{{ this.statistic.totalSentUserForms }}</p>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" class="w-[20%]">
          <mat-card-header>
            <mat-card-subtitle>En cours</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="mt-5">
            <p class="text-2xl font-black">{{ this.statistic.totalInProgressUserForms }}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="grow mt-10">

      <div class="border border-gray-200">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="category">
            <th mat-header-cell class="category-column" *matHeaderCellDef>Catégorie</th>
            <td mat-cell class="category-column" *matCellDef="let element">
              <app-form-icon [formCategory]="element.form.category"
                             [mode]="'dashboard'"></app-form-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Nom</th>
            <td mat-cell class="overflow-hidden whitespace-nowrap overflow-ellipsis" *matCellDef="let element">{{element.form.title}}</td>
          </ng-container>

          <ng-container matColumnDef="sendDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'envoi</th>
            <td mat-cell *matCellDef="let element">{{element.sendDate | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
          </ng-container>

          <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef>Progression</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-between items-center">
                <mat-progress-bar mode="determinate" [value]="getProgressValue(element)"></mat-progress-bar>
                <div class="px-3 text-xs whitespace-nowrap">{{element.statusSummary}}</div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex justify-between">
                <div [ngClass]="{'text-[var(--violine)]' : isInProgress(element.aggregatedStatus)}">{{displayStatus(element.aggregatedStatus)}}</div>
                <mat-icon class="cursor-pointer" (click)="goToDetail(element)">remove_red_eye</mat-icon>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>
      </div>

    </div>
  </div>
}




