<section [formGroup]="secondStepForm" class="flex flex-col items-center">
  <section [formArrayName]="'reportElements'" class="w-3/5" >
    <h3 class="text-2xl font-bold pb-3">Définissez les textes conditionnels de votre question</h3>

    <div class="mb-5">
      <div class="flex items-center justify-between bg-green-400/15 text-green-500 pr-5 pl-2 py-2.5 rounded-md">
        <h4 class="flex items-center text-lg font-bold"><mat-icon>arrow_right_alt</mat-icon> Les points d'appuis</h4>
        <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">check_circle</mat-icon>
      </div>
      <div *ngFor="let r of reportElements.controls; let i = index" [formGroupName]="i">
        <section *ngIf="r.get('flag')?.value === QuestionReportFlag.GREEN" class="flex gap-5 my-5">
          <mat-form-field class="-mb-5">
            <mat-label>Si réponse</mat-label>

            @if (firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES) {
              <mat-select [formControlName]="'answer'">
                <mat-option *ngFor="let answer of questionAnswers.controls" [value]="answer.get('displayOrder')?.value">{{(answer.get('displayOrder')?.value + 1)+ ' - ' + answer.get('value')?.value}}</mat-option>
              </mat-select>
            } @else {
              <mat-select [formControlName]="'answer'">
                <mat-option *ngFor="let answer of evalCounter(questionAnswers.controls[0].get('min')?.value, questionAnswers.controls[0].get('max')?.value)" [value]="answer">{{(answer)}}</mat-option>
              </mat-select>
            }
          </mat-form-field>

          <mat-form-field class="w-full -mb-5">
            <mat-label>Texte conditionnel</mat-label>
            <input matInput type="text" [formControlName]="'text'" [placeholder]="'Le text de votre choix'">
          </mat-form-field>
        </section>
      </div>


      <button (click)="addElement(QuestionReportFlag.GREEN)" class="underline mt-5 h-5 flex"><mat-icon class="w-5 h-5">add</mat-icon> Ajouter un nouvel élément d'appuis</button>
    </div>

    <div class="mb-5">
      <div class="flex items-center justify-between bg-orange-400/10 text-orange-400 pr-5 pl-2 py-2.5 rounded-md">
        <h4 class="flex items-center text-lg font-bold"><mat-icon>arrow_right_alt</mat-icon> Les points à surveiller</h4>
        <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">error</mat-icon>
      </div>

      <div *ngFor="let r of reportElements.controls; let i = index" [formGroupName]="i">
        <section *ngIf="r.get('flag')?.value === QuestionReportFlag.ORANGE" class="flex gap-5 my-5">
          <mat-form-field class="-mb-5">
            <mat-label>Si réponse</mat-label>

            @if (firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES) {
              <mat-select [formControlName]="'answer'">
                <mat-option *ngFor="let answer of questionAnswers.controls" [value]="answer.get('displayOrder')?.value">{{(answer.get('displayOrder')?.value + 1)+ ' - ' + answer.get('value')?.value}}</mat-option>
              </mat-select>
            } @else {
              <mat-select [formControlName]="'answer'">
                <mat-option *ngFor="let answer of evalCounter(questionAnswers.controls[0].get('min')?.value, questionAnswers.controls[0].get('max')?.value)" [value]="answer">{{(answer)}}</mat-option>
              </mat-select>
            }
          </mat-form-field>

          <mat-form-field class="w-full -mb-5">
            <mat-label>Texte conditionnel</mat-label>
            <input matInput type="text" [formControlName]="'text'" [placeholder]="'Le text de votre choix'">
          </mat-form-field>
        </section>
      </div>

      <button (click)="addElement(QuestionReportFlag.ORANGE)" class="underline mt-5 h-5 flex"><mat-icon class="w-5 h-5">add</mat-icon> Ajouter un nouvel élément à surveiller</button>
    </div>

    <div>
      <div class="flex items-center justify-between bg-red-400/10 text-red-400 pr-5 pl-2 py-2.5 rounded-md">
        <h4 class="flex items-center text-lg font-bold"><mat-icon>arrow_right_alt</mat-icon> Les points critique</h4>
        <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">warning</mat-icon>
      </div>

      <div *ngFor="let r of reportElements.controls; let i = index" [formGroupName]="i">
      <section *ngIf="r.get('flag')?.value === QuestionReportFlag.RED" class="flex gap-5 my-5">
        <mat-form-field class="-mb-5">
          <mat-label>Si réponse</mat-label>

          @if (firstStepForm.get('type')?.value === QuestionTypeEnum.CHOICES) {
            <mat-select [formControlName]="'answer'">
              <mat-option *ngFor="let answer of questionAnswers.controls" [value]="answer.get('displayOrder')?.value">{{(answer.get('displayOrder')?.value + 1)+ ' - ' + answer.get('value')?.value}}</mat-option>
            </mat-select>
          } @else {
            <mat-select [formControlName]="'answer'">
              <mat-option *ngFor="let answer of evalCounter(questionAnswers.controls[0].get('min')?.value, questionAnswers.controls[0].get('max')?.value)" [value]="answer">{{(answer)}}</mat-option>
            </mat-select>
          }
        </mat-form-field>

        <mat-form-field class="w-full -mb-5">
          <mat-label>Texte conditionnel</mat-label>
          <input matInput type="text" [formControlName]="'text'" [placeholder]="'Le text de votre choix'">
        </mat-form-field>
      </section>
    </div>

      <button (click)="addElement(QuestionReportFlag.RED)" class="underline mt-5 h-5 flex"><mat-icon class="w-5 h-5">add</mat-icon> Ajouter un nouvel élément critique</button>
    </div>
  </section>
</section>
