import {Component, Input, OnInit} from '@angular/core';
import {Question, QuestionAnswer, QuestionRestitutionSynthesis} from "../../../../../models/questions.model";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-restitution-synthesis',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './restitution-synthesis.component.html',
  styleUrl: './restitution-synthesis.component.scss'
})
export class RestitutionSynthesisComponent implements OnInit {

  @Input() questionSections!: QuestionRestitutionSynthesis;

  public organizedResponsesArray: { question: Question, answers: QuestionAnswer[] }[] = [];

  ngOnInit(): void {
    // Transformer la Map en un tableau pour l'itération
    this.questionSections.organizedResponses.forEach((answers, question) => {
      this.organizedResponsesArray.push({ question, answers });
    });
  }

}
