@if (isLoading) {
  <app-loader></app-loader>
} @else {
  @if (hasCoverPage) {
    <div class="flex flex-col h-full w-full">
      <div class="flex shadow-md bg-black">
        <div class="flex items-center">
          <div class="mr-5">
            <img class="w-48" src="https://www.inextenso.fr/wp-content/themes/inextenso/images/logo-inextenso.svg" alt="new logo In Extenso !"/>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1 justify-between w-full px-10 py-12">
        <div class="text-3xl font-bold">Page de garde</div>
        <div class="flex justify-center">
          <div class="break-words w-1/3 font-medium">
            {{form?.coverPage}}
          </div>
        </div>
        <div class="flex justify-end px-60">
          <app-button
            [label]="'Suivant'"
            color="sky-blue"
            appearance="filled"
            (buttonClick)="changeCoverPageStatus()"
            [disabled]="false">
          </app-button>
        </div>
      </div>
    </div>
  } @else {
    <div class="h-full flex flex-col overflow-hidden">
      @if (showTopBar) {
        <div class="flex shadow-md bg-black">
          <div class="flex items-center">
            <div class="mr-5">
              <img class="w-48" src="https://www.inextenso.fr/wp-content/themes/inextenso/images/logo-inextenso.svg" alt="new logo In Extenso !"/>
            </div>
          </div>
        </div>
      }
      <div class="h-full flex flex-1 overflow-hidden">
        <div class="h-full w-1/4 overflow-y-auto overflow-x-hidden">
          <app-form-summary [form]="form" [isSummaryBlockedForVisualisation]="true"></app-form-summary>
        </div>
        <div class="h-full w-3/4 flex flex-col gap-3">
          <div class="p-3 ">
            <mat-button-toggle-group class="mt-5" [value]="formDisplayMode" (change)="changeDisplayMode($event)">
              <mat-button-toggle [value]="FormDisplayMode.VISUALISATION">Visualisation</mat-button-toggle>
              <mat-button-toggle [value]="FormDisplayMode.SYNTHESE">Synthèse</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          @if (formDisplayMode === FormDisplayMode.VISUALISATION) {
            <div style="max-height: 78%; min-height: 78%">
              <app-form-visualisation [form]="form" (questionAnswered)="setQuestionAnswer($event)"></app-form-visualisation>
            </div>
          }
          @if (formDisplayMode === FormDisplayMode.VISUALISATION) {
            <div class="flex justify-between px-8 mb-5">
              <app-button
                [label]="'Retour'"
                icon="arrow_back_ios"
                color="sky-blue"
                [disabled]="false"
                (buttonClick)="showTopBar ? this.setCoverPageStatus() : null"
              >
              </app-button>
              <app-button
                [label]="'Envoyer'"
                color="sky-blue"
                appearance="filled"
                [disabled]="false">
              </app-button>
            </div>
          }
        </div>
      </div>
    </div>
  }
}
