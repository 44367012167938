<div [formGroup]="firstStepFormGroup" *ngIf="!_loading">
  <div [formArrayName]="'questionAnswers'">
    @for (answer of answerArray.controls; track $index) {
      <section [formGroupName]="$index">
        <div class="my-3 flex justify-between items-center">
          <h4>Évaluation inverse : </h4>
          <div>
            <mat-slide-toggle [formControlName]="'formatType'"></mat-slide-toggle>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="flex justify-between items-center my-3">
          <h4>Configuration de l'échelle :</h4>

          <div class="flex gap-2 items-center">
            <h5>de :</h5>
            <mat-form-field class="w-24 -mb-5">
              <mat-label>Min</mat-label>

              <mat-select [formControlName]="'min'">
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
              </mat-select>
            </mat-form-field>

            <h5>à : </h5>
            <mat-form-field class="w-24 -mb-5">
              <mat-label>Max</mat-label>

              <mat-select [formControlName]="'max'">
                <mat-option *ngFor="let number of generateNumbers()" [value]="number">{{number}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="flex justify-between gap-5 mt-3">
          <mat-form-field class="w-1/2">
            <mat-label>Libellé gauche (facultatif)</mat-label>
            <input matInput type="text" [formControlName]="'leftLabel'">
          </mat-form-field>
          <mat-form-field class="w-1/2">
            <mat-label>Libellé droit (facultatif)</mat-label>
            <input matInput type="text" [formControlName]="'rightLabel'">
          </mat-form-field>
        </div>

      </section>
    }
  </div>
</div>
