<div class="h-1/3 flex mb-2">
  <div class="grow px-8 py-3 w-11/12">
    <div class="font-bold flex gap-2 items-center w-full">
      @if (showIcon) {
        <mat-icon class="opacity-60 min-w-6" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(question.type)">{{QuestionTypeUtil.getQuestionTypeIcon(question.type)}}</mat-icon>
      }
      <div class="truncate">{{ question.question }}</div>
    </div>
    <div class="text-sm italic truncate">{{ this.extractTextFromHtml(question.description)}}</div>
  </div>
  @if (!visualisationMode) {
    <div class="cursor-pointer" (click)="deleteSelectedQuestion(question.id)">
      <mat-icon class="m-3">delete</mat-icon>
    </div>
  } @else {
    <mat-checkbox [checked]="isNotApplicable" (click)="setResponseNonApplicable()">
      Non Applicable
    </mat-checkbox>
  }
</div>
<div class="px-8 h-2/3 flex-col italic text-gray-200" [formGroup]="questionForm">
  <mat-radio-group class="flex justify-evenly" [formControlName]="'answer'" (change)="onFormChanges()">
    @for (answer of this.questionAnswersForEvaluationType; track answer; let i = $index){
      <div class="flex flex-col gap-2 items-center">
        <div class="text-blue-950">{{answer.value}}</div>
        <mat-radio-button [value]="answer.value"></mat-radio-button>
        <div class="text-blue-950">{{answer.label}}</div>
      </div>
    }
  </mat-radio-group>
</div>
