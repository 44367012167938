import {Component, Input} from '@angular/core';
import {NgClass, NgStyle} from "@angular/common";
import {StyleMap} from "../../form-card/form-card.component";

@Component({
  selector: 'app-form-icon',
  standalone: true,
  imports: [
    NgStyle,
    NgClass
  ],
  templateUrl: './form-icon.component.html',
  styleUrl: './form-icon.component.scss'
})
export class FormIconComponent {

  @Input() formCategory! : string;
  @Input() mode!: string;

  defaultColor: string = "#454545"
  defaultIcon: string = "folder"

  styleMap: StyleMap[] = [
    {category: 'DIAGNOSTIC', primaryColor : '#450992', secondaryColor : '#2196F3', icon: 'troubleshoot'},
    {category: 'PROFILAGE', primaryColor : '#295ff5', secondaryColor : '#FB8C00', icon: 'badge'},
    {category: 'CONSEIL', primaryColor : '#f5c244', secondaryColor : '#FF6347', icon: 'chat'},
    {category: 'MARKETING', primaryColor : '#3a7f4f', secondaryColor : '#50C878', icon: 'account_balance'},
  ];

  getSecondaryColorMap() :string {
    const style: StyleMap | undefined = this.styleMap.find((style: StyleMap) => style.category === this.formCategory)
    return style ? style.secondaryColor : this.defaultColor;
  }

  getCategoryIcon(): string {
    const style: StyleMap | undefined = this.styleMap.find((style: StyleMap) => style.category === this.formCategory)
    return style ? style.icon : this.defaultIcon;
  }

}
