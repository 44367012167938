<div [formGroup]="firstStepFormGroup" *ngIf="!_loading">
  <div [formArrayName]="'questionAnswers'" cdkDropList (cdkDropListDropped)="drop($event)">
    <h4 class="my-3">Ordre attendu :</h4>
    <div *ngFor="let answer of answerArray.controls; let i = index" [formGroupName]="i" class="flex items-center justify-between gap-5 my-3" cdkDrag>
      <div class="flex items-center gap-3">
        <button cdkDragHandle class="flex"><mat-icon>drag_indicator</mat-icon></button>
        <h5>{{i + 1}}</h5>
      </div>
      <div class="w-full">
        <mat-form-field class="w-full -mb-5">
          <mat-label>Libellé</mat-label>
          <input matInput type="text" [formControlName]="'value'" [placeholder]="'Libellé'">
        </mat-form-field>
      </div>

      <div>
        <button (click)="answerArray.removeAt(i)" [disabled]="answerArray.length === 1" [class]="answerArray.length === 1 ? 'cursor-not-allowed' : 'cursor-pointer' "><mat-icon [class]="answerArray.length === 1 ? 'opacity-60' : ''">{{answerArray.length === 1 ?  "delete_forever" : "delete" }}</mat-icon></button>
      </div>
    </div>
    <button (click)="addElement()" class="underline mt-5 h-5 flex"><mat-icon class="w-5 h-5">add</mat-icon> Ajouter un nouvel élément</button>
  </div>
</div>
