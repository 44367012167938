import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, first, Observable, of, throwError} from "rxjs";
import {Form, FormParams} from "../../models/form.model";
import {NotificationsStatus} from "../../enums/notifications-status";
import {Level, NotificationService} from "../front/notifications.service";
import {PaginatedListResponse} from "../../models/PaginatedListResponse.model";
import {OperationResponseModel} from "../../models/operation-response.model";
import {environment} from "../../../environments/environment";

const API_URL = environment.apiURL;

@Injectable()
export class FormService {
  private _baseUrl: string = API_URL + '/forms';

  isErrorServer: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  getFormById(id: string): Observable<Form> {
    return this.httpClient.get<Form>(this._baseUrl + '/' + id).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of();
      })
    )
  }

  getAllFormWithParams(formParams: FormParams): Observable<PaginatedListResponse<Form>> {

    const defaultResponse: PaginatedListResponse<Form> = {totalNumbers: 0, totalPages: 0, data: []};
    let myParams = new HttpParams();

    for (const [key, value] of Object.entries(formParams)) {
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          myParams = myParams.set(key, value.join(','));
        } else {
          myParams = myParams.set(key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase(), value);
        }
      }
    }

    return this.httpClient.get<PaginatedListResponse<Form>>(this._baseUrl + '/search-all', {params : myParams}).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of(defaultResponse);
      })
    )
  }

  getAllForms(): Observable<Form[]> {
    return this.httpClient.get<Form[]>(this._baseUrl).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of([]);
      })
    )
  }

  createForm(formDTO: Form): Observable<Form> {
    return this.httpClient.post<Form>(this._baseUrl, formDTO).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!error.error.includes('Violation of UNIQUE KEY constraint')) {
          this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
          this.isErrorServer = true;
        }
        return throwError(() => error);
      })
    );
  }

  editForm(id: string, formDTO: Form): Observable<Form> {
    return this.httpClient.put<Form>(`${this._baseUrl}/${id}`, formDTO).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  updateFormStatusToArchived(id: string): Observable<Form> {
    return this.httpClient.patch<Form>(this._baseUrl + '/' + id +'/status', {}).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of();
      })
    )
  }

  duplicateForm(formId: number, params: Map <String, String>): Observable<OperationResponseModel> {
    const jsonObject = Object.fromEntries(params);
    return this.httpClient.post<OperationResponseModel>(this._baseUrl + '/duplicate/' + formId, jsonObject)
      .pipe(first(),
        catchError(error => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, error.error, 5000))));
  }

  deleteForm(id: string): Observable<void> {
    return this.httpClient.delete<void>(this._baseUrl + '/' + id).pipe(
      catchError(() => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        return of();
      })
    )
  }

  getForm(id: string): Observable<Form> {
    return this.httpClient.get<Form>(`${this._baseUrl}/${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showNotification(NotificationsStatus.SERVER_ERROR);
        this.isErrorServer = true;
        return throwError(() => console.log(error));
      })
    )
  }

}
