import {Tag} from "./tag.model";
import {Question, QuestionResponseModel} from './questions.model';
import {QuestionThreadModel} from "./question-thread.model";

export interface Form {
  uuid?: string;
  title: string;
  category: FormCategoryModel;
  tagsList: Tag[];
  status: FormStatus;
  createdAt?: Date;
  coverPage: string;
  creator: string;
  withReport: boolean;
  formsComponentsList: (Question | Section)[];
  questionThreadList : QuestionThreadModel[]
}

export interface FormCategoryModel {
  color: string;
  name: string;
  icon: string;
}

export enum FormCategoryEnum {
  DIAGNOSTIC = 'diagnostic',
  CONSEIL = 'conseil',
  PROFILAGE = 'profilage',
  MARKETING = 'marketing'
}

export interface Section {
  id?: number;
  name: string;
  formsComponentsType: FormComponentsType;
  synthesisType: SynthesisType;
}

export enum FormStatus {
  DRAFT = 'BROUILLON',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVÉE'
}

export enum FormComponentsType {
  SECTION = 'SECTION',
  QUESTION = 'QUESTION',
}

export enum SynthesisType {
  AUCUNE = 'AUCUNE',
  RESTITUTION = 'RESTITUTION',
  GRAPHIQUE = 'GRAPHIQUE',
  CONDITIONNEL = 'CONDITIONNEL',
}

export const synthesisTypeList: { key: SynthesisType; value: string }[] = [
  { key: SynthesisType.AUCUNE, value: 'Aucune' },
  { key: SynthesisType.RESTITUTION, value: 'La restitution' },
  { key: SynthesisType.GRAPHIQUE, value: 'Le graphique' },
  { key: SynthesisType.CONDITIONNEL, value: 'Le rapport conditionnel' },
];

export class FormParams {
  constructor(
    public title?: string | null,
    public category?: string | null,
    public status?: string[] | null,
    public tagsList?: string[] | null,
    public page?: number | null, // -1 for fetch all
    public size?: number | null // -1 for fetch all
  ) {}
}

export interface UniqueMultipleSavedAnswerModel {
  choices: string[];
  freeText: string;
}

export interface RankSavedAnswerModel {
  choices: QuestionResponseModel[];
}

export interface evaluationSavedAnswerModel {
  value: number;
  label: string;
}

export interface FormDetailModel {
  formId: string,
  clientId: string,
  clientCode: string,
  clientName: string,
  raisonSoc: string,
  status: FormAnswerStatus
}

export enum FormAnswerStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED'
}
