<div class="h-1/3 flex mb-2">
  <div class="grow px-8 py-3 w-11/12">
    <div class="font-bold flex gap-2 items-center w-full">
      @if (showIcon) {
        <mat-icon class="opacity-60 min-w-6" [matTooltip]="QuestionTypeUtil.getQuestionTypeTooltip(question.type)">{{QuestionTypeUtil.getQuestionTypeIcon(question.type)}}</mat-icon>
      }
      <div class="truncate">{{ question.question }}</div>
    </div>
    <div class="text-sm italic truncate">{{ this.extractTextFromHtml(question.description) }}</div>
  </div>
  @if (!visualisationMode) {
    <div class="cursor-pointer" (click)="deleteSelectedQuestion(question.id)">
      <mat-icon class="m-3">delete</mat-icon>
    </div>
  } @else {
    <mat-checkbox [checked]="isNotApplicable" (click)="setResponseNonApplicable()">
      Non Applicable
    </mat-checkbox>
  }
</div>

<div class="px-8 h-2/3 flex-col italic text-gray-200" [formGroup]="questionForm">
  <div class="flex justify-evenly flex-wrap">
    @if (question.type === QuestionTypeEnum.MULTIPLE_CHOICE;) {
      @for (option of questionPossibleAnswerWithOutFreeText; track option; let i = $index) {
        <mat-checkbox [formControlName]="'checkbox' + i" (change)="onFormChanges()">
          {{ option.value }}
        </mat-checkbox>
      }
    } @else {
      <mat-radio-group formControlName="radio" (change)="onFormChanges()" class="flex justify-evenly flex-wrap w-full">
        @for (option of questionPossibleAnswerWithOutFreeText; track option; let i = $index) {
          <mat-radio-button [value]="option.value">
            {{ option.value }}
          </mat-radio-button>
        }
      </mat-radio-group>
    }
  </div>
  <div class="flex flex-col mt-4 items-center">
    @for (option of question.questionPossibleAnswers; track option; let i = $index) {
      @if (option.displayOrder < 0) {
        <mat-form-field class="w-3/4" appearance="fill">
          <mat-label>Text libre</mat-label>
          <textarea matInput #message maxlength="256" placeholder="Text libre" type="text" formControlName="freeText"></textarea>
          <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
        </mat-form-field>
      }
    }
  </div>
</div>
