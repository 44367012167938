<div class="min-h-full w-full flex-col border-r-[2px]">
  @if(thirdStepFormGroup) {
    <div class="w-full h-12"></div>
  }
  <div class="h-12">
   <p class="text-red-400 font-black text-lg px-8 py-4">Sommaire</p>
  </div>
  <div class="w-full min-h-full pr-5">
    <div cdkDropListGroup>
      <div
        cdkDropList
        [cdkDropListData]="allSections"
        class="summary-container"
        (cdkDropListDropped)="dropSection($event)">
        @for (element of allSections; track trackByFn; let i=$index) {
          <div cdkDrag>
            <div class="summary-box">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="flex items-center gap-5 w-11/12">
                    <mat-icon class="min-w-6">drag_indicator</mat-icon>
                    <span class="grow truncate">{{element.section.name}}</span>
                    @if (!isSummaryBlockedForVisualisation){
                      <div class="flex gap-3">
                        <mat-icon class="cursor-pointer"
                                  [ngClass]="{'text-gray-200' : element.questions.length > 0}"
                                  (click)="deleteSection(i)">delete</mat-icon>
                        <mat-icon class="cursor-pointer" (click)="showEditSectionName(element, i)">edit</mat-icon>
                      </div>
                    }
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  cdkDropList
                  id="subSectionList{{i}}"
                  [cdkDropListData]="element.questions"
                  [cdkDropListConnectedTo]="connectedSubLists"
                  class="summary-list"
                  (cdkDropListDropped)="dropSubSection($event, i)">
                  @for (question of element.questions; track trackByFn) {
                    <div class="sub-summary-box gap-5 flex" cdkDrag>
                      <mat-icon class="min-w-6 text-lg">drag_indicator</mat-icon>
                      <span class="truncate grow text-xs">{{question.question}}</span>
                    </div>
                  }
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        }
      </div>
    </div>
    @if (!isSummaryBlockedForVisualisation){
      <div class="max-h-10 flex justify-center mt-5">
        @if (!isEditModeForSectionName) {
          @if (isLoading) {
            <div class="w-full">
              <app-loader></app-loader>
            </div>
          } @else {
            <mat-form-field appearance="outline" (keydown)="addSection($event)">
              <mat-icon matPrefix>add</mat-icon>
              <mat-label class="text-sm">Ajouter une thématique</mat-label>
              <input matInput [formControl]="addSectionControl">
            </mat-form-field>
          }
        } @else {
          @if (isLoading) {
            <div class="w-full">
              <app-loader></app-loader>
            </div>
          } @else {
            <div class="flex-col gap-5">
              <mat-form-field appearance="outline" (keydown)="editSectionName($event)" [formGroup]="editSectionGroupForm">
                <mat-label>Modifier la thématique</mat-label>
                <input formControlName="sectionName" matInput>
              </mat-form-field>
              <div class="flex justify-center">
                <button mat-stroked-button (click)="cancelEditingSectionName()" [disabled]="isLoading">
                  <p class="text-xs text-gray-7">Annuler la modification</p>
                </button>
              </div>
            </div>
          }
        }
      </div>
    }
  </div>
</div>
