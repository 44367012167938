<section class="flex flex-col w-full md:px-8 md:py-5 py-2">
  <div class="flex justify-between items-center">
    <div>
      <h3 class="font-bold text-xl md:text-2xl">Bibliothèque</h3>
    </div>
    <div class="hidden md:flex justify-end min-w-[429px]">
      <div [routerLink]="PATHS.LIBRARY.CREATE">
        <button class="flex items-center text-white py-3 px-6 w-52 rounded bg-[var(--vert-eau)]">
          <mat-icon>add</mat-icon>
          <span class="text-md h-5">Créer une question</span></button>
      </div>
    </div>
  </div>
  <app-questions-list [mode]="'library'"></app-questions-list>
</section>
