<form [formGroup]="secondStepFormGroup" class="h-full mt-3">
  <div class="h-[90%] flex">
    <quill-editor class="popup-body grow"
                  formControlName="coverPage"
                  [modules]="editorConfig.modules"
                  [placeholder]="editorConfig.placeholder"
    ></quill-editor>
  </div>

</form>
