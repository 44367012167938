import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    MatIcon,
    MatButton,
    UpperCasePipe,
    MatIconButton
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  label: typeof Label = Label;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}

export interface ConfirmDialogData {
  title: string;
  text: string;
  text2?: string;
  yes?: string;
  no?: string;
}

enum Label {
  YES = 'OUI',
  NO = 'NON'
}
