<div id="synthesis">
  @if (isLoading) {
    <app-loader></app-loader>
  } @else {
    <div class="container mt-5">
      <h2 class="text-2xl">Synthèse générale du formulaire</h2>

      @for (questionSection of getQuestionSectionOrganizedList(); track $index) {
        <app-synthesis-card [questionSection]="questionSection" [questionAnswers]="getAnswersForSection(questionSection)"></app-synthesis-card>
      }
    </div>
  }
</div>
