import {Component, OnInit} from '@angular/core';
import {FormService} from '../../../services/back/form.service';
import {ActivatedRoute, Router} from "@angular/router";
import {Form} from '../../../models/form.model';
import {FormSummaryComponent} from "../create-form/form-summary/form-summary.component";
import {FormVisualisationComponent} from "../../shared/form-visualisation/form-visualisation.component";
import {QuestionAnswerForm} from "../../../models/questions.model";
import {LoaderComponent} from "../../shared/loader/loader.component";
import {FormDisplayMode} from "../../../enums/form.enum";
import {MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {ButtonComponent} from "../../shared/button/button.component";
import {MatIcon} from "@angular/material/icon";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-view-form',
  standalone: true,
  imports: [
    FormSummaryComponent,
    FormVisualisationComponent,
    LoaderComponent,
    MatButtonToggle,
    MatButtonToggleGroup,
    ButtonComponent,
    MatIcon,
    NgIf
  ],
  templateUrl: './view-form.component.html',
  styleUrl: './view-form.component.scss'
})
export class ViewFormComponent implements OnInit {

  form: Form | null = null
  isLoading = false;
  formDisplayMode: FormDisplayMode = FormDisplayMode.VISUALISATION;
  protected readonly FormDisplayMode = FormDisplayMode;
  hasCoverPage : boolean = false;
  showTopBar : boolean = false;

  constructor(private formService: FormService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.setCoverPageStatus();
    const formId = this.route.snapshot.paramMap.get('forms_uuid') || '';
    this.formService.getFormById(formId).subscribe((form) => {
      this.form = form;
      this.isLoading = false;
    });
  }

  setCoverPageStatus() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/forms/view/')) {
      this.hasCoverPage = false;
      this.showTopBar = false;
    } else if (currentUrl.includes('/forms/')) {
      this.hasCoverPage = true;
      this.showTopBar = true;
    }
  }

  // TODO : to be implemented for save the answers in the database
  setQuestionAnswer($event: QuestionAnswerForm) {
    console.log("last responses",$event);
  }

  changeDisplayMode(event: MatButtonToggleChange) {
    this.formDisplayMode = event.value;
  }

  changeCoverPageStatus() {
    this.hasCoverPage = !this.hasCoverPage;
  }
}
