import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {Level, NotificationService} from "../front/notifications.service";
import {catchError, Observable, throwError} from "rxjs";
import {
  Agence,
  Client,
  ClientImportFormFilters,
  CodeNaf,
  Collaborateur,
  FormeJuridique,
  Segment
} from "../../models/inexref.model";
import {User} from "../../models/user.model";

const API_URL = environment.apiURL;

@Injectable()
export class InexrefService {
  private _baseUrl: string = API_URL + '/inexrefs';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {
  }

  getMe(email: string): Observable<User> {
    return this.httpClient.post<User>(this._baseUrl + '/me', {"email" : email}).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Il est impossible de récupérer les informations du collaborateur.', 5000))));
  }

  // [POST] /api/inexrefs/:region
  listInformationClients(formFilters: ClientImportFormFilters, codeRegion: string): Observable<Client[]> {
    return this.httpClient.post<Client[]>(this._baseUrl + '/' + codeRegion, formFilters).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région: ' + codeRegion, 5000))));
  }

  // [GET] /api/inexrefs/:region
  getClientByRegion(codeRegion: string): Observable<Client[]> {
    return this.httpClient.get<Client[]>(this._baseUrl + '/' + codeRegion).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région: ' + codeRegion, 5000))));
  }

  // [GET] /api/inexrefs/:region/agences
  getClientAgencesByRegion(codeRegion: string): Observable<Agence[]> {
    return this.httpClient.get<Agence[]>(this._baseUrl + "/" + codeRegion + '/agences' )
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(() =>
          this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région: ' + codeRegion, 5000))));
  }

  // [GET] /api/inexrefs/codes-naf
  getClientCodeNAF(code: string, label: string): Observable<CodeNaf[]> {
    return this.httpClient.get<CodeNaf[]>(this._baseUrl + '/codes-naf', {
      params: new HttpParams()
        .set("code", code)
        .set("label", label)
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région: ' + code + label, 5000))));
  }

  // [GET] /api/inexrefs/:region/collaborateurs
  getClientCollaborateurs(codeRegion: string, name: string): Observable<Collaborateur[]> {
    return this.httpClient.get<Collaborateur[]>(this._baseUrl + '/' + codeRegion + '/collaborateurs', {
      params: new HttpParams()
        .set("name", name)
    }).pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les collaborateur pour cette région: ' + codeRegion, 5000))));
  }

  // [GET] /api/inexrefs/segments
  getClientSegments(): Observable<Segment[]> {
    return this.httpClient.get<Segment[]>(this._baseUrl + '/segments').pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région ', 5000))));
  }

  // [GET] /api/inexrefs/formes-juridiques
  getClientFormesJuridiques(): Observable<FormeJuridique[]> {
    return this.httpClient.get<FormeJuridique[]>(this._baseUrl + '/formes-juridiques').pipe(
      catchError((error: HttpErrorResponse) => throwError(() =>
        this.notificationService.sendNotification(Level.ERROR, 'Impossible de récupérer les clients pour cette région: ', 5000))));
  }
}
