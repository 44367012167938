import {Environment} from "./environment.interface";

export const environment: Environment = {
  env: "Local Dev",
  apiURL: "https://rct-ieo-inexplus.fabrick.io/api",
  msal: {
    clientId: "b2deea15-ccac-485d-8d38-55dccf5025f8", // APP ID
    authority: "https://login.microsoftonline.com/", // auth microsoft
    redirectURI: "https://rct-ieo-inexplus.fabrick.io/", // redirect
    tenantId: "bb948182-0852-4388-a948-98f0c3dfb67f", // tenant of APP
  }
}
