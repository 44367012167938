import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {AbstractControl, FormControl, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {TagService} from "../../services/back/tag.service";
import {Tag} from "../../models/tag.model";
import {HttpErrorResponse} from "@angular/common/http";
import {LoaderComponent} from "../shared/loader/loader.component";

@Component({
  selector: 'app-tags-list',
  standalone: true,
  imports: [
    NgForOf,
    MatIcon,
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    NgIf,
    LoaderComponent
  ],
  templateUrl: './tags-list.component.html',
  styleUrl: './tags-list.component.scss'
})
export class TagsListComponent implements OnInit, OnDestroy {
  subscription!: Subscription;
  tagListMax = 25;
  tagLengthMax = 14;
  errorMessage: string = '';
  tagsList: Tag[] = [];

  tagInput: FormControl = new FormControl('', [
    Validators.maxLength(this.tagLengthMax),
    this.onlyLettersValidator()]);

  constructor(public tagService: TagService) {
    this.tagInput.valueChanges.subscribe(newValue => {
      if (newValue === '') {
        console.log('reset error')
        this.errorMessage = '';
      }
    });
  }

  ngOnInit(): void {
    this.init();
  }


  init() {
    this.subscription = this.tagService.getTagsList().subscribe(
      (rsp: Tag[]) => {
        this.tagsList = rsp;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  addTag() {
    console.log(this.tagInput)
    if (this.tagInput.valid) {
      this.tagService.createTag({title: this.tagInput.value}).subscribe({
        next: () => {
          this.tagInput.reset()
          this.init();
        },
        error: error => this.handleError(error)
      });
    }
  }

  handleError(error : HttpErrorResponse) {
    if (error.error.includes('Violation of UNIQUE KEY constraint')) {
      this.errorMessage = 'Ce tag est déjà présent';
    } else if (error.error.includes('only_letters_constraint')) {
      this.errorMessage = 'Uniquement les lettres sont acceptés';
    } else {
      console.log(`Erreur: ${error}`);
      this.errorMessage = `Erreur ${error.status} `;
    }
  }

  removeTag(id: number) {
    this.tagService.deleteTag(id).subscribe(() => {
      this.init();
    })
  }

  onlyLettersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const regex = /^[a-zA-Z]+$/;

      if (!value) {
        return null;
      }

      const valid = regex.test(value);
      return valid ? null : { onlyLetters: { valid: false } };
    };
  }
}
