import {Component, Input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {QuestionsListComponent} from "../../../../library/questions-list/questions-list.component";
import {Question, QuestionSectionOrganized} from "../../../../../models/questions.model";
import {FormComponentsType, Section, SynthesisType, synthesisTypeList} from "../../../../../models/form.model";
import {FormControl, FormGroup, FormsModule} from "@angular/forms";
import {NotificationService} from "../../../../../services/front/notifications.service";
import {SectionOrganizerService} from "../../../../../services/front/section-organizer.service";
import {NgClass, NgStyle} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {QuestionThreadModel} from "../../../../../models/question-thread.model";
import {NavigationNodeModel} from "../../../../../models/navigation-node.model";
import {QuestionTypeEnum} from "../../../../../enums/questions.enum";
import {NotificationsStatus} from "../../../../../enums/notifications-status";
import {
  UniqueMultipleQuestionDisplayComponent
} from "../unique-multiple-question-display/unique-multiple-question-display.component";
import {RankQuestionDisplayComponent} from "../rank-question-display/rank-question-display.component";
import {EvaluationQuestionDisplayComponent} from "../evaluation-question-display/evaluation-question-display.component";
import {FreeTextQuestionDisplayComponent} from "../free-text-question-display/free-text-question-display.component";
import {MultiQuestionDisplayComponent} from "../multi-question-display/multi-question-display.component";
import {SectionService} from "../../../../../services/back/section.service";
import {LoaderComponent} from "../../../../shared/loader/loader.component";

@Component({
  selector: 'app-form-question-display-conception',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    QuestionsListComponent,
    NgStyle,
    FormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgClass,
    NgStyle,
    UniqueMultipleQuestionDisplayComponent,
    RankQuestionDisplayComponent,
    EvaluationQuestionDisplayComponent,
    FreeTextQuestionDisplayComponent,
    MultiQuestionDisplayComponent,
    LoaderComponent
  ],
  templateUrl: './form-question-display-conception.component.html',
  styleUrl: './form-question-display-conception.component.scss'
})
export class FormQuestionDisplayConceptionComponent {

  @Input() thirdStepFormGroup!: FormGroup;
  isLibraryDisplayed: boolean = false;
  navigationState: Map<number, boolean> = new Map();
  isLoading: boolean = false;
  protected readonly QuestionTypeEnum = QuestionTypeEnum;

  constructor(
    private notificationService: NotificationService,
    private sectionOrganizerService: SectionOrganizerService,
    private sectionService: SectionService
  ) {
    this.notificationService._isLibraryDisplayed.subscribe(value => {
      this.isLibraryDisplayed = value;
    });
  }

  displayLibrary(): void {
    const questionList = this.thirdStepFormGroup.get('formsComponentsList')?.value as (Question | Section)[];
    if(questionList.find(q=> q.formsComponentsType === FormComponentsType.SECTION)) {
      this.notificationService.setIsLibraryDisplayed(!this.isLibraryDisplayed)
    } else {
      this.notificationService.showNotification(NotificationsStatus.ERROR_SECTION_NOT_FOUND);
    }
  }

  get questionThreadList() {
    return this.thirdStepFormGroup.get('questionThreadList') as FormControl<QuestionThreadModel[]>;
  }

  get formsComponentsList() {
    return this.thirdStepFormGroup.get('formsComponentsList') as FormControl<(Question | Section) []>;
  }

  collectQuestion(question: Question): void {
    question.formsComponentsType = FormComponentsType.QUESTION;
    if(this.formsComponentsList.value.find(q => q.formsComponentsType === FormComponentsType.QUESTION && q.id === question.id)) {
      this.notificationService.showNotification(NotificationsStatus.ERROR_QUESTION_ALREADY_EXISTS);
      return;
    }
    this.formsComponentsList?.value.push(question);
    this.sectionOrganizerService.setQuestionListForm(this.formsComponentsList?.value);
    this.verifySectionSynthesis(question);
    let questionThread: QuestionThreadModel = {
      id: null,
      question: question,
      defaultNextQuestionId: null,
      previousQuestionId: null,
      next: [],
    };
    this.questionThreadList?.value.push(questionThread);
  }

  removeQuestionFromList(i: number) {
    const questionIndex = (this.thirdStepFormGroup.get('formsComponentsList')?.value).findIndex((q: Question) => q.formsComponentsType == FormComponentsType.QUESTION && q.id == i);
    this.thirdStepFormGroup.get('formsComponentsList')?.value.splice(questionIndex, 1);
    this.sectionOrganizerService.setQuestionListForm(this.thirdStepFormGroup.get('formsComponentsList')?.value);
  }

  getQuestionSectionOrganizedList() : QuestionSectionOrganized[] {
    return this.sectionOrganizerService.organizeSectionsAndQuestions(this.formsComponentsList?.value);
  }

  displayQuestion(id: number): Question[] {
    let list = this.getQuestionList();
    return list.filter((elt : Question) => elt.id != id);
  }

  addNavigation(idQuestion: number): void{
    let navNode: NavigationNodeModel = {nextQuestionId: 0, valueId: 0};
    this.questionThreadList.value.find((elt: QuestionThreadModel) => elt.question.id === idQuestion)?.next.push(navNode);
  }

  deleteNavigation(questionId: number, index: number): void {
    let qNavNode = this.questionThreadList.value.find((thread: QuestionThreadModel) => thread.question.id === questionId)?.next;
    qNavNode?.splice(index, 1);
  }

  navNodeList(idQuestion: number): NavigationNodeModel[] {
    return this.questionThreadList.value.find((elt: QuestionThreadModel) => elt.question.id === idQuestion)?.next ?? [];
  }

  getQuestionList(): Question[] {
    return this.formsComponentsList.value.filter((elt: Question | Section): elt is Question =>
      elt.formsComponentsType === FormComponentsType.QUESTION
    );
  }

  navigationToogle(questionId: number): void {
    const state: boolean = !!this.navigationState.get(questionId);
    this.navigationState.set(questionId, !state);
  }

  isNavigationMenuDisplayed(questionId: number): boolean{
    return this.navigationState.get(questionId) || false;
  }

  isQuestionTypeAuthorized(type: QuestionTypeEnum): boolean {
    let authorizedTypes: QuestionTypeEnum[] = [
      QuestionTypeEnum.MULTIPLE_CHOICE,
      QuestionTypeEnum.UNIQUE_CHOICE,
      QuestionTypeEnum.EVAL
    ];
    return !!authorizedTypes.find((elt: QuestionTypeEnum) => elt === type);
  }

  editSectionSynthesis(section: Section, synthesis: SynthesisType) {
    this.isLoading = true;
    section.synthesisType = synthesis;
    this.sectionService.updateSection(section).subscribe(() => this.isLoading = false);
  }

  getAvailableSynthesisTypes(questionsAndSections: QuestionSectionOrganized) {

    if(questionsAndSections.questions.length === 0) {
      return synthesisTypeList.filter(synthesis => synthesis.key == SynthesisType.AUCUNE);
    }

    const allAreEvaluations = questionsAndSections.questions.every(
      (question: Question) => question.type === QuestionTypeEnum.EVAL
    );

    const allAreUniqueMultipleOrEval = questionsAndSections.questions.every(
      (question: Question) =>
        question.type === QuestionTypeEnum.UNIQUE_CHOICE ||
        question.type === QuestionTypeEnum.MULTIPLE_CHOICE ||
        question.type === QuestionTypeEnum.EVAL
    );

    if (allAreEvaluations) {
      return synthesisTypeList.filter(synthesis => synthesis.key != SynthesisType.CONDITIONNEL);
    }
    if (allAreUniqueMultipleOrEval) {
      return synthesisTypeList.filter(synthesis => synthesis.key != SynthesisType.GRAPHIQUE);
    }
    return synthesisTypeList.filter(synthesis => synthesis.key == SynthesisType.RESTITUTION || synthesis.key == SynthesisType.AUCUNE);
  }

  isPreSelectSynthesisType(questionsAndSections: QuestionSectionOrganized) {
    return this.getAvailableSynthesisTypes(questionsAndSections).some(
      synthesisTypeObj => synthesisTypeObj.key === questionsAndSections.section.synthesisType
    );
  }

  verifySectionSynthesis(question: Question) {
    const foundSectionByQuestion = this.getQuestionSectionOrganizedList().find((section: QuestionSectionOrganized) => section.questions.find((q: Question) => q.id === question.id));
    if(foundSectionByQuestion && !this.isPreSelectSynthesisType(foundSectionByQuestion)) {
      this.editSectionSynthesis(foundSectionByQuestion.section, SynthesisType.AUCUNE);
    }
  }
}
