import {QuestionTypeEnum} from "../enums/questions.enum";


export class QuestionTypeUtil {

  static getQuestionTypeIcon(questionType: string): string {
    switch (questionType) {
      case QuestionTypeEnum.MULTIPLE_CHOICE:
        return 'check_box';
      case QuestionTypeEnum.UNIQUE_CHOICE:
        return 'radio_button_checked';
      case QuestionTypeEnum.EVAL:
        return 'trending_up';
      case QuestionTypeEnum.FREE_TEXT:
        return 'notes';
      case QuestionTypeEnum.MULTI:
        return 'list';
      case QuestionTypeEnum.RANK:
        return 'low_priority';
      default:
        return '';
    }
  }

  static getQuestionTypeTooltip(questionType: string): string {
    switch (questionType) {
      case QuestionTypeEnum.MULTIPLE_CHOICE:
        return 'Choix multiples';
      case QuestionTypeEnum.UNIQUE_CHOICE:
        return 'Choix unique';
      case QuestionTypeEnum.EVAL:
        return 'Évaluation';
      case QuestionTypeEnum.FREE_TEXT:
        return 'Texte libre';
      case QuestionTypeEnum.MULTI:
        return 'Multi-champs';
      case QuestionTypeEnum.RANK:
        return 'Classement';
      default:
        return '';
    }
  }

}
