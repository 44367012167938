import {Component, Input, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {QuestionReportFlag, QuestionTypeEnum} from "../../../../enums/questions.enum";
import {NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-text-report-step',
  standalone: true,
  imports: [
    MatIcon,
    ReactiveFormsModule,
    NgForOf,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgIf
  ],
  templateUrl: './text-report-step.component.html',
  styleUrl: './text-report-step.component.scss'
})
export class TextReportStepComponent implements OnInit {

  @Input() firstStepForm: FormGroup = new FormGroup({});
  @Input() secondStepForm: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  reportElementsArray: FormArray = new FormArray<any>([]);

  ngOnInit() {
    this.reportElementsArray = this.secondStepForm.get('reportElements') as FormArray;
    // todo if (this._editMode) this.initEditionForm();
  }

  addElement(flag: QuestionReportFlag) {
    this.reportElementsArray.push(this.createReportElementFormGroup(flag));
  }


  evalCounter(min: number, max: number): number[] {
    return Array(max + 1).fill(min).map((_, i) => i);
  }


  get reportElements() {
      return this.secondStepForm.get('reportElements') as FormArray;
  }

  get questionAnswers() {
    return this.firstStepForm.get('questionAnswers') as FormArray;
  }

  private createReportElementFormGroup(flag: QuestionReportFlag) {
    return new FormGroup({
      text: new FormControl('', Validators.required),
      flag: new FormControl(flag),
      answer: new FormControl(null, Validators.required)
    })
  }

  protected readonly QuestionReportFlag = QuestionReportFlag;
  protected readonly QuestionTypeEnum = QuestionTypeEnum;
}
