import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {
  Question,
  QuestionAnswer,
  QuestionPossibleAnswers,
  QuestionResponseModel
} from '../../../../../models/questions.model';
import {QuestionTypeEnum} from "../../../../../enums/questions.enum";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {UniqueMultipleSavedAnswerModel} from "../../../../../models/form.model";
import {QuestionTypeUtil} from "../../../../../utils/questions-type-utils";
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-unique-multiple-question-display',
  standalone: true,
  imports: [
    MatIcon,
    MatRadioGroup,
    MatRadioButton,
    FormsModule,
    MatCheckbox,
    ReactiveFormsModule,
    MatButton,
    MatTooltip,
    MatFormField,
    MatInput,
    MatHint,
    MatLabel
  ],
  templateUrl: './unique-multiple-question-display.component.html',
  styleUrl: './unique-multiple-question-display.component.scss'
})
export class UniqueMultipleQuestionDisplayComponent implements OnInit{

  @Input() question!: Question;
  @Input() showIcon: boolean = true;
  @Input() visualisationMode: boolean = false;
  @Input() savedValues: UniqueMultipleSavedAnswerModel = {choices: [], freeText: ''}; // TODO : to be reviewed when the backend will be ready for complete form
  @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionAnswer: EventEmitter<QuestionAnswer> = new EventEmitter<QuestionAnswer>();
  questionForm!: FormGroup;
  isNotApplicable: boolean = false;
  questionPossibleAnswerWithOutFreeText : QuestionPossibleAnswers[] = [];
  protected readonly QuestionTypeEnum = QuestionTypeEnum;
  protected readonly QuestionTypeUtil = QuestionTypeUtil;

  constructor() {
  }

  ngOnInit(): void {
    this.question.questionPossibleAnswers.sort((a, b) => {
      if (a.displayOrder === -1) return 1;
      if (b.displayOrder === -1) return -1;
      return a.displayOrder - b.displayOrder;
    });
    this.questionPossibleAnswerWithOutFreeText = this.question.questionPossibleAnswers.filter(pa => pa.displayOrder >= 0);
    this.createForm();
    this.visualisationMode ? this.questionForm.enable() : this.questionForm.disable();
  }

  extractTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  createForm() {
    const controlsConfig: { [key: string]: FormControl } = {
      freeText: new FormControl<string>('', [Validators.maxLength(256), Validators.minLength(1)])
    };
    if (this.question.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
      this.questionPossibleAnswerWithOutFreeText.forEach((option, index) => {
        const isSelected = this.savedValues.choices.includes(option.value);
        controlsConfig[`checkbox${index}`] = new FormControl(isSelected);
      });
    }
    if (this.question.type === QuestionTypeEnum.UNIQUE_CHOICE) {
      const isChosen = this.savedValues.choices.length > 0;
      controlsConfig["radio"] = new FormControl(isChosen);
    }
    this.questionForm = new FormGroup(controlsConfig);

    this.questionForm.get('freeText')?.valueChanges
    .pipe(debounceTime(1000)) // Set the debounce time to 300ms (or adjust as needed)
    .subscribe(value => {
      if (value !== undefined && value !== null && value != "") {
        this.onFormChanges();
      }
    });
  }

  deleteSelectedQuestion(id: number) {
    this.deleteQuestion.emit(id);
  }

  onFormChanges() {
    let responses : QuestionResponseModel[] = [];

    if(this.isNotApplicable) {
      responses.push({displayOrder: -2, value: 'N/A'});
    } else {
      this.getFormResponse().freeText.trim().length > 0 ? responses.push({displayOrder: -1, value: this.getFormResponse().freeText}) : null;
      this.getFormResponse().choices.forEach((choice, index) => {
        responses.push({displayOrder: 0, value: choice});
      });
    }

    const questionAnswer : QuestionAnswer = {
      questionId: this.question.id,
      response: responses
    }



    this.questionAnswer.emit(questionAnswer);
  }

  getFormResponse(): UniqueMultipleSavedAnswerModel {
    let selectedValues : string[] = [];

    if (this.question.type === QuestionTypeEnum.UNIQUE_CHOICE) {
      selectedValues.push(this.questionForm.get('radio')?.value as string);
    }

    if (this.question.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
      selectedValues = this.questionPossibleAnswerWithOutFreeText.reduce((selected, option, index) => {
        const control = this.questionForm.get(`checkbox${index}`);
        if (control?.value) {
          selected.push(option.value);
        }
        return selected;
      }, [] as string[]);
    }

    return {
      choices: selectedValues,
      freeText: (this.questionForm.get('freeText')?.value as string).trim() || ''
    };
  }


  setResponseNonApplicable() {
    this.isNotApplicable = !this.isNotApplicable;
    if (this.question.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
      this.questionPossibleAnswerWithOutFreeText.forEach((option, index) => {
        this.isNotApplicable ? this.questionForm.get(`checkbox${index}`)?.disable() : this.questionForm.get(`checkbox${index}`)?.enable();
      });
    }
    if (this.question.type === QuestionTypeEnum.UNIQUE_CHOICE) {
      this.isNotApplicable ? this.questionForm.get('radio')?.disable() : this.questionForm.get('radio')?.enable();
    }
    this.isNotApplicable ? this.questionForm.get('freeText')?.disable() : this.questionForm.get('freeText')?.enable();
    this.onFormChanges();
  }
}
