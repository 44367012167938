import {Pipe, PipeTransform} from '@angular/core';
import {QuestionStatus} from '../enums/questions.enum';

@Pipe({
  standalone: true,
  name: 'qStatusTr'
})
export class QuestionsStatusTrPipe implements PipeTransform {

  statusMap: Record<keyof typeof QuestionStatus, string> = {
    DRAFT: QuestionStatus.DRAFT,
    INACTIVE: QuestionStatus.INACTIVE,
    ACTIVE: QuestionStatus.ACTIVE,
    ARCHIVE: QuestionStatus.ARCHIVE
  };

  transform(value: keyof typeof QuestionStatus): string {
    return this.statusMap[value] || value;
  }
}
