.popup {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "TradeGothic", sans-serif;
}

.popup-header {
  text-align: center;
}

.popup-header p:first-child {
  font-size: 1.58rem;
  font-weight: 900;
  color: #ff9800;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-family: "TradeGothicBold", sans-serif;
}

.popup-header p:not(:first-child) {
  font-size: 0.875rem;
  font-style: italic;
  color: #a6a6a6;
}
.popup-body {
  border-radius: 3px;
  height: 70%;
  width: 100%;
  margin-top: 1rem;
}
.popup-body textarea {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: transparent;
  resize: none;
  outline: none;
  border: none;
  font-size: 1rem;
  color: black;
}
.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.popup-footer button {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  position: relative;
  font-family: "TradeGothicBold", sans-serif;
  padding: 0.5rem 2rem 0.3rem;
}
.popup-footer button span {
  font-size: 1rem;
  font-weight: 900;
}

.cartoon-button {
  background-color: #ff9800;
  transition: box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 7px 5px black;
  }
}

.disable-cartoon-button {
  background-color: #535353;
}

:host ::ng-deep {
  .ql-toolbar.ql-snow {
    border-bottom: 0 ;
  }
}

/*SVGBackgrounds.com*/
.pattern-decreasing-circles {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='981' height='981' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%231D272D' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23A1DAF8'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
}




