<div [formGroup]="firstStepFormGroup">
  <section class="flex justify-between w-full items-center my-5">
    <h4>Type de choix : </h4>
    <div>
      <mat-button-toggle-group
        [formControlName]="'subType'"
        class="!shadow-md !rounded-3xl"
        [value]="QuestionTypeEnum.UNIQUE_CHOICE"
      >
        <mat-button-toggle [value]="QuestionTypeEnum.UNIQUE_CHOICE">Unique</mat-button-toggle>
        <mat-button-toggle [value]="QuestionTypeEnum.MULTIPLE_CHOICE">Multiple</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </section>

  <mat-divider></mat-divider>

  <div [formArrayName]="'questionAnswers'" class="w-full flex flex-col gap-3 mt-3">
    @for (answer of answerArray.controls; track answer; let index = $index) {
      <div [formGroupName]="index" class="flex justify-between items-center gap-5">
        @if (answer.get('displayOrder')?.value !== -1 ) {
          <div class="flex gap-2 items-center h-6">
            <div class="font-bold">
              {{questionAnswers.controls[0].get('displayOrder')?.value === -1 ? index : index + 1}}
            </div>
            <div class="h-6">
              <mat-icon>{{firstStepFormGroup.get('subType')?.value === QuestionTypeEnum.UNIQUE_CHOICE ? 'radio_button_unchecked' : 'check_box_outline_blank'}}</mat-icon>
            </div>
          </div>
          <mat-form-field appearance="outline" class="ixp-mat-form-field">
            <input matInput type="text" [formControlName]="'value'" [placeholder]="'Écrivez un choix'">
          </mat-form-field>
          <button (click)="this.answerArray.removeAt(index)" [disabled]="answerArray.length === 1" [class]="answerArray.length === 1 ? 'cursor-not-allowed' : 'cursor-pointer' ">
            <mat-icon [class]="answerArray.length === 1 ? 'opacity-60' : ''">{{answerArray.length === 1 ?  "delete_forever" : "delete" }}</mat-icon>
          </button>
        }
      </div>
    }

    @if (freeAnswer) {
      <div [formGroupName]="indexOfFreeAnswer()" class="flex justify-between items-center gap-6">
        <mat-form-field appearance="outline" class="ixp-mat-form-field">
          <input matInput type="text" [formControlName]="'value'" [placeholder]="'Réponse libre'">
        </mat-form-field>
        <button (click)="answerArray.removeAt(indexOfFreeAnswer()); freeAnswer = !freeAnswer"><mat-icon>delete</mat-icon></button>
      </div>
    }
  </div>
  <p class="flex mt-5 h-5 gap-1">
    <mat-icon class="w-5 h-5">add</mat-icon> ajouter une
    <button (click)="addElement()" class="underline">réponse possible</button> <span *ngIf="!freeAnswer">ou</span>
    <button (click)="addFreeTextElement()" class="underline" *ngIf="!freeAnswer">un champ libre</button></p>
</div>
