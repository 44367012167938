<form [formGroup]="firstStepFormGroup" class="h-full mt-5">
  <section class="flex flex-col items-center w-full">
  <section class="w-3/5">

    <mat-form-field class="w-full mt-5" appearance="outline">
      <mat-label>Titre</mat-label>
      <input matInput type="text" [formControlName]="'name'" placeholder="Titre du formulaire">
    </mat-form-field>


    <mat-form-field  class="w-full" appearance="outline">
      <mat-label>Catégorie de formulaire</mat-label>
      <mat-select [formControlName]="'category'" [(value)]="selectedCategory">
        <mat-option *ngFor="let category of getEnumKeys()"
                    [value]="category">
          {{ category | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Choisissez vos tags</mat-label>
      <mat-select [formControlName]="'tagsList'"
                  [compareWith]="compareTags"
                  multiple>
        <mat-select-trigger>
          {{tagsListFormGroup && tagsListFormGroup.value && tagsListFormGroup.value[0] && tagsListFormGroup.value[0].title || ''}}
          @if ((tagsListFormGroup.value && tagsListFormGroup.value.length || 0) > 1) {
            <span class="opacity-75 text-xs">
          (+{{((tagsListFormGroup.value && tagsListFormGroup.value.length) || 0) - 1}} {{(tagsListFormGroup.value && tagsListFormGroup.value.length === 2) ? 'autre' : 'autres'}})
        </span>
          }
        </mat-select-trigger>

        @if (tagsList) {
          @for (tag of tagsList; track tag.id) {
            <mat-option [value]="tag">{{tag.title}}</mat-option>
          }
        }

      </mat-select>
    </mat-form-field>

  </section>
</section>
</form>
