import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {finalize, Observable, of, tap} from 'rxjs';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatDivider} from '@angular/material/divider';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {AnswerFormatTypeEnum, QuestionTypeEnum} from '../../../../../enums/questions.enum';

@Component({
  selector: 'app-unique-multiple-choice-answer',
  standalone: true,
  imports: [
    MatButtonToggle,
    MatButtonToggleGroup,
    MatDivider,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    NgIf,
  ],
  templateUrl: './unique-multiple-choice-answer.component.html',
  styleUrl: './unique-multiple-choice-answer.component.scss'
})
export class UniqueMultipleChoiceAnswerComponent implements OnInit {

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  _loading: boolean = false;
  questionAnswers: FormArray = new FormArray<any>([]);
  freeAnswer: boolean = false;

  ngOnInit() {
    this._switchLoading().pipe(
      tap(() => this.initForm()),
      finalize(() => this._switchLoading())
    ).subscribe()
  }

  initForm() {
    this.questionAnswers = this.firstStepFormGroup.get('questionAnswers') as FormArray;

    if (!this._editMode) {
      this.questionAnswers.push(this.createFormGroupAnswer(0));
    } else {
      this.freeAnswer = !!this.questionAnswers.controls.find(v =>  v.get('displayOrder')?.value === -1);
      this.questionAnswers.controls.sort((a, b) => a.get('displayOrder')?.value - b.get('displayOrder')?.value)
    }
  }

  addElement() {
    this.questionAnswers.push(this.createFormGroupAnswer(this.questionAnswers.length));
  }

  addFreeTextElement() {
    this.freeAnswer = !this.freeAnswer;
    this.questionAnswers.insert(0, this.createFormGroupAnswer(-1, AnswerFormatTypeEnum.TEXT))
  }

  indexOfFreeAnswer() {
    return this.questionAnswers.controls.findIndex(v => v.get('displayOrder')?.value === -1);
  }

  get answerArray() {
    return this.firstStepFormGroup.get('questionAnswers') as FormArray
  }

  private createFormGroupAnswer(order: number, formatType?: AnswerFormatTypeEnum): FormGroup {
    return new FormGroup({
      displayOrder: new FormControl(order),
      value: new FormControl(''),
      formatType: new FormControl(formatType ? formatType : null),
      min: new FormControl(null),
      max: new FormControl(null),
    });
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  protected readonly QuestionTypeEnum = QuestionTypeEnum;
}
