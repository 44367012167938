<div class="mb-5">
  <div class="flex items-center justify-between bg-green-400/15 text-green-500 pr-5 pl-2 py-2.5 rounded-md">
    <h4 class="flex items-center text-lg font-bold">
      <mat-icon>arrow_right_alt</mat-icon>
      Les points d'appuis
    </h4>
    <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">check_circle</mat-icon>
  </div>

  @for (supportPoint of supportPoints; track $index) {
    <div class="flex items-center justify-between pr-5 pl-5 py-2.5 rounded-md">
      <h4 class="flex items-center text-base font-bold">
        <mat-icon>arrow_right_alt</mat-icon>
        {{ supportPoint.text }}
      </h4>
    </div>
  }
</div>

<div class="mb-5">
  <div class="flex items-center justify-between bg-orange-400/10 text-orange-400 pr-5 pl-2 py-2.5 rounded-md">
    <h4 class="flex items-center text-lg font-bold">
      <mat-icon>arrow_right_alt</mat-icon>
      Les points à surveiller
    </h4>
    <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">error</mat-icon>
  </div>


  @for (warningPoint of warningPoints; track $index) {
    <div class="flex items-center justify-between pr-5 pl-5 py-2.5 rounded-md">
      <h4 class="flex items-center text-base font-bold">
        <mat-icon>arrow_right_alt</mat-icon>
        {{ warningPoint.text }}
      </h4>
    </div>
  }
</div>

<div>
  <div class="flex items-center justify-between bg-red-400/10 text-red-400 pr-5 pl-2 py-2.5 rounded-md">
    <h4 class="flex items-center text-lg font-bold">
      <mat-icon>arrow_right_alt</mat-icon>
      Les points critique
    </h4>
    <mat-icon class="!text-[22px] !h-[22px] !w-[22px]">warning</mat-icon>
  </div>


  @for (criticalPoint of criticalPoints; track $index) {
    <div class="flex items-center justify-between pr-5 pl-5 py-2.5 rounded-md">
      <h4 class="flex items-center text-base font-bold">
        <mat-icon>arrow_right_alt</mat-icon>
        {{ criticalPoint.text }}
      </h4>
    </div>
  }
</div>
