/* src/app/components/forms-dashboard/create-form/form-summary/form-summary.component.scss */
.summary-container {
  width: 100%;
  margin: 0;
  display: inline-block;
  vertical-align: top;
}
.summary-list {
  border: solid 1px #ccc;
  min-height: 60px;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}
.summary-box {
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  gap: 10px;
  font-size: 14px;
  width: 100%;
}
.sub-summary-box {
  padding: 5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: move;
  font-size: 14px;
  overflow: hidden;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.example-box:last-child {
  border: none;
}
.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
mat-expansion-panel {
  margin: 0;
  width: 100%;
}
mat-expansion-panel-header {
  padding: 0 12px;
}
/*# sourceMappingURL=form-summary.component.css.map */
