import {Component, Input, OnInit} from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIcon} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NotificationService} from "../../../../services/front/notifications.service";
import {NotificationsStatus} from "../../../../enums/notifications-status";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Question, QuestionSectionOrganized} from "../../../../models/questions.model";
import {Form, FormComponentsType, Section, SynthesisType} from "../../../../models/form.model";
import {SectionOrganizerService} from "../../../../services/front/section-organizer.service";
import {MatButton} from "@angular/material/button";
import {SectionService} from "../../../../services/back/section.service";
import {LoaderComponent} from "../../../shared/loader/loader.component";


@Component({
  selector: 'app-form-summary',
  standalone: true,
  imports: [
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    MatExpansionModule,
    MatIcon,
    CommonModule,
    MatFormFieldModule,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    LoaderComponent
  ],
  templateUrl: './form-summary.component.html',
  styleUrl: './form-summary.component.scss'
})

export class FormSummaryComponent implements OnInit {

  @Input() thirdStepFormGroup: FormGroup | null = null;
  @Input() form: Form | null = null;
  @Input() isSummaryBlockedForVisualisation: boolean = false;
  editSectionGroupForm : FormGroup = new FormGroup({});
  addSectionControl : FormControl = new FormControl('');
  allSections: QuestionSectionOrganized[] = [];
  isEditModeForSectionName: boolean = false;
  isLoading: boolean = false;


  constructor(private notificationService : NotificationService,
              private sectionOrganizerService : SectionOrganizerService,
              private sectionService: SectionService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.editSectionGroupForm = this.formBuilder.group({
      sectionName: new FormControl<string | null>(null),
      sectionIndex: new FormControl<number | null>(null)
    });
    this.sectionOrganizerService.questionListForm$.subscribe(value => {
      this.allSections = this.getQuestionsList();
    });
  }

  dropSection(event: CdkDragDrop<QuestionSectionOrganized[]>) {
    if(this.isLoading || this.isSummaryBlockedForVisualisation) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(this.allSections, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.setQuestionsList(this.allSections);
  }

  dropSubSection(event: CdkDragDrop<Question[]>, sectionIndex: number) {
    if(this.isLoading || this.isSummaryBlockedForVisualisation) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(this.allSections[sectionIndex].questions, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.setQuestionsList(this.allSections);
  }

  trackByFn(index: number): number {
    return index;
  }

  get connectedSubLists() {
    return this.allSections.map((_, index) => `subSectionList${index}`);
  }

  addSection(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const value = (this.addSectionControl.value as string).trim();
      if(!this.checkIfLengthAndNotExistence(value)) {
        return;
      }
      const newSection : Section = {
        name: value,
        formsComponentsType: FormComponentsType.SECTION,
        synthesisType: SynthesisType.AUCUNE
      }

      this.isLoading = true;
      this.disableFromControls();
      this.sectionService.addSection(newSection).subscribe(section => {
        this.allSections = this.getQuestionsList();
        this.allSections.push({
          section: section,
          questions: []
        });
        this.addSectionControl.setValue('');
        this.setQuestionsList(this.allSections);
        this.activeFromControls();
        this.isLoading = false;
      });
    }
  }

  editSectionName(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const index = this.editSectionGroupForm.get('sectionIndex')?.value;
      const value = (this.editSectionGroupForm.get('sectionName')?.value as string).trim();
      if(!this.checkIfLengthAndNotExistence(value)) {
        return;
      }
      this.allSections = this.getQuestionsList();
      const selectedSection: Section = {
        id: this.allSections[index].section.id!,
        name: value,
        formsComponentsType: FormComponentsType.SECTION,
        synthesisType: this.allSections[index].section.synthesisType
      };
      this.isLoading = true;
      this.disableFromControls();
      this.sectionService.updateSection(selectedSection).subscribe(section => {
        this.allSections[index].section = section;
        this.editSectionGroupForm.get('sectionName')?.setValue('');
        this.setQuestionsList(this.allSections);
        this.activeFromControls();
        this.isEditModeForSectionName = false;
        this.isLoading = false;
      });
    }
  }

  setQuestionsList(allSections: QuestionSectionOrganized[]) {
    if(this.thirdStepFormGroup) {
      this.thirdStepFormGroup.get('formsComponentsList')?.setValue(this.sectionOrganizerService.flattenSectionsAndQuestions(allSections))
    } else if (this.form) {
      this.form.formsComponentsList = this.sectionOrganizerService.flattenSectionsAndQuestions(allSections);
    }
  }

  getQuestionsList() {
    if (this.thirdStepFormGroup) {
      return this.sectionOrganizerService.organizeSectionsAndQuestions(this.thirdStepFormGroup?.get('formsComponentsList')?.value);
    }
    return this.sectionOrganizerService.organizeSectionsAndQuestions(this.form?.formsComponentsList || []);
  }

  showEditSectionName(element : QuestionSectionOrganized, index : number) {
    if(this.isLoading) {
      return;
    }
    this.editSectionGroupForm.get('sectionName')?.setValue(element.section.name);
    this.editSectionGroupForm.get('sectionIndex')?.setValue(index);
    this.isEditModeForSectionName = true;
  }

  deleteSection(index: number) {
    if(this.isLoading) {
      return;
    }
    if(this.allSections[index].questions.length > 0) {
      this.notificationService.showNotification(NotificationsStatus.ERROR_DELETING_SECTION);
      return;
    }
    const sectionId = this.allSections[index].section.id!;
    this.isLoading = true;
    this.sectionService.deleteSection(sectionId).subscribe(() => {
      this.allSections.splice(index, 1);
      this.setQuestionsList(this.allSections);
      this.isLoading = false;
    });
  }

  cancelEditingSectionName() {
    this.editSectionGroupForm.get('sectionName')?.setValue(null);
    this.editSectionGroupForm.get('sectionIndex')?.setValue(null);
    this.isEditModeForSectionName = false;
  }

  checkIfLengthAndNotExistence(value: string) {
    if(value.length < 1) {
      this.notificationService.showNotification(NotificationsStatus.ERROR_SECTION_NAME_LENGTH);
      return false;
    }
    const isAlreadyExist = this.allSections.find(section => section.section.name === value);
    if(isAlreadyExist) {
      this.notificationService.showNotification(NotificationsStatus.ERROR_SECTION_ALREADY_EXIST);
      return false;
    }
    return true;
  }

  disableFromControls() {
    this.addSectionControl.disable();
    this.editSectionGroupForm.get('sectionName')?.disable();
  }

  activeFromControls() {
    this.addSectionControl.enable();
    this.editSectionGroupForm.get('sectionName')?.enable();
  }

}

