import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatDivider} from '@angular/material/divider';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {finalize, Observable, of, tap} from 'rxjs';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatOption, MatSelect} from '@angular/material/select';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-evaluation-answer',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatDivider,
    MatFormField,
    MatInput,
    MatSlideToggle,
    MatLabel,
    MatSelect,
    MatOption,
    NgForOf,
    FormsModule,
    NgIf
  ],
  templateUrl: './evaluation-answer.component.html',
  styleUrl: './evaluation-answer.component.scss'
})
export class EvaluationAnswerComponent implements OnInit {

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  MAX_ARRAY_LENGTH: number = 9;
  _loading: boolean = false;

  questionAnswers: FormArray = new FormArray<any>([]);

  ngOnInit() {
    this._switchLoading().pipe(
      tap(() => this._editMode ? this.initEditForm() : this.initForm()),
      finalize(() => this._switchLoading())
    ).subscribe()
  }

  initForm() {
    this.questionAnswers = this.firstStepFormGroup.get('questionAnswers') as FormArray;
    this.questionAnswers.push(this.createFormGroupAnswer());
    this.initEditionLabels();
  }

  initEditForm() {
    this.questionAnswers = this.firstStepFormGroup.get('questionAnswers') as FormArray;
    const questionValue: string[] = this.questionAnswers.controls[0].get('value')?.value.split(';');
    const groupToUpdate = this.questionAnswers.controls[0] as FormGroup;

    groupToUpdate.addControl('leftLabel', new FormControl(questionValue[0]));
    groupToUpdate.addControl('rightLabel', new FormControl(questionValue[1]));
    this.initEditionLabels();
  }

  initEditionLabels() {
    this.questionAnswers.controls[0].get('leftLabel')?.valueChanges.subscribe(val => {
      this.questionAnswers.controls[0].get('value')?.setValue(`${val};${this.questionAnswers.controls[0].get('rightLabel')?.value}`)
    });
    this.questionAnswers.controls[0].get('rightLabel')?.valueChanges.subscribe(val => {
      this.questionAnswers.controls[0].get('value')?.setValue(`${this.questionAnswers.controls[0].get('leftLabel')?.value};${val}`)
    })
  }

  generateNumbers(): number[] {
    return Array.from({ length: this.MAX_ARRAY_LENGTH }, (_, i) => i + 2);
  }

  get answerArray() {
    return this.firstStepFormGroup.get('questionAnswers') as FormArray
  }

  private createFormGroupAnswer(): FormGroup {
    return new FormGroup({
      value: new FormControl(''),
      formatType: new FormControl(''),
      min: new FormControl(null, Validators.required),
      max: new FormControl(null, Validators.required),
      rightLabel: new FormControl(''),
      leftLabel: new FormControl('')
    })
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

}
