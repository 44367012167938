import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgStyle} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    MatIcon,
    NgStyle
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() routerLink: string | undefined;
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() isIconOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() color: string = 'black';
  @Input() appearance: 'outline' | 'filled' = 'outline';
  @Output() buttonClick = new EventEmitter<void>();

  get classes(): string[] {
    const baseClasses = ['flex', 'items-center', 'justify-between', 'py-2', 'px-4', 'rounded', 'text-md', 'gap-2', 'h-12'];
    if (this.appearance === 'outline') {
      baseClasses.push('bg-white', 'border-2');
    } else {
      baseClasses.push('text-white');
    }
    if (this.disabled) {
      baseClasses.push('opacity-50', 'cursor-not-allowed');
    }
    return baseClasses;
  }

  get styles(): { [key: string]: string } | {} {
    const colorVariable = `var(--${this.color})`;
    if (this.disabled) {
      return { 'background-color': 'gray', 'color': 'darkgray', 'border-color': 'darkgray' };
    } else if (this.appearance === 'filled') {
      return { 'background-color': colorVariable };
    } else if (this.appearance === 'outline') {
      return { 'color': colorVariable, 'border-color': colorVariable };
    }
    return {};
  }

  handleClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }
}

/* How you can use it in your templates:

<app-button
  [routerLink]="PATHS.CLIENTS.IMPORT"
  icon="add"
  label="Importer une base de client"
  color="azur"
  appearance="filled"
></app-button>


<app-button
  (buttonClick)="switchIsMenuActivate()"
  icon="power_settings_new"
  label="Déconnexion"
  color="nuit"
  appearance="outline"
></app-button>


* */
