import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {AnswerFormatTypeEnum} from "../../../../../enums/questions.enum";
import {Question, QuestionAnswer, QuestionResponseModel} from "../../../../../models/questions.model";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatFormField, MatFormFieldModule, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from "@angular/material/core";
import {QuestionTypeUtil} from "../../../../../utils/questions-type-utils";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-multi-question-display',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatSuffix,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckbox,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    provideNativeDateAdapter(),
  ],
  templateUrl: './multi-question-display.component.html',
  styleUrl: './multi-question-display.component.scss'
})
export class MultiQuestionDisplayComponent implements OnInit{


  @Input() question!: Question;
  @Input()  showIcon: boolean = true;
  @Input() visualisationMode: boolean = false;
  @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionAnswer: EventEmitter<QuestionAnswer> = new EventEmitter<QuestionAnswer>();
  protected readonly AnswerFormatTypeEnum = AnswerFormatTypeEnum;
  protected readonly QuestionTypeUtil = QuestionTypeUtil;
  questionAnswerForm!: FormGroup;
  isNotApplicable: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.createForm();
    this.visualisationMode ? this.questionAnswerForm.enable() : this.questionAnswerForm.disable();
  }

  extractTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  deleteSelectedQuestion(id: number) {
    this.deleteQuestion.emit(id);
  }

  createForm() {
    const controlsConfig: { [key: string]: FormControl } = {};


    this.question.questionPossibleAnswers.sort((a,b)=> a.displayOrder - b.displayOrder).forEach((possibleAnswer, index) => {
      if(possibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
        const textControl = new FormControl('', [Validators.maxLength(256), Validators.minLength(1)]);
        controlsConfig['answerText' + index] = textControl;
        textControl.valueChanges.pipe(debounceTime(1000))
        .subscribe(value => {
          if (value !== undefined && value !== null && value != "") {
            this.onFormChanges();
          }
        });
      }
      if (possibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
        controlsConfig['answerDate' + index] = new FormControl('',[Validators.required]);
      }
      if(possibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
        controlsConfig['answerNumber' + index] = new FormControl('', [Validators.min(possibleAnswer.min), Validators.max(possibleAnswer.max)]);
      }
      if (possibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
        const mailControl = new FormControl('', [Validators.email]);
        controlsConfig['answerMail' + index] = mailControl
        mailControl.valueChanges.pipe(debounceTime(1000))
        .subscribe(value => {
          if (value !== undefined && value !== null && value != "") {
            this.onFormChanges();
          }
        });
      }
    });

    this.questionAnswerForm = new FormGroup(controlsConfig);
  }


  onFormChanges() {
    let responses : QuestionResponseModel[] = [];
    if(this.isNotApplicable) {
      this.setValueForNotApplicable();
      responses.push({displayOrder: -2, value: 'N/A'});
    } else {
      let value = '';
      this.question.questionPossibleAnswers.forEach((possibleAnswer, index) => {
        if(possibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
          value = this.questionAnswerForm.get('answerText' + index)?.value;
        }
        if (possibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
          value = (this.questionAnswerForm.get('answerDate' + index)?.value as Date) ? (this.questionAnswerForm.get('answerDate' + index)?.value as Date).toISOString() : '';
        }
        if(possibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
          value = this.questionAnswerForm.get('answerNumber' + index)?.value;
        }
        if (possibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
          value = this.questionAnswerForm.get('answerMail' + index)?.value;
        }
        if (value === '') return;
        responses.push({displayOrder: index, value: value});
      });
    }
    const questionAnswer : QuestionAnswer = {
      questionId: this.question.id,
      response: responses
    }

    this.questionAnswer.emit(questionAnswer);
  }

  setResponseNonApplicable() {
    this.isNotApplicable = !this.isNotApplicable;
    if(this.isNotApplicable) {
      this.questionAnswerForm.disable();
    } else {
      this.questionAnswerForm.reset();
      this.questionAnswerForm.enable();
    }
    this.onFormChanges();
  }

  setValueForNotApplicable(){
    this.question.questionPossibleAnswers.forEach((possibleAnswer, index) => {
      if(possibleAnswer.formatType === AnswerFormatTypeEnum.TEXT) {
        this.questionAnswerForm.get('answerText' + index)?.setValue('N/A');
      }
      if (possibleAnswer.formatType === AnswerFormatTypeEnum.DATE) {
        this.questionAnswerForm.get('answerDate' + index)?.setValue('N/A');
      }
      if(possibleAnswer.formatType === AnswerFormatTypeEnum.NUMBER) {
        this.questionAnswerForm.get('answerNumber' + index)?.setValue('N/A');
      }
      if (possibleAnswer.formatType === AnswerFormatTypeEnum.EMAIL) {
        this.questionAnswerForm.get('answerMail' + index)?.setValue('N/A');
      }
    });
  }
}
