export enum QuestionTypeEnum {
  CHOICES = 'CHOICES',
  UNIQUE_CHOICE = 'UNIQUE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  FREE_TEXT = 'FREE_TEXT',
  MULTI = 'MULTI',
  EVAL = 'EVAL',
  RANK = 'RANK'
}

export enum AnswerFormatTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date'
}

export enum QuestionStatus {
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export enum QuestionReportFlag {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED'
}
