<div [formGroup]="firstStepFormGroup" *ngIf="!_loading">
  <div [formArrayName]="'questionAnswers'" class="w-full flex flex-col gap-3 mt-3">
    @for (answer of answerArray.controls; track $index) {
      <div class="flex flex-col gap-5 my-3 shadow-lg rounded-md p-3" [formGroupName]="$index">
        <div>
          <h4 class="font-bold">Champ {{$index + 1}}</h4>
        </div>
        <div class="flex justify-between items-center gap-5">
          <div class="w-full">
            <mat-form-field class="w-full -mb-5">
              <mat-label>Intitulé du champs</mat-label>
              <input matInput type="texte" [formControlName]="'value'" placeholder="Intitulé du champs">
            </mat-form-field>
          </div>

          <div>
            <button (click)="answerArray.removeAt($index)"
                    [disabled]="answerArray.length === 1"
                    [class]="answerArray.length === 1 ? 'cursor-not-allowed' : 'cursor-pointer' ">
              <mat-icon [class]="answerArray.length === 1 ? 'opacity-60' : ''">
                {{answerArray.length === 1 ?  "delete_forever" : "delete" }}
              </mat-icon>
            </button>
          </div>

        </div>

        @if (answer.get('formatType')?.value !== AnswerFormatTypeEnum.DATE) {
          <div>
            <h4>Nombre de caractères</h4>
            <section class="flex justify-between gap-5 w-full">
              <mat-form-field class="w-1/2 -mb-5">
                <mat-label>Min (Par défaut: 0)</mat-label>
                <input matInput type="number" [formControlName]="'min'" placeholder="Min (Par défaut: 0)">
              </mat-form-field>
              <mat-form-field class="w-1/2 -mb-5">
                <mat-label>Max (Par défaut: 255)</mat-label>
                <input matInput type="number" [formControlName]="'max'" placeholder="Max (Par défaut: 255)">
              </mat-form-field>
            </section>
          </div>
        }


        <div class="flex justify-between items-center">

          <h4>Type de saisie :</h4>
          <div>
            <mat-button-toggle-group
              [formControlName]="'formatType'"
              class="!shadow-md !rounded-3xl"
              [value]="AnswerFormatTypeEnum.TEXT"
            >
              <mat-button-toggle [value]="AnswerFormatTypeEnum.TEXT">Libre</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.DATE">Date</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.EMAIL">E-mail</mat-button-toggle>
              <mat-button-toggle [value]="AnswerFormatTypeEnum.NUMBER">Numérique</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    }
  </div>
  <button (click)="addElement()" class="underline mt-5 h-5 flex"><mat-icon class="w-5 h-5">add</mat-icon> Ajouter un nouveau champ</button>
</div>
