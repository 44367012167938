import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Question, QuestionAnswer, QuestionGraphicalSynthesis} from "../../../../../models/questions.model";
import {Chart, registerables} from "chart.js";
import {Observable} from "rxjs";
import {LoaderComponent} from "../../../loader/loader.component";

@Component({
  selector: 'app-graphical-synthesis',
  standalone: true,
  imports: [
    LoaderComponent
  ],
  templateUrl: './graphical-synthesis.component.html',
  styleUrl: './graphical-synthesis.component.scss'
})
export class GraphicalSynthesisComponent implements OnInit, AfterViewInit {

  @Input() question!: QuestionGraphicalSynthesis;

  chart!: Chart;

  isReady: boolean = false;

  chartId!: string;

  constructor() {
    Chart.register(...registerables); // Enregistrement des composants nécessaires
    this.chartId = 'chart-' + Math.floor(Math.random() * 1000000);
  }

  ngOnInit(): void {
    this.isReady = false;
  }

  ngAfterViewInit() {
    this.constructDatasForChartJs()
      .subscribe(() => {
        this.isReady = true;
      })
  }

  private constructDatasForChartJs(): Observable<void> {
    return new Observable<void>((observer) => {

      this.constructChartJs(this.question.questionAnswer, this.question.question)
      // Émettre la valeur de fin de traitement
      observer.next();
      observer.complete();
    });
  }

  private constructChartJs(questionAnswer: QuestionAnswer, question: Question) {
    this.chart = new Chart(this.chartId, {
      type: 'bar',
      data: {
        labels: questionAnswer.response.map(res => res.value),
        datasets: [
          {
            label: 'Votre réponse',
            data: questionAnswer.response.map(res => res.value).map(value => Number(value)), // Tes données ici
            borderWidth: 1,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            min: question.questionPossibleAnswers.map(qpa => qpa.min).at(0),
            max: question.questionPossibleAnswers.map(qpa => qpa.max).at(0), // Ajuste cette valeur selon tes données
            ticks: {
              stepSize: 1, // Ajuste l'incrément des ticks
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: question.question,
          },
        },
      },
    });
  }
}
