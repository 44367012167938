import {Injectable, signal, WritableSignal} from '@angular/core';
import {NotificationsStatus} from "../../enums/notifications-status";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationSignal: WritableSignal<boolean> = signal(false);
  status: NotificationsStatus | undefined;

  level: Level = Level.INFO;
  message: string = '';
  private isLibraryDisplayedValue = new BehaviorSubject<boolean>(false);
  _isLibraryDisplayed: Observable<boolean>;

  constructor() {
    this._isLibraryDisplayed = this.isLibraryDisplayedValue.asObservable();
  }

  setIsLibraryDisplayed(value: boolean) {
    this.isLibraryDisplayedValue.next(value);
  }

  showNotification(actualStatus: NotificationsStatus):void {
    this.status = actualStatus;
    this.notificationSignal.set(true);
    setTimeout(() => {
      this.notificationSignal.set(false);
    }, 5000);
  }

  sendNotification(level: Level, message: string, timeout: number = 3000) {
    this.message = message;
    this.level = level
    this.status = undefined;

    this.notificationSignal.set(true);
    setTimeout(() => {
      this.notificationSignal.set(false);
    }, timeout);
  }

}

export enum Level {
  ERROR = 'Erreur',
  WARN = 'Attention',
  INFO = 'Information',
  SUCCESS = 'Succès',
}
