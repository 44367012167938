<section class="flex flex-col w-full h-full md:px-8 md:py-5 py-2">
  <div class="flex justify-between items-center">
    <div>
      <h3 class="font-bold text-xl md:text-2xl mt-2">Tags</h3>
    </div>
  </div>

  @if(!this.subscription.closed) {
    <app-loader></app-loader>
  } @else {
    @if (tagsList.length>= this.tagListMax) {
      <div class="h-28 mt-8 mb-1 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
        La limite de tags est de {{this.tagListMax}}. Merci d'en supprimer {{(this.tagsList.length - this.tagListMax) + 1}} pour en créer un nouveau.
      </div>
    } @else {
      <div class="mt-8 mb-5 w-full flex flex-col items-center justify-between h-28">
        <mat-form-field appearance="outline">
          <mat-label>Ajoutez un tag!</mat-label>
          <input
            matInput
            [formControl]="tagInput"
            (keydown.enter)="addTag()"
          />
          @if (this.tagInput.errors?.['onlyLetters']) {
            <mat-error class="italic text-xs mt-2">Seules les lettres sont autorisées.</mat-error>
          } @else if (this.tagInput.errors?.['maxlength']) {
            <mat-error class="italic text-xs mt-2">{{this.tagLengthMax}} caractères maximum</mat-error>
          }
        </mat-form-field>

        <div class="flex justify-center items-end">
          <mat-icon class="text-red-500 mr-1">@if(this.errorMessage) {error}</mat-icon>
          <div class="text-red-500 text-sm truncate">{{errorMessage}}</div>
        </div>
      </div>
    }

      @if(this.subscription.closed && this.tagsList.length === 0) {
        <div class="p-32 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
          Aucun tag enregistré
        </div>
      } @else {
        <div class="flex items-center justify-center">
          <div class="flex flex-wrap w-10/12 justify-center">
            @for (tag of tagsList; track $index) {
              <div class="m-2 p-6 flex justify-center bg-blue-100 rounded-[50px] w-[210px] h-[72px]">
                <div class="flex-1 truncate text-blue-700">{{tag.title}}</div>
                <mat-icon class="cursor-pointer text-blue-700" (click)="removeTag(tag.id)">close</mat-icon>
              </div>
            }
          </div>
        </div>
      }
  }

</section>
