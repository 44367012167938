<div class="flex justify-center w-11/12 m-auto h-full overflow-y-scroll">
  <mat-accordion [class]="form ? 'w-full overflow-y-scroll px-2': 'w-full mt-4 mb-28 overflow-y-scroll px-2'" >
    @for (item of getQuestionSectionOrganizedList(); track $index ) {
      <mat-expansion-panel class="w-full flex flex-col" style="border: 1px solid">
          <mat-expansion-panel-header class="flex justify-between items-baseline">
            <mat-panel-title>
              <div class="text-2xl text-blue-800">{{$index+1}} - {{item.section.name}} :</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
          @if(item.questions.length === 0) {
          <div class="h-28 mt-8 mb-1 w-full flex justify-center text-center text-[var(--light-grey-1)] ">
            Ajoutez une question à votre form!
          </div>
        } @else {
          @for (elt of item.questions; track $index) {
            <div class="flex flex-col items-center">
              <div class="w-full">
                <div class ="border border-solid border-gray-200 flex flex-col mb-4 px-8 py-6">
                  @if (elt.type === QuestionTypeEnum.UNIQUE_CHOICE || elt.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
                    <app-unique-multiple-question-display [question]="elt" [visualisationMode]="true" (questionAnswer)="setQuestionAnswer($event, elt)"></app-unique-multiple-question-display>
                  } @else if (elt.type === QuestionTypeEnum.RANK) {
                    <app-rank-question-display [question]="elt" [visualisationMode]="true" (questionAnswer)="setQuestionAnswer($event, elt)"></app-rank-question-display>
                  } @else if (elt.type === QuestionTypeEnum.EVAL) {
                    <app-evaluation-question-display [question]="elt" [visualisationMode]="true" (questionAnswer)="setQuestionAnswer($event, elt)"></app-evaluation-question-display>
                  } @else if (elt.type === QuestionTypeEnum.FREE_TEXT) {
                    <app-free-text-question-display [question]="elt" [visualisationMode]="true" (questionAnswer)="setQuestionAnswer($event, elt)"></app-free-text-question-display>
                  } @else if (elt.type === QuestionTypeEnum.MULTI) {
                    <app-multi-question-display [question]="elt" [visualisationMode]="true" (questionAnswer)="setQuestionAnswer($event, elt)"></app-multi-question-display>
                  }
                </div>
              </div>
            </div>
          }
        }
        </mat-expansion-panel>
    }
  </mat-accordion>
</div>
