<div>
  @for (question of organizedResponsesArray; track $index) {
    <h4 class="flex items-center text-lg font-bold">
      <mat-icon>arrow_right_alt</mat-icon>
      {{ question.question.question }}
    </h4>

    <div class="ml-5 mt-2 mb-5 text-blue-900">
      @for (answers of question.answers; track $index) {
        <ul class="ml-5">
          @for (answer of answers.response; track $index) {
            <li>{{ answer.value }}</li>
          }
        </ul>
      }
    </div>
  }
</div>
