import {Component, Input, OnInit} from '@angular/core';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatDivider} from '@angular/material/divider';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {NgIf} from '@angular/common';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {finalize, Observable, of, tap} from 'rxjs';
import {AnswerFormatTypeEnum} from '../../../../../enums/questions.enum';

@Component({
  selector: 'app-multi-field-answer',
  standalone: true,
  imports: [
    MatButtonToggle,
    MatButtonToggleGroup,
    MatDivider,
    MatFormField,
    MatIcon,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    MatLabel
  ],
  templateUrl: './multi-field-answer.component.html',
  styleUrl: './multi-field-answer.component.scss'
})
export class MultiFieldAnswerComponent implements OnInit {

  @Input() firstStepFormGroup: FormGroup = new FormGroup({});
  @Input() _editMode: boolean = false;

  _loading: boolean = false;
  questionAnswers: FormArray = new FormArray<any>([]);

  ngOnInit() {
    this._switchLoading().pipe(
      tap(() => this.initForm()),
      finalize(() => this._switchLoading())
    ).subscribe()
  }

  initForm() {
    this.questionAnswers = this.firstStepFormGroup.get('questionAnswers') as FormArray;
    if (!this._editMode) this.questionAnswers.push(this.createFormGroupAnswer());
    else {
      this.questionAnswers.controls.sort((a, b) => a.get('displayOrder')?.value - b.get('displayOrder')?.value)
    }
  }

  addElement() {
    this.questionAnswers.push(this.createFormGroupAnswer());
  }

  get answerArray() {
    return this.firstStepFormGroup.get('questionAnswers') as FormArray
  }

  private createFormGroupAnswer(): FormGroup {
    return new FormGroup({
      displayOrder: new FormControl(null),
      value: new FormControl(''),
      formatType: new FormControl(AnswerFormatTypeEnum.TEXT),
      min: new FormControl(0),
      max: new FormControl(255),
    });
  }

  private _switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  protected readonly AnswerFormatTypeEnum = AnswerFormatTypeEnum;
}
